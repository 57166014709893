import styled from 'styled-components';
import * as Global from '../../../styles';
import { Link } from 'react-router-dom';

interface CardProps {
  fullWidth?: boolean;
}

export const Card = styled.section<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.6rem;
  padding: 1.8rem;
  background: #f8f8f8;
  width: 100%;
  box-shadow: 0px 0px 8.47px 0px #00000040;
`;

export const Container = styled(Card)`
  max-height: 643px;
  position: relative;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f3f3f3;
    //background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

export interface MonitoredAreasItemProps {
  active: boolean;
}

export const MonitoredDataSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Work Sans;
    margin-bottom: 3.2rem;
    & span {
      color: #262424;
      font-weight: 500;
    }
    & h1 {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 4.8rem;
      line-height: 1;
      margin: 0;
    }
  }
`;

export const MonitoredAreasSection = styled.div`
  width: 100%;
  border-radius: 0.8rem;
  border: 1px solid #e6e6e6;
  display: flex;
  justify-content: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f3f3f3;
    //background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

export const MonitoredAreasNames = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  border-top-left-radius: 0.8rem;
  align-items: center;
`;

export const MonitoredAreasHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  padding: 1.6rem;
  border: 1px solid #e6e6e6;
  border-top-left-radius: 0.8rem;
  & p {
    margin: 0;
    color: #666666;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: Work Sans;
  }
`;
export const MonitoredAreasItem = styled.div<MonitoredAreasItemProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-family: Work Sans;
  font-size: 1.6rem;
  border: ${({ active }) => (active ? 'none' : '1px solid #e6e6e6')};
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  gap: 0.8rem;
  & span {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4c4c4c;
    background-color: #eaedfc;
    border-radius: 50%;
  }
  & p {
    margin: 0;
    color: #666666;
    flex-grow: 1;
    text-align: left;
  }
`;

export const MonitoredAreasCollaborators = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  align-items: center;
  text-align: left;
  & > svg {
    align-self: flex-end;
  }
`;

export const MonitoredCollaborator = styled.div`
  display: flex;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
  padding: 0.4rem 1.6rem;
  gap: 1.6rem;
  font-family: Work Sans;
  & span {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #2f4cdd;
    font-size: 1.6rem;
  }
  & p {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #666666;
    font-size: 1.4rem;
  }
`;

export const CollaboratorsIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
`;

type MainMessageProps = {
  type: 'error' | 'success';
};

export const MainMessage = styled.h1<MainMessageProps>`
  color: ${({ type }) => (type === 'success' ? '#33b469' : '#ED3A3A')};
  font-weight: 500;
  font-size: 1.8rem;
  font-family: Work Sans;
  text-align: center;
  width: 100%;

  & a {
    color: #ed3a3a;
    text-decoration: underline;
  }
`;

export const ScrollChipsContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  padding: 0.8rem;
  gap: 2rem;
  width: 92%;
  position: absolute;
  left: 2.4rem;
  right: 2.4rem;
  top: 6.4rem;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f3f3f3;
    //background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

export const Chip = styled.div`
  background-color: #e6e6e6;
  box-shadow: 0px 2px 4px 0px #00000026;
  color: #808080;
  border-radius: 10rem;
  padding: 0.2rem 0.8rem;
  text-align: center;
  font-family: Work Sans;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
  }
`;

export const QuestionItem = styled.span`
  display: flex;
  align-items: center;
  padding: 8px 12px 9px 8px;
  gap: 8px;
  border-radius: 8px;
  border-left: 3px solid var(--State-color-Info_Color, #2f80ed);
  background: rgba(119, 177, 255, 0.15);
  cursor: pointer;
  font-weight: 500;
  font-family: Work Sans;
  margin-bottom: 1.2rem;
  width: 100%;
  max-width: 70rem;
`;

export const InOutDataSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    justify-content: center;
  }
  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Work Sans;
    padding: 0 2rem;
    & span {
      color: #666666;
      font-weight: 500;
      font-size: 1.4rem;
    }
    & h1 {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 2.8rem;
      color: #666666;
    }
    & p {
      color: #999999;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
`;

export const PagesContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2.4rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const CardContainer = styled.div`
  min-width: 8.4rem;
  max-width: 16.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4.8rem;
  color: #666666;
  font-weight: 500;
  font-family: Work Sans;
  gap: 0.8rem;
`;

export const PageCard = styled.span`
  background-color: #e8e8e8;
  cursor: pointer;
  width: 100%;
  aspect-ratio: 4/4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  &:hover {
    background-color: #2f4cdd;
  }
`;

export const PageDescription = styled.p`
  width: 100%;
  text-align: left;
  font-weight: 600;
  font-family: Work Sans;
  color: #666666;
`;

export const PageControlButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 6rem;
  justify-content: center;
`;

export const ControlButton = styled.button`
  all: unset;
  background-color: #2f4cdd;
  width: 5.6rem;
  height: 5.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
  &:disabled {
    cursor: not-allowed;
    background-color: #c1c9f5;
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  position: relative;
  overflow: hidden;
`;
