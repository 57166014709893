import { TooltipContent } from '../../../../components/Info/Tooltip';

export const getReportsTooltipContent = (showImproductive: boolean): TooltipContent[] => { 
  const tooltip = [
    {
      title: 'Produtivas - ',
      content: 'Tempo registrado em áreas monitoradas, exceto casas de PT, portarias, refeitorios e acessos.',
    },
    {
      title: 'Retirada de PT - ',
      content: 'Tempo registrado em casas de PT.',
    },
    {
      title: 'Complementares - ',
      content: 'Tempo registrado em portarias, refeitórios e acessos.',
    },
    {
      title: 'Deslocamentos - ',
      content: 'Tempo registrado em qualquer área, inferior a 2 minutos. Indicam movimentação.',
    }
  ]

  if(showImproductive)
    tooltip.push( {
      title: 'Improdutivas - ',
      content: 'Tempo complementar qualquer área em que não há captação da solução.',
  });

  return tooltip;
}