import React, { useMemo } from 'react';

import { TableWithActionColumn } from '../../../../../components/TableWithActionColumn';
import { TableRow } from '../../../../../components/TableWithActionColumn/types';
import { IDelayedEmployeesReport } from '../../../types';
import { getDateBrFormatFromString } from '../../../../../utils/DateTime';

interface Props {
  data: IDelayedEmployeesReport[];
}

const headers = [
  {
    title: 'Data',
  },
  {
    title: 'Empresa',
  },
  {
    title: 'Previsto',
  },
  {
    title: 'Efetivo',
  },
  {
    title: 'Atraso',
  },
];

export const DelayTable: React.FC<Props> = ({ data }) => {
  const rows: TableRow[] = useMemo(() => {
    return data.map((item) => ({
      id: item.data,
      cells: [
        {
          col: 'Data',
          value: getDateBrFormatFromString(item.data),
        },
        {
          col: 'Empresa',
          value: item.empresa,
        },
        {
          col: 'Previsto',
          value: item.efetivo_total,
        },
        {
          col: 'Efetivo',
          value: item.efetivo_atrasado,
        },
        {
          col: 'Atraso',
          value: `${100 - parseInt(item.percentual_atraso.toString())}%`,
        },
      ],
    }));
  }, [data]);
  return <TableWithActionColumn headers={headers} rows={rows} />;
};