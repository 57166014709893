import React from 'react';
import { DefaultIconProps } from './types';

export const TriangleIcon: React.FC<DefaultIconProps> = ({ height, width, fill, onClick, style }) => {
  return (
    <svg
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path d="M0 16L10 0L20 16H0ZM3.6 14H16.4L10 3.75L3.6 14Z" fill={fill || '#616161'} />
    </svg>
  );
}