import styled from 'styled-components';

export const ButtonContainer = styled.button`
  all: unset;
  background-color: #2f4cdd;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0.8rem;
  font-family: Work Sans;
  font-weight: 500;
  padding: 1.2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 4.4rem;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;