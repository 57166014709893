import React from 'react';

import { DefaultIconProps } from './types';

const IconLocation: React.FC<DefaultIconProps & { stroke?: string }> = ({
  fill,
  stroke,
  height,
  onClick,
  style,
  width,
}) => (
  <svg
    enableBackground="new 0 0 50 50"
    height={height || '20px'}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    width={width || '20px'}
    style={{ marginBottom: '-4px', ...style }}
    onClick={onClick}
  >
    <rect fill="none" height="20" width="20" />
    <path
      d="M40,17  c0,13.604-15,31-15,31S10,30.928,10,17c0-8.285,6.715-15,15-15C33.284,2,40,8.715,40,17z"
      fill="none"
      stroke={fill || stroke || '#fff'}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="5"
    />
    <circle
      cx="25"
      cy="18"
      fill="none"
      r="6"
      stroke={fill || stroke || '#fff'}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="4"
    />
  </svg>
);

export default IconLocation;