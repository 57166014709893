import React, { useEffect, useState } from 'react';
import { Page } from '../../../../components/PageStructure/Page';
import { AdminCustomTitle } from '../CustomTitle';
import { ContentContainer } from '../../../../components/PageStructure/styles';
import * as S from './styles';
import { Grid } from '../../../../components/Grid';
import { BoxesIcon } from '../../../../Icons/Boxes';
import { AlertIconVuesax } from '../../../../Icons/Alert-Vuesax';
import { Switch } from '../../../../components/Switch';
import { useConfigAdminContext } from './context/ConfigContext';
import { AdminCardRow } from '../CardRow';
import { AdminModalEdit } from '../ModalEdit';
import { ToastConfirm } from '../ToastConfirm';
import { useAdminPagesContext } from '../../context/AdminPagesContext';

export const AdminConfigContainer: React.FC = () => {
  const {
    cards,
    toggleCardActive,
    toggleRowSelect,
    openModal,
    modal,
    closeModal,
    modalForm,
    modalTitle,
    setModalForm,
    onModalConfirm,
    cardActive,
    isChanged,
    resetRows,
    initialCards,
    setActiveRow,
    activeRow,
  } = useConfigAdminContext();
  const { updateComponents } = useAdminPagesContext();
  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    if (isChanged) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [isChanged]);

  return (
    <Page
      title=""
      customTitle={<AdminCustomTitle title="Configurações" />}
      components={
        <ContentContainer value="">
          <ToastConfirm
            onConfirm={() => {
              const componentsList = cards.flatMap((card) => {
                const components = [];
                components.push({ name: card.title, active: card.active });
                if (card.rows) {
                  card.rows.forEach((row) => {
                    components.push({ name: row.name, active: card.active ? row.selected : false });
                  });
                }
                return components;
              });
              updateComponents(componentsList);
            }}
            show={showToast}
            onCancel={() => {
              const componentsList = initialCards.flatMap((card) => {
                const components = [];
                components.push({ name: card.title, active: card.active });
                if (card.rows) {
                  card.rows.forEach((row) => {
                    components.push({ name: row.name, active: card.active ? row.selected : false });
                  });
                }
                return components;
              });
              updateComponents(componentsList);
              setShowToast(false);
              resetRows();
            }}
          />
          <AdminModalEdit
            modal={modal}
            closeModal={closeModal}
            modalForm={modalForm}
            modalTitle={modalTitle}
            onClickConfirm={() => onModalConfirm(cardActive)}
            setModalForm={setModalForm}
            tags={activeRow?.tags || []}
            onChangeTag={(tags) => {
              if (activeRow) {
                setActiveRow({ ...activeRow, tags });
              }
            }}
          />
          <S.Container>
            {cards.map((card, index) =>
              index === 0 ? (
                <S.AdminCard style={{ height: 'auto', marginBottom: 'auto' }}>
                  <S.AdminCardHeader>
                    <BoxesIcon />
                    {card.title}
                    <AlertIconVuesax />
                    <div>
                      <Switch selected={card.active} onClick={() => toggleCardActive(card.title)} />
                    </div>
                  </S.AdminCardHeader>
                  <S.AdminCardContent style={{ height: 'auto', marginBottom: 0 }}>
                    {card.rows.map((row) => (
                      <AdminCardRow
                        disabled={row.disabled || !card.active || false}
                        icon={row.icon}
                        isActive={false}
                        selected={row.selected}
                        name={row.name}
                        onClickSelect={() => toggleRowSelect(row.name, card.title)}
                        onClickEdit={() => openModal(row.name, card.title)}
                      />
                    ))}
                  </S.AdminCardContent>
                </S.AdminCard>
              ) : (
                <></>
              )
            )}
            <Grid.Cell size={6}>
              <Grid.Display style={{ rowGap: '2.4rem' }}>
                {cards.slice(1).map((card) => (
                  <S.AdminCard fullSize>
                    <S.AdminCardHeader>
                      <BoxesIcon />
                      {card.title}
                      <AlertIconVuesax />
                      <div>
                        <Switch selected={card.active} onClick={() => toggleCardActive(card.title)} />
                      </div>
                    </S.AdminCardHeader>
                    <S.AdminCardContent style={{ marginBottom: 0 }}>
                      {card.rows.map((row) => (
                        <AdminCardRow
                          disabled={row.disabled || !card.active || false}
                          icon={row.icon}
                          isActive={false}
                          selected={row.selected}
                          name={row.name}
                          onClickSelect={() => toggleRowSelect(row.name, card.title)}
                          onClickEdit={() => openModal(row.name, card.title)}
                        />
                      ))}
                    </S.AdminCardContent>
                  </S.AdminCard>
                ))}
              </Grid.Display>
            </Grid.Cell>
          </S.Container>
        </ContentContainer>
      }
    ></Page>
  );
};
