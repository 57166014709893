import { AxiosResponse, CancelToken } from 'axios';

import api from '../../api';
import {
  CancelInvite,
  ChangeComponents,
  ChangePageAccess,
  ChangePassword,
  ChangePasswordResponse,
  CoordinatesResponse,
  GetAreaTimelineData,
  GetBulletinReportInfos,
  GetClients,
  GetCompanies,
  GetGeneralDataToExport,
  GetHHIndicators,
  GetInvites,
  GetMachinesSummary,
  GetMapCoordinates,
  GetMonitoredResources,
  GetProfessions,
  GetProfiles,
  GetSiteCoordinates,
  GetTimelineData,
  InviteUsers,
  RecoverPassword,
  RecoverPasswordResponse,
  TimelineData,
} from '.';
import {
  AreaTimeline,
  Burndown,
  CollaboratorsCount,
  Timeline,
} from '../../../containers/indicators/types/Indicators';
import { MachinesSummaryData } from '../../../containers/machinesSummary/types/MachinesSummaryTypes';
import { IHeatmapArea } from '../../../containers/moviment/HeatMap/types/HeatMapData';
import {
  ICollaboratorsByAreaDetails,
  MonitoredAreasInEmergencyData,
  MonitoredData,
  OccupationData,
  TeamData,
  RealTimeLocations,
  DashboardInOutData,
  DashboardWorkingPTData,
} from '../../../types/MonitoredData';
import { Feedbacks } from '../../../containers/insights/types/Insights';
import { TextInsightsMessages } from '../../../containers/textInsights/types/TextInsights';
import {
  AddCollaborators,
  DeleteCollaborators,
  LinkCollaboratos,
} from '../../../containers/linkCollaborator/types/linkCollaborators';
import { getEndpoint } from './endpoints';
import { Incidents, SolveIncidents } from '../../../containers/incidents/types/Incidents';
import { PeriodDate, ScannertStatus, TagStatus } from '../../../containers/scannerStatus/types';
import { UserInvite } from '../../../containers/users/types';
import {
  CreateArea,
  CreatedClients,
  CreatedCompany,
} from '../../../containers/clientCompanyRegistration/types/ClientCompany';
import { AreasInEvacuationSnapshot } from '../../../containers/emergency/types/Emergency';
import { GeneralMovimentData } from '../../../containers/moviment/types/types';


export const getAreas = async (
  cancelToken: CancelToken,
  excludeImproductZone?: boolean,
  order?: boolean
): Promise<AxiosResponse<any>> => {
  const improductZone = excludeImproductZone ? excludeImproductZone.toString() : 'false';
  const applyOrder = order ? order.toString() : 'false';
  return await api.get('v1/areas', {
    params: {
      excludeImproductZone: improductZone,
      order: applyOrder,
    },
    cancelToken,
  });
}

export const getArea = async (cancelToken: CancelToken, id: number): Promise<AxiosResponse<any>> => {
  return await api.get('v1/area', {
    params: {
      id
    },
    cancelToken,
  });
}

export const getClients: GetClients = async (cancelToken?: CancelToken, omitCivil?: boolean): Promise<AxiosResponse<any>> => {
  return await api.get(`v1/clients`, {
    params: omitCivil ? { omitcivil: omitCivil } : {},
    cancelToken,
  });
};

export const getCompanies: GetCompanies = async (
  operational: boolean,
  cancelToken?: CancelToken
): Promise<AxiosResponse<any>> => {
  return await api.get(`v1/companies`, {
    params: {
      operational: operational,
    },
    cancelToken,
  });
}

export const getProfessions: GetProfessions = async (cancelToken?: CancelToken) => {
  return await api.get(`v1/professions`, {
    params: {},
    cancelToken,
  });
}

export const getCreatedClients = async (cancelToken: CancelToken): Promise<CreatedClients[]> => {
  try {
    const endpoint = getEndpoint('createdclients', false);

    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {},
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return [];
  }
}

export const getCreatedCompany = async (cancelToken: CancelToken, clientId: number): Promise<CreatedCompany[]> => {
  try {
    const endpoint = getEndpoint('createdcompanies', false);

    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: { clientcod: clientId },
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return [];
  }
}

export const getSiteCoordinates: GetSiteCoordinates = async (
  clientId: number, 
  cancelToken: CancelToken): Promise<CoordinatesResponse> => {
  try {
    const endpoint = getEndpoint('sitecoordinates', false);

    const result: AxiosResponse = await api.get(endpoint, {
      params: { clientId },
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return {
      id: 0,
      hasMap: true,
      latitude: '',
      longitude: '',
      zoom: '',
    };
  }
}

export const createNewClient = async (createNewClientRequest: CreatedClients, 
  cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('createclient', false);

  return api.post(
    endpoint,
    {
      clientName: createNewClientRequest.name,
      clientType: createNewClientRequest.type,
      clientLat: createNewClientRequest.lat,
      clientLng: createNewClientRequest.lng,
    },
    { cancelToken }
  );
}

export const createNewCompany = async (createNewCompanyRequest: CreatedCompany,
  cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('createcompany', false);

  return api.post(
    endpoint,
    {
      companyName: createNewCompanyRequest.name,
      clientCod: createNewCompanyRequest.client,
    },
    { cancelToken }
  );
}

export const createNewArea = async (createNewAreaRequest: CreateArea, cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('createarea', false);

  return api.post(
    endpoint,
    {
      clientCod: createNewAreaRequest.client,
      areaName: createNewAreaRequest.name,
      centerLat: createNewAreaRequest.centerLat,
      centerLng: createNewAreaRequest.centerLng,
      areaColor: createNewAreaRequest.color,
      polygonCoords: createNewAreaRequest.polygonCoords,
    },
    { cancelToken }
  );
}

export const getScannersStatus = async (clientId: number, cancelToken: CancelToken): Promise<ScannertStatus[]> => {
  try {
    const endpoint: string = getEndpoint('scannersStatus', false);

    const result: AxiosResponse = await api.get(endpoint, {
      cancelToken,
    });

    return result.data.map((item: any) => {
      return {
        local: item.areaNome,
        scanner: item.scanner,
        status: item.status == 'online',
        lastCheck: item.lastCheck,
        lastReading: item.lastReading,
      };
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const getTagsStatus = async (cancelToken: CancelToken): Promise<TagStatus[]> => {
  try {
    const endpoint: string = getEndpoint('tagsStatus', false);
    const result: AxiosResponse<TagStatus[]> = await api.get(endpoint, { cancelToken });
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

//=====================================================================================================================

export const getLinkedCollaboratorsProfessions: GetProfessions = async (cancelToken?: CancelToken): Promise<AxiosResponse<any>> => {
  return await api.get(`v1/linkedcollab/professions`, {
    params: {},
    cancelToken,
  });
}

export const getLinkedCollaboratorsCompanies: GetCompanies = async (
  operational: boolean,
  cancelToken?: CancelToken
): Promise<AxiosResponse<any>> => {
  return await api.get(`v1/linkedcollab/companies`, {
    params: {
      operational: operational,
    },
    cancelToken,
  });
}

export const getLinkedTags = async (
  companyId: number,
  professionId: number,
  tagAlias: string,
  cancelToken: CancelToken
): Promise<LinkCollaboratos[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('v1/resourceMonitoring/linkedTags', {
      params: {
        company: companyId,
        profession: professionId,
        tagAlias: tagAlias,
      },
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return [];
  }
}

export const getTagsToLink = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  return await api.get('v1/resourceMonitoring/tagsToLink', {
    params: {},
    cancelToken,
  });
}

export const addNewCollaborator = async (
  addCollaboratorsRequest: AddCollaborators,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  return api.post(
    'v1/resourceMonitoring/addCollaborators',
    {
      company: addCollaboratorsRequest.company,
      profession: addCollaboratorsRequest.profession,
      tag: addCollaboratorsRequest.tag,
      currentDate: addCollaboratorsRequest.currentDate,
    },
    { cancelToken }
  );
}

export const deleteCollaborator = async (
  deleteCollaboratorsRequest: DeleteCollaborators,
  cancelToken: CancelToken
): Promise<any> => {
  try {
    const result: AxiosResponse<any> = await api.post(
      'v1/resourceMonitoring/deleteCollaborators',
      {
        tag: deleteCollaboratorsRequest.tagAlias,
      },
      { cancelToken }
    );
    return result.data;
  } catch (error) {
    return '';
  }
}

export const getNewCollaboratorAlias = async (professionId: number, cancelToken: CancelToken): Promise<string> => {
  try {
    const result: AxiosResponse<any> = await api.get('v1/resourceMonitoring/newCollaboratorAlias', {
      params: {
        profession: professionId,
      },
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return '';
  }
}

//=====================================================================================================================

export const getProfiles: GetProfiles = async (cancelToken?: CancelToken) => {
  const endpoint: string = getEndpoint('profiles', false);

  return await api.get(endpoint, {
    params: {},
    cancelToken,
  });
}

export const getInvites: GetInvites = async (
  profileSelected: number,
  companySelected: number,
  userSearched: string,
  cancelToken?: CancelToken
) => {
  const endpoint: string = getEndpoint('invites', false);

  return await api.get(endpoint, {
    params: {
      profile: profileSelected,
      company: companySelected,
      user: userSearched,
    },
    cancelToken,
  });
}

export const inviteUsers: InviteUsers = async (invites: UserInvite[], cancelToken?: CancelToken): Promise<void> => {
  const endpoint: string = getEndpoint('inviteusers', false);
  return await api.post(endpoint, { invites }, { cancelToken });
}

export const cancelUserInvite: CancelInvite = async (inviteId: number, cancelToken?: CancelToken): Promise<void> => {
  const endpoint: string = getEndpoint('cancelinvite', false);
  return await api.post(endpoint, { invite: inviteId }, { cancelToken });
}

export const revokeUserAccess: CancelInvite = async (userId: number, cancelToken?: CancelToken): Promise<void> => {
  const endpoint: string = getEndpoint('revokeuser', false);
  return await api.post(endpoint, { revokeduser: userId }, { cancelToken });
}

//==========================================================================================================================

export const getAverageTimeChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  areas: string,
  collaboratorId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('averageTimeByPeople', false);

  return api.get(endpoint, {
    params: {
      company: companies,
      profession: professions,
      area: areas,
      collaborator: collaboratorId,
      initialDate,
      finalDate,
      currentDate: new Date().getTime(),
    },
    cancelToken,
  });
}

export const getPeopleInAreasChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('peopleInAreas', false);

  return api.get(endpoint, {
    params: {
      company: companies,
      profession: professions,
      initialDate,
      finalDate,
      currentDate: new Date().getTime(),
    },
    cancelToken,
  });
}

export const getProductivityByAreaData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('productivityByArea', false);

  return api.get(endpoint, {
    params: {
      company: companies,
      profession: professions,
      initialDate,
      finalDate,
    },
    cancelToken,
  });
}

export const getRankChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('rankByProfession', false);

  return api.get(endpoint, {
    params: {
      company: companies,
      profession: professions,
      initialDate,
      finalDate,
    },
    cancelToken,
  });
}

export const getHHIndicators: GetHHIndicators = async (
  clientId: number,
  companyId: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('hhquantity', false);

  return api.get(endpoint, {
    params: { companyId: companyId },
    cancelToken,
  });
}

export const getMachinesSummary: GetMachinesSummary = async (cancelToken?: CancelToken): Promise<MachinesSummaryData> => {
  try {
    const result: AxiosResponse<any> = await api.get(`reports/v1/machinessummary`, {
      params: {},
      cancelToken,
    });

    return result.data as MachinesSummaryData;
  } catch (error) {
    return { data: [], statusValues: [], idValues: [] } as MachinesSummaryData;
  }
}

export const getMapCoordinates: GetMapCoordinates = async (cancelToken: CancelToken): Promise<CoordinatesResponse> => {
  try {
    const endpoint = getEndpoint('coordinates', false);
    const result: AxiosResponse = await api.get(endpoint, {
      params: {},
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return {
      id: 0,
      hasMap: true,
      latitude: '',
      longitude: '',
      zoom: '',
    };
  }
}

export const getMonitoredResources: GetMonitoredResources = async (
  clientId: number,
  companyId: string,
  professionId: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('collaboratorsMonitored', false);
  return await api.get(endpoint, {
    params: {
      company: companyId,
      profession: professionId,
    },
    cancelToken,
  });
}

export const getHeatMapData = async (
  clientId: number,
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<IHeatmapArea[]> => {
  try {
    const endpoint = getEndpoint('heatmaps', false);
    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        company: companyId,
        profession: professionId,
        initialDate: initialDate,
        finalDate: finalDate,
      },
      cancelToken,
    });

    return result.data as IHeatmapArea[];
  } catch (error) {
    return [] as IHeatmapArea[];
  }
}

export const getAreaTimelineData: GetAreaTimelineData = async (clientId: number, data: TimelineData): Promise<AreaTimeline[]> => {
  const { companies, professions, areas, initialDate, finalDate, currentDate, cancelToken } = data;
  const endpoint: string = getEndpoint('areaTimeline', false);
  try {
    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        company: companies,
        profession: professions,
        area: areas,
        initialDate,
        finalDate,
        currentDate,
      },
      cancelToken,
    });

    return result.data as AreaTimeline[];
  } catch (error) {
    return [];
  }
}

export const getTimelineData: GetTimelineData = async (clientId: number, data: TimelineData): Promise<Timeline[]> => {
  const { companies, professions, areas, initialDate, finalDate, currentDate, cancelToken } = data;
  const endpoint: string = getEndpoint('timeline', false);

  try {
    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        company: companies,
        profession: professions,
        area: areas,
        initialDate,
        finalDate,
        currentDate,
        ngena: true
      },
      cancelToken,
    });

    return result.data as Timeline[];
  } catch (error) {
    return [];
  }
}

export const putMachineStatus: any = async (
  status: string,
  id: string,
  cancelToken?: CancelToken
): Promise<any> => {
  try {
    const result: AxiosResponse<any> = await api.put(
      `reports/v1/machinestatus`,
      {
        status: status,
        machineCod: id,
      },
      {
        cancelToken,
      }
    );
    return result.data;
  } catch (error) {
    return {};
  }
}

export const getLocationsByType = async (cancelToken: CancelToken): Promise<RealTimeLocations[]> => {
  try {
    const endpoint = getEndpoint('currentlocationtype', false);
    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        currentTimestamp: new Date().getTime(),
      },
      cancelToken,
    });

    return result.data as RealTimeLocations[];
  } catch (error) {
    return [
      {
        tag: '',
        area: '',
      },
    ] as RealTimeLocations[];
  }
}

export const getMonitoredFloor = async (
  clientId: number,
  cancelToken: CancelToken,
  areaId: number,
  companyId: number
): Promise<MonitoredAreasInEmergencyData> => {
  try {
    const user = localStorage.getItem('@trackfy_user_id');
    const userId = user ? Number.parseInt(user) : 1;
    const useV2 = clientId == 11 || clientId == 1 || clientId == 6 || userId == 6;
    const url = useV2 ? getEndpoint('timeline', false) : getEndpoint('areasFloor', false);

    const result: AxiosResponse<any> = await api.get(url, {
      params: {
        areaId,
        companyId,
        currentTimestamp: new Date().getTime(),
      },
      cancelToken,
    });

    return result.data as MonitoredAreasInEmergencyData;
  } catch (error) {
    return {
      nome: '',
      lat: 0,
      lng: 0,
      ordem: 1,
      operacional: true,
      total: 0,
      issafearea: false,
      data: [],
      collaborators: [],
      teams: [],
    } as MonitoredAreasInEmergencyData;
  }
}

export const getRetiradaPTChartData = async (companyId: number, cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('statistics/v1/retiradaPT', {
    params: {
      company: companyId,
    },
    cancelToken,
  });

  return result;
}

export const getLunchChartData = async (companyId: number, cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  return api.get('statistics/v1/lunch', {
    params: { company: companyId },
    cancelToken,
  });
}

export const getAverageTimeInsightsChartData = async (
  companyId: number,
  areaId: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  return api.get('statistics/v1/averageTimeInsights', {
    params: {
      company: companyId,
      area: areaId,
    },
    cancelToken,
  });
}

export const getChegadaSaidaChartData = async (
  companyId: number,
  areaId: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  return api.get('statistics/v1/chegadaSaida', {
    params: {
      company: companyId,
      area: areaId,
    },
    cancelToken,
  });
}

export const getFeedbacksData = async (companyId: number, cancelToken: CancelToken): Promise<Feedbacks[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('statistics/v1/feedbacks', {
      params: { company: companyId },
      cancelToken,
    });

    return result.data as Feedbacks[];
  } catch (error) {
    return [] as Feedbacks[];
  }
}

export const getAgileanData = async (
  companyId: number,
  professionId: number,
  areaId: number,
  activityId: string,
  initialDate: number,
  finalDate: number,
  hours: number,
  cancelToken: CancelToken
): Promise<Burndown> => {
  try {
    const result: AxiosResponse<any> = await api.get('statistics/v1/planagil', {
      params: {
        company: companyId,
        profession: professionId,
        area: areaId,
        initialDate,
        finalDate,
        activity: activityId,
        hours,
      },
      cancelToken,
    });

    return result.data as Burndown;
  } catch (error) {
    return { series: [], benchmark: [] } as Burndown;
  }
}

export const getAgileanCurves = async (
  companyId: number,
  professionId: number,
  areaId: number,
  activityId: string,
  initialDate: number,
  finalDate: number,
  hours: number,
  plan: number,
  cancelToken: CancelToken
): Promise<Burndown> => {
  try {
    const result: AxiosResponse<any> = await api.get('statistics/v1/planagilcurves', {
      params: {
        company: companyId,
        profession: professionId,
        area: areaId,
        initialDate,
        finalDate,
        activity: activityId,
        hours,
        plan,
      },
      cancelToken,
    });

    return result.data as Burndown;
  } catch (error) {
    return { series: [], benchmark: [] } as Burndown;
  }
}

export const getActivities = async (areaId: number, cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  return await api.get('v1/activities', {
    params: { area: areaId },
    cancelToken,
  });
}

export const getWorkingPermitChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  weekValues: boolean,
  monthValues: boolean,
  yearValues: boolean,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('workingPermit', false);

  return api.get(endpoint, {
    params: {
      company: companies,
      profession: professions,
      currentDate: new Date().getTime(),
      initialDate,
      finalDate,
      week: weekValues,
      month: monthValues,
      year: yearValues,
    },
    cancelToken,
  });
}

export const getInOutChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  areas: string,
  weekValues: boolean,
  monthValues: boolean,
  yearValues: boolean,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('inOut', false);

  return api.get(endpoint, {
    params: {
      company: companies,
      profession: professions,
      initialDate,
      finalDate,
      area: areas,
      week: weekValues,
      month: monthValues,
      year: yearValues,
    },
    cancelToken,
  });
}

export const getCollaboratorsCount = async (
  clientId: number,
  companyId: string,
  currentDate: string,
  cancelToken: CancelToken
): Promise<CollaboratorsCount[]> => {
  try {
    const endpoint: string = getEndpoint('collaboratorsCount', false);

    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        company: companyId,
        currentDate: currentDate,
      },
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return [];
  }
}

export const getPlanningCollaboratorsCount = async (
  companyId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<CollaboratorsCount[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('monitoring/planningCollaboratorsCount', {
      params: {
        company: companyId,
        initialDate,
        finalDate,
      },
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return [];
  }
}

export const getTextInsightsMessages = async (
  companyId: number,
  professionId: number,
  areaId: number,
  collaboratorId: number,
  clientId: number,
  cancelToken: CancelToken
): Promise<TextInsightsMessages[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('statistics/v1/insightsMessage', {
      params: {
        company: companyId,
        profession: professionId,
        area: areaId,
        collaborator: collaboratorId,
        client: clientId,
      },
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return [];
  }
}

export const getProductivityVSDisplacement = async (
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  areaId: number,
  weekValues: boolean,
  monthValues: boolean,
  yearValues: boolean,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  return api.get('statistics/v1/productivityVSDisplacement', {
    params: {
      company: companyId,
      profession: professionId,
      initialDate,
      finalDate,
      area: areaId,
      week: weekValues,
      month: monthValues,
      year: yearValues,
    },
    cancelToken,
  });
}

export const getDisplacement = async (
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  return api.get('statistics/v1/displacement', {
    params: {
      company: companyId,
      profession: professionId,
      initialDate,
      finalDate,
    },
    cancelToken,
  });
}

export const getAbsenteeism = async (
  clientId: number,
  companyId: string,
  currentDate: string,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('absenteeism', false);
  return api.get(endpoint, {
    params: {
      company: companyId,
      currentDate: currentDate,
    },
    cancelToken,
  });
}

export const getAreasTimeDivision = async (
  clientId: number,
  companies: string,
  professions: string,
  collaborators: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('areasTimeDivision', false);

  return api.get(endpoint, {
    params: {
      company: companies,
      profession: professions,
      collaborators: collaborators,
      initialDate,
      finalDate,
    },
    cancelToken,
  });
}

export const getDisplacementsAtoB = async (
  companies: string,
  professions: string,
  collaborators: string,
  initialDate: number,
  finalDate: number,
  initialArea: number,
  finalArea: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('displacementsAtoB', false);

  return api.get(endpoint, {
    params: {
      company: companies,
      profession: professions,
      collaborators: collaborators,
      initialDate,
      finalDate,
      initialArea,
      finalArea
    },
    cancelToken,
  });
}

export const getGeneralDataToExport: GetGeneralDataToExport = async (
  professions,
  companies,
  initialDate,
  finalDate,
  cancelToken
) => {
  const endpoint = getEndpoint('movementhistory', false);

  const result = await api.get<GeneralMovimentData[]>(
    endpoint,
    {
      params: {
        professions,
        companies,
        initialDate,
        finalDate,
        currentDate: (new Date()).getTime()
      },
      cancelToken
    }  
  );
  return result;
}

//==========================================================================================================================

export const getAverageSpeedInsight = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('statistics/v1/insights/averagespeed', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getMovingOnFootInsight = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('statistics/v1/insights/movingonfoot', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getMovingByCarInsight = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('statistics/v1/insights/movingbycar', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getOptimizationsInsight = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('statistics/v1/insights/optimizations', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getDailySavingInsight = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('statistics/v1/insights/dailysaving', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getRoiInsight = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('statistics/v1/insights/roi', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getSavingsInsight = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('statistics/v1/insights/savings', {
    params: {},
    cancelToken,
  });

  return result;
}

//========================================================================================================================

export const getDashboardInOutData = async (companies: string,
                                            professions: string,
                                            collaborators: string,
                                            areas: string,
                                            initialDate: number,
                                            finalDate: number,
                                            week: boolean,
                                            month: boolean,
                                            quarter: boolean,
                                            cancelToken: CancelToken): Promise<DashboardInOutData> => {
  try {
    const endpoint: string = getEndpoint('homePageInOut', false);

    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        company: companies,
        profession: professions,
        collaborators: collaborators,
        area: areas,
        initialDate,
        finalDate,
        week,
        month,
        quarter,
      },
      cancelToken,
    });

    return {
      arrivalAndDepartureAverage: result.data.average,
      arrivalImproved: result.data.maxEntranceIsBetter,
      departureImproved: result.data.minExitIsBetter,
      earliestDeparture: result.data.minExit,
      earliestDepartureDate: '',
      latestArrival: result.data.maxEntrance,
      latestArrivalDate: '',
    };

  } catch (error) {
    console.log(error);

    return {
      arrivalAndDepartureAverage: '00:00/00:00',
      arrivalImproved: false,
      departureImproved: false,
      earliestDeparture: '00:00',
      earliestDepartureDate: '01/01/1970',
      latestArrival: '00:00',
      latestArrivalDate: '01/01/1970',
    } as DashboardInOutData;
  }
}

export const getDashboardWorkingPermit = async (companies: string,
                                            professions: string,
                                            currentDate: number,
                                            initialDate: number,
                                            finalDate: number,
                                            week: boolean,
                                            month: boolean,
                                            quarter: boolean,
                                            cancelToken: CancelToken): Promise<DashboardWorkingPTData> => {
  try {
    const endpoint: string = getEndpoint('homePageWorkingPermit', false);

    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        company: companies,
        profession: professions,
        currentDate,
        initialDate,
        finalDate,
        week,
        month,
        quarter,
      },
      cancelToken,
    });

    return {
      min: result.data.minTime,
      minImproved: result.data.minTimeIsBetter ? result.data.minTimeIsBetter : undefined,
      average: result.data.average,
      averageImproved: true,
      max: result.data.maxTime,
      maxImproved: result.data.maxTimeIsBetter ? result.data.maxTimeIsBetter : undefined,
    };

  } catch (error) {
    console.log(error);

    return {
      min: "00min",
      minImproved: false,
      average: "00min",
      averageImproved: false,
      max: "00min",
      maxImproved: false,
    } as DashboardWorkingPTData;
  }
}

export const getLocations = async (cancelToken: CancelToken): Promise<RealTimeLocations[]> => {
  try {
    const endpoint = getEndpoint('currentlocation', false);
    const result: AxiosResponse<any> = await api.get(endpoint,
      {
        params: {
          currentTimestamp: new Date().getTime(),
        },
        cancelToken,
      }
    );

    return result.data as RealTimeLocations[];
  } catch (error) {
    return [
      {
        tag: '',
        area: '',
      },
    ] as RealTimeLocations[];
  }
}

export const getLocationsByArea = async (
  clientId: number,
  cancelToken: CancelToken,
  company: string,
  isevacuating: boolean,
): Promise<MonitoredAreasInEmergencyData[]> => {
  try {
    const endpoint: string = getEndpoint('locationsByArea', false);

    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        currentTimestamp: new Date().getTime(),
        company,
        evc: isevacuating
      },
      cancelToken,
    });

    const locations: MonitoredAreasInEmergencyData[] = result.data.map((item: any) => {
      return {
        nome: item.area,
        lng: item.lng,
        lat: item.lat,
        ordem: item.ordem,
        operacional: item.operacional,
        total: item.quantity,
        issafearea: item.issafearea,
        data: item.details as OccupationData[],
        collaborators: item.collaborators as ICollaboratorsByAreaDetails[],
        teams: item.teams as TeamData[],
      };
    });

    return locations;
  } catch (error) {
    return [] as MonitoredAreasInEmergencyData[];
  }
}

export const getLocationsByFloors = async (
  clientId: number,
  cancelToken: CancelToken
): Promise<MonitoredData[]> => {
  try {
    const endpoint: string = getEndpoint('floors', false);

    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        currentTimestamp: new Date().getTime(),
      },
      cancelToken,
    });

    return result.data;
  } catch (error) {
    return [] as MonitoredData[];
  }
}

//========================================================================================================================

export const getMonitoredHH = async (
  clientId: number,
  companies: string,
  professions: string,
  areas: string,
  collaborators: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('monitoredhh', false);

  return api.get(endpoint, {
    params: {
      clientId,
      companies,
      professions,
      areas,
      collaborators,
      initialDate,
      finalDate,
    },
    cancelToken,
  });
}

export const getResourcesQuantity = async (
  clientId: number,
  companies: string,
  professions: string,
  areas: string,
  collaborators: string,
  initialDate: number,
  finalDate: number,
  currentDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('quantityResources', false);

  return api.get(endpoint, {
    params: {
      clientId,
      companies,
      professions,
      areas,
      collaborators,
      initialDate,
      finalDate,
      currentDate
    },
    cancelToken,
  });
}

export const getArrivalAndDeparture = async (
  clientId: number,
  companies: string,
  professions: string,
  areas: string,
  collaborators: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('arrivalAndDeparture', false);

  return api.get(endpoint, {
    params: {
      clientId,
      companies,
      professions,
      areas,
      collaborators,
      initialDate,
      finalDate,
    },
    cancelToken,
  });
}

export const getAvailableVsMonitoredResources = async (
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  timeGranularity: string,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('availablevsmonitoredresources', false);

  return api.get(endpoint, {
    params: {
      companies,
      professions,
      initialDate,
      finalDate,
      timeGranularity,
      optionalParams: {
        initialTimeLimit: '07:00:00',
			  finalTimeLimit: '17:00:00'
      }
    },
    cancelToken,
  });
}

export const getResourcesTimeDivision = async (
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  timeGranularity: string,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('resourcestimedivision', false);

  return api.get(endpoint, {
    params: {
      companies,
      professions,
      initialDate,
      finalDate,
      timeGranularity,
    },
    cancelToken,
  });
}

export const getResourcesTimeDivisionByHour = async (
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  timeGranularity: string,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('resourcestimedivisionbyhr', true);

  return api.get(endpoint, {
    params: {
      companies,
      professions,
      initialDate,
      finalDate,
      timeGranularity,
      optionalParams: {
        movementThreshold: '00:03:00', 
			  improductivePresenceThreshold: '00:20:00', 
			  initialTimeLimit: '07:00:00',
			  finalTimeLimit: '17:00:00'
      }
    },

    cancelToken,
  });
}

export const getBulletinReportInfos: GetBulletinReportInfos = async (cancelToken: CancelToken
  ): Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('bulletinInfos', false);
  return await api.get(endpoint, { cancelToken });
}

//===============================================================================================================

export const getEmergencyNotifications = async (clientId: number, cancelToken: CancelToken): Promise<any[]> => {
  try {
    const endpoint: string = getEndpoint('emergencyNotifications', false);
    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: { currentTimestamp: new Date().getTime() },
      cancelToken,
    });

    return result.data as any[];
  } catch (error) {
    return [] as any[];
  }
}

export const getMonitoredAreasInEmergency = async (clientId: number, cancelToken: CancelToken): 
  Promise<MonitoredAreasInEmergencyData[]> => {
  try {
    const user = localStorage.getItem('@trackfy_user_id');
    const userId = user ? Number.parseInt(user) : 1;
    const useV2 = clientId == 11 || clientId == 1 || clientId == 6 || userId == 6;
    const url = useV2 ? getEndpoint('emergencyAreas', false) : getEndpoint('emergencyAreasV1', false);
    
    const result: AxiosResponse<any> = await api.get(url, {
      params: {
        currentTimestamp: new Date().getTime(),
        delay: false,
      },
      cancelToken,
    });

    return result.data as MonitoredAreasInEmergencyData[];
  } catch (error) {
    return [] as MonitoredAreasInEmergencyData[];
  }
}

export const getIncidents = async (
  clientId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  areaId: number,
  cancelToken: CancelToken
): Promise<Incidents[]> => {
  try {
    const endpoint = getEndpoint('incidents', false);

    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        profession: professionId,
        initialDate,
        finalDate,
        area: areaId,
      },
      cancelToken,
    });

    return result.data.map((item: any) => {
      return {
        id: item.id,
        col_alias: item.collaborator,
        place: item.place,
        timestamp: item.date,
        reason: item.reason,
        solved: item.solved,
        comment: item.comment,
        solvedby: item.solvedby,
        date: item.solvedDate,
		evacuatedAreas: item.evacuatedAreas,
        evacuationDuration: item.evacuationDuration,
      };
    });
  } catch (error) {
    return [];
  }
}

export const solveIncident = async (
  clientId: number,
  solveIncidentRequest: SolveIncidents,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('solveincident', false);

  return api.post(
    endpoint,
    {
      id: solveIncidentRequest.id,
      comment: solveIncidentRequest.comment,
      solvedBy: solveIncidentRequest.solvedBy,
      currentDate: solveIncidentRequest.currentDate,
    },
    { cancelToken }
  );
}

export const sendEvacuationData = async (safeAreas: AreasInEvacuationSnapshot[], 
                                         collaboratorsInDangerAreas: string[], 
                                         collaboratorsInSafeArea: string[], 
                                         collaboratorsSeenInSafeArea: string[],
                                         collaboratorsInDangerAtStart: string[],
                                         dangerAreas: string[],  
                                         elapsedTime: number, 
                                         timestamp: number, 
                                         cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
                                          
  const endpoint = getEndpoint('emergencyEvacuation', false);

  return api.post(
    endpoint,
    {
      safearea: safeAreas,
      collaboratorsinsafearea: collaboratorsInSafeArea,
      collaboratorsseeninsafearea: collaboratorsSeenInSafeArea,
      collaboratorsindangerareas: collaboratorsInDangerAreas,
      collaboratorsindangeratstart: collaboratorsInDangerAtStart,
      dangerareas: dangerAreas,
      timestamp: timestamp,
      time: elapsedTime,
    },
    { cancelToken }
  );
}

export const getEvacuations = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('emergencyEvacuationList', false);

  const result: AxiosResponse<any> = await api.get(endpoint, {
    params: {},
    cancelToken,
  });

  return result;
}

export const getGps = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const endpoint: string = getEndpoint('gps', false);

  return api.get(endpoint, {
    params: {},
    cancelToken,
  });
}

//===============================================================================================================

export const getValueExpansion = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('https://backend.trackfyapp.com/rest/v2/statistics/onePage/valueExpansion', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getRevenueFTE = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('https://backend.trackfyapp.com/rest/v2/statistics/onePage/revenueFTE', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getNDR = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('https://backend.trackfyapp.com/rest/v2/statistics/onePage/NDR', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getLtvCacMargin = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('https://backend.trackfyapp.com/rest/v2/statistics/onePage/ltvCacMargin', {
    params: {},
    cancelToken,
  });

  return result;
}

export const getLastUpdate = async (cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const result = await api.get('https://backend.trackfyapp.com/rest/v2/statistics/v1/onePage/lastUpdate', {
    params: {},
    cancelToken,
  });

  return result;
}

//================================================================================================================

export const getLogtypes = async (cancelToken?: CancelToken) => {
  const endpoint = getEndpoint('logtypes', false);

  return await api.get(endpoint, {
    params: {},
    cancelToken,
  });
}

export const getLogs = async (type: number, initialDate: number, finalDate: number, cancelToken?: CancelToken):
Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('logs', false);

  return await api.get(endpoint, {
    params: {
      logtypes: type,
      initialDate,
      finalDate,
    },
    cancelToken,
  });
}

export const changePassword: ChangePassword = async (password, token, cancelToken) => {
  const endpoint = getEndpoint('changePassword', false);
  const result = await api.post<ChangePasswordResponse>(endpoint,
    {
      password,
      token,
    },
    {
      params: {},
      cancelToken,
    }
  );

  return result;
}

export const recoverPassword: RecoverPassword = async (email, cancelToken) => {
  const endpoint = getEndpoint('recoverPassword', false);
  const result = await api.post<RecoverPasswordResponse>(endpoint,
    {
      email,
    },
    {
      params: {},
      cancelToken,
    }
  );
  return result;
}

//=========================================================================================================================
export const changePageAccess: ChangePageAccess = async (pages, icons, groupId, cancelToken) => {
  const endpoint = getEndpoint('adminPageAccess', false);
  const result = await api.post(endpoint,
    {
      routes: pages,
      groupId,
      icons,
    },
    {
      cancelToken,
    }
  );
  return result;
}

export const changeComponents: ChangeComponents = async (components, icons, groupId, cancelToken) => {
  const endpoint = getEndpoint('adminComponentAccess', false);
  const result = await api.post(endpoint,
    {
      components,
      icons,
      groupId,
    },
    {
      cancelToken,
    }
  );
  return result;
}

export const getInfleetResources = async (
  infleetToken: string,
  operationName: string,
  cancelToken: CancelToken,
  id?: string[],
  period?: PeriodDate
): Promise<AxiosResponse<any>> => {
  const endpoint = getEndpoint('infleet', false);

  return await api.post(
    endpoint,
    {
      infleetToken,
      operationName,
      id,
      period,
    },
    { cancelToken }
  );
}