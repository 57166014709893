import React from 'react';

import * as S from './styles';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const SecondaryButton: React.FC<Props> = ({ children, disabled, onClick, style }) => {
  return (
    <S.ButtonContainer disabled={disabled} onClick={onClick} style={style}>
      {children}
    </S.ButtonContainer>
  );
};