import styled from 'styled-components';
import { EvacuationSnapshot } from '../../../../emergency/types/Emergency';

export const MonitoredAreasCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 36rem;
`;

export const MonitoredAreasCardHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 0.8rem;
  color: #666666;
  font-family: Work Sans;
  font-weight: 500;
`;

interface MonitoredAreasCardContentProps {
  collapsed: boolean;
}

export const MonitoredAreasCardContent = styled.div<MonitoredAreasCardContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.8rem;
  max-height: ${({ collapsed }) => (collapsed ? '500px' : '0px')};
  opacity: ${({ collapsed }) => (collapsed ? 1 : 0)};
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
`;

interface MonitoredAreasCardRowProps {
  isFirstItem?: boolean;
  isLastItem?: boolean;
  color: string;
  collapsed: boolean;
}

export const MonitoredAreasCardRow = styled.div<MonitoredAreasCardRowProps>`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.8rem 1.6rem 0.8rem 2.4rem;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: ${({ isFirstItem, isLastItem }) =>
      isFirstItem ? '.8rem .8rem 0 0' : isLastItem ? '0 0 .8rem 0.8rem' : 'none'};
    font-family: Work Sans;
    font-weight: 500;
    & > div {
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 9.6rem;
      background-color: ${({ color }) => color};
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin-right: 0.8rem;
    }
    & span {
      color: #666666;
      flex-grow: 1;
    }
  }
`;

interface AreaCollapsedCollaboratorsProps {
  collapsed: boolean;
}

export const AreaCollapsedCollaborators = styled.section<AreaCollapsedCollaboratorsProps>`
  display: ${({ collapsed }) => (collapsed ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #ffffff;
  font-family: Work Sans;
  font-weight: 500;
  color: #666666;
  font-size: 1.2rem;
  padding: 0.4rem 1.4rem;
  width: 100%;
  text-align: left;
  max-height: ${({ collapsed }) => (collapsed ? '200px' : '0px')};
  opacity: ${({ collapsed }) => (collapsed ? 1 : 0)};
  visibility: ${({ collapsed }) => (collapsed ? 'visible' : 'hidden')};
  transition: max-height 0.2s ease, opacity 0.2s ease;
`;

export const FilterContainer = styled.div`
  width: 350px;
  z-index: 1000;
`;

export const SwitchContainer = styled.div`
  display: flex;
  background: rgb(255, 255, 255);
  width: 210px;
  flex-direction: row;
  padding-top: 5px;
  border-radius: 20px;
  justify-content: space-around;
`;

export interface LabelProps {
  checked?: boolean;
}

export const SwitchLabel = styled.h3<LabelProps>`
  color: rgb(79, 88, 130);
  font-weight: ${(props) => (props.checked ? 'bold' : 'normal')};
  margin-left: 5px;
`;

export interface EvacuationButtonProps extends Pick<EvacuationSnapshot, 'mode'> {
  active: boolean;
  isMobile: boolean;
}

export interface ModalCardProps {
  success: boolean;
}

export interface EvacuationCardItemProps {
  color: string;
}

export const EvacuationButton = styled.button<EvacuationButtonProps>`
  width: fit-content;
  right: ${({ isMobile }) => (isMobile ? '.5rem' : '4rem')};
  background-color: ${({ active, mode }) => (mode === 'evacuating' ? '#FFFFFF' : active ? '#F69C9C' : '#ed3a3a')};
  color: ${({ mode }) => (mode === 'evacuating' ? '#ed3a3a' : '#ffffff')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ isMobile }) => (isMobile ? '1.2rem 2rem' : '1.2rem 2rem')};
  border-radius: 0.8rem;
  gap: 1.4rem;
  cursor: ${({ active, mode }) => (mode === 'evacuating' ? 'pointer' : active ? 'not-allowed' : 'pointer')};
  font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
  font-size: 2rem;
  transition: all 0.3s ease-in-out;
  border: ${({ mode }) => (mode === 'evacuating' ? '2px solid #ed3a3a' : '')};
`;
