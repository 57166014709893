import React from 'react';

import * as S from '../../styles';
import '../../stylesheet.css';

type ResourcesTimeCompanySectionProps = {
    index: number;
    title: string;
    prodFormatted: number;
    ptFormatted: number;
    complFormatted: number;
    deslocFormatted: number;
    improdFormatted: number;
    total: number;
    dailyTotal: boolean;
    showImproductive?: boolean;
    cores: {
        produtivas: string;  
        pt: string;
        complementares: string;
        deslocamentos: string;
        improdutivas: string;
    }
}

const calcularPercentual = (valor: number, total: number) => parseFloat( ((valor / total) * 100).toFixed(2) );

export const ProfessionBar: React.FC<ResourcesTimeCompanySectionProps> = ({index, 
                                                                           title,
                                                                           prodFormatted, 
                                                                           ptFormatted,
                                                                           complFormatted,
                                                                           deslocFormatted,
                                                                           improdFormatted,
                                                                           total,
                                                                           cores}) => (
    <S.CompanyBarsContiner>
        <S.FunctionTitle key={index + "_" + title}>{title}</S.FunctionTitle>
        <S.ReportBarsContiner key={index + "_" + title + "+values"}>
            {(prodFormatted > 0) &&
                <S.Bars title={`Produtivas: ${calcularPercentual(prodFormatted, total)}% (${prodFormatted})`}
                        backgroundColor= {cores.produtivas}
                        width={`${calcularPercentual(prodFormatted, total)}%`}>
                        {`${calcularPercentual(prodFormatted, total)}% (${prodFormatted} min)`} 
                </S.Bars>}
        
            {(ptFormatted > 0) &&
                <S.Bars title={`Complementares: ${calcularPercentual(ptFormatted, total)}% (${ptFormatted})`}
                        backgroundColor= {cores.pt}
                        width={`${calcularPercentual(ptFormatted, total)}%`}>
                    {`${calcularPercentual(ptFormatted, total)}% (${ptFormatted} min)`}
                </S.Bars>}
                                                              
            {(complFormatted > 0) &&
                <S.Bars title={`Complementares: ${calcularPercentual(complFormatted, total)}% (${complFormatted})`}
                        backgroundColor= {cores.complementares}
                        width={`${calcularPercentual(complFormatted, total)}%`}>
                    {`${calcularPercentual(complFormatted, total)}% (${complFormatted} min)`}
                </S.Bars>}
                                                              
            {(deslocFormatted > 0) &&
                <S.Bars title={`Deslocamentos: ${calcularPercentual(deslocFormatted, total)}% (${deslocFormatted})`} 
                        backgroundColor= {cores.deslocamentos}
                        width={`${calcularPercentual(deslocFormatted, total)}%`}>
                    {`${calcularPercentual(deslocFormatted, total)}% (${deslocFormatted} min)`}             
                </S.Bars>}
        
            {/*(improdFormatted > 0) &&
                <S.Bars title={`Improdutivas: ${calcularPercentual(improdFormatted, total)}%`} 
                        backgroundColor= {cores.improdutivas}
                        width={`${calcularPercentual(improdFormatted, total)}%`}>
                    {`${calcularPercentual(improdFormatted, total)}%`} 
                {/*</S.Bars>*/}
        </S.ReportBarsContiner>
    </S.CompanyBarsContiner>   
)

export const CompanyBar: React.FC<ResourcesTimeCompanySectionProps> = ({index, 
                                                                        title,
                                                                        prodFormatted, 
                                                                        ptFormatted,
                                                                        complFormatted,
                                                                        deslocFormatted,
                                                                        improdFormatted,
                                                                        total,
                                                                        dailyTotal,
                                                                        cores}) => (
    <S.CompanyFooterBarsContiner dailyTotal={dailyTotal}>
        <S.CompanyTotalTitle key={"total_" + title}>Total {title}</S.CompanyTotalTitle>
        <S.CompanyBarContiner key={"total_bar" + title}>
            {(prodFormatted > 0) &&
                <S.Bars title={`Produtivas: ${calcularPercentual(prodFormatted, total)}% (${prodFormatted})`}
                        backgroundColor= {cores.produtivas}
                        width={`${calcularPercentual(prodFormatted, total)}%`}>
                    {`${calcularPercentual(prodFormatted, total)}% (${prodFormatted} min)`} 
                </S.Bars>}
                
            {(ptFormatted > 0) &&
                <S.Bars title={`Complementares: ${calcularPercentual(ptFormatted, total)}% (${ptFormatted})`}
                        backgroundColor= {cores.pt}
                         width={`${calcularPercentual(ptFormatted, total)}%`}>
                    {`${calcularPercentual(ptFormatted, total)}% (${ptFormatted} min)`}
                </S.Bars>}
                                                                
            {(complFormatted > 0) &&
                <S.Bars title={`Complementares: ${calcularPercentual(complFormatted, total)}% (${complFormatted})`}
                        backgroundColor= {cores.complementares}
                        width={`${calcularPercentual(complFormatted, total)}%`}>
                    {`${calcularPercentual(complFormatted, total)}% (${complFormatted} min)`}
                </S.Bars>}
                                                                
            {(deslocFormatted > 0) &&
                <S.Bars title={`Deslocamentos: ${calcularPercentual(deslocFormatted, total)}% (${deslocFormatted})`} 
                        backgroundColor= {cores.deslocamentos}
                        width={`${calcularPercentual(deslocFormatted, total)}%`}>
                    {`${calcularPercentual(deslocFormatted, total)}% (${deslocFormatted} min)`} 
                </S.Bars>}
        </S.CompanyBarContiner>
    </S.CompanyFooterBarsContiner> 
)

export const TimeSlotBar: React.FC<ResourcesTimeCompanySectionProps> = ({index, 
                                                                        title,
                                                                        prodFormatted, 
                                                                        ptFormatted,
                                                                        complFormatted,
                                                                        deslocFormatted,
                                                                        improdFormatted,
                                                                        total,
                                                                        cores,
                                                                        dailyTotal,
                                                                        showImproductive}) => {

    const prodPercentage = calcularPercentual(prodFormatted, total);
    const ptPercentage = calcularPercentual(ptFormatted, total);
    const complPercentage = calcularPercentual(complFormatted, total);
    const deslocPercentage = calcularPercentual(deslocFormatted, total);
    let improdPercentage = 0;

    let totalPercentage = prodPercentage + ptPercentage + complPercentage
        + deslocPercentage; 

    if(showImproductive){
        let improdPercentage = calcularPercentual(improdFormatted, total);
        totalPercentage = + improdPercentage;

        if(totalPercentage > 100)
            improdPercentage = improdPercentage - (totalPercentage - 100);
    }    

    return (
        <S.CompanyFooterBarsContiner dailyTotal={dailyTotal}>
            <S.CompanyTotalTitle key={"total_" + title}>Total</S.CompanyTotalTitle>
            <S.CompanyBarContiner key={"total_bar" + title}>
                {(prodFormatted > 0) &&
                    <S.Bars title={`Produtivas: ${prodPercentage}% (${prodFormatted})`}
                            backgroundColor= {cores.produtivas} width={`${prodPercentage}%`}>
                        {`${prodPercentage}% (${prodFormatted} min)`} 
                    </S.Bars>}
                    
                {(ptFormatted > 0) &&
                    <S.Bars title={`Complementares: ${ptPercentage}% (${ptFormatted})`}
                            backgroundColor= {cores.pt} width={`${ptPercentage}%`}>
                        {`${ptPercentage}% (${ptFormatted} min)`}
                    </S.Bars>}
                                                                    
                {(complFormatted > 0) &&
                    <S.Bars title={`Complementares: ${complPercentage}% (${complFormatted})`}
                            backgroundColor= {cores.complementares} width={`${complPercentage}%`}>
                        {`${complPercentage}% (${complFormatted} min)`}
                    </S.Bars>}
                                                                    
                {(deslocFormatted > 0) &&
                    <S.Bars title={`Deslocamentos: ${deslocPercentage}% (${deslocFormatted})`} 
                            backgroundColor= {cores.deslocamentos} width={`${deslocPercentage}%`}>
                        {`${deslocPercentage}% (${deslocFormatted} min)`} 
                    </S.Bars>}

                {(showImproductive && improdFormatted > 0) &&
                    <S.Bars title={`Impordutivas: ${improdPercentage.toFixed(2)}% (${improdFormatted})`} 
                            backgroundColor= {cores.improdutivas} width={`${improdPercentage}%`}>
                        {`${improdPercentage.toFixed(2)}% (${improdFormatted} min)`} 
                    </S.Bars>}
            </S.CompanyBarContiner>
        </S.CompanyFooterBarsContiner> 
    );
}