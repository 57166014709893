import React, { useEffect, useState } from 'react';

import { ContentContainer } from '../../../../components/PageStructure/styles';
import { Page } from '../../../../components/PageStructure/Page';
import { AdminCardRow } from '../CardRow';
import { AdminModalEdit } from '../ModalEdit';
import { useRecordsAdminContext } from './context/RecordsAdminContext';
import { TrackfyIcon } from '../../../../Icons/Trackfy';
import { AlertIconVuesax } from '../../../../Icons/Alert-Vuesax';
import { AdminCustomTitle } from '../CustomTitle';
import { ToastConfirm } from '../ToastConfirm';
import { useAdminPagesContext } from '../../context/AdminPagesContext';

import * as S from './styles';

export const RecordsAdminContainer: React.FC = () => {
  const {
    closeModal,
    modal,
    modalForm,
    setModalForm,
    activeRow,
    onModalConfirm,
    cards,
    openModal,
    setCards,
    isChanged,
    resetRows,
    initialCards,
    setActiveRow,
  } = useRecordsAdminContext();
  const [showToast, setShowToast] = useState(false);
  const { updateComponents } = useAdminPagesContext();

  const onClickSelectRow = (name: string) => {
    const localCards = [...cards];
    const row = localCards.findIndex((item) => item.name === name);
    if (row >= 0) {
      localCards.splice(row, 1, { ...localCards[row], selected: !localCards[row].selected });
    }
    setCards(localCards);
  };

  useEffect(() => {
    if (isChanged) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [isChanged]);

  return (
    <Page
      title=""
      customTitle={<AdminCustomTitle title="Registros" />}
      components={
        <ContentContainer value="" style={{ gap: '2.4rem' }}>
          <ToastConfirm
            onConfirm={() => {
              const componentsList = cards.map((card) => {
                return { name: card.name, active: card.selected };
              });
              updateComponents(componentsList);
            }}
            show={showToast}
            onCancel={() => {
              const componentsList = initialCards.map((card) => {
                return { name: card.name, active: card.selected };
              });
              updateComponents(componentsList);
              setShowToast(false);
              resetRows();
            }}
          />
          <S.ContentTitle>
            <TrackfyIcon />
            <span>Funcionalidades</span>
            <AlertIconVuesax />
          </S.ContentTitle>
          <AdminModalEdit
            closeModal={closeModal}
            modal={modal}
            modalForm={modalForm}
            modalTitle={activeRow?.name || ''}
            onClickConfirm={onModalConfirm}
            setModalForm={setModalForm}
            tags={activeRow?.tags || []}
            onChangeTag={(tags) => {
              if (activeRow) {
                setActiveRow({ ...activeRow, tags });
              }
            }}
          />
          <S.ContentItems>
            {cards.map((row) => (
              <AdminCardRow
                disabled={row.disabled || false}
                icon={row.icon}
                isActive
                name={row.name}
                onClickSelect={() => {
                  onClickSelectRow(row.name);
                }}
                selected={row.selected}
                onClickEdit={() => openModal(row.name)}
              />
            ))}
          </S.ContentItems>
        </ContentContainer>
      }
    ></Page>
  );
};
