import React from 'react';
import { useAdminPagesContext } from '../../context/AdminPagesContext';
import { ConfigAdminContextProvider } from '../Config/context/ConfigContext';
import { AdminConfigContainer } from '../Config';
import { HomeAdminContextProvider } from '../Home/context/HomeContext';
import { HomeAdminContainer } from '../Home';
import { IndicatorsAdminContextProvider } from '../Indicators/context/IndicatorsContext';
import { IndicatorsAdminContainer } from '../Indicators';
import { MonitoringAdminContextProvider } from '../Monitoring/context/MonitoringAdminContext';
import { MonitoringAdminContainer } from '../Monitoring';
import { RecordsAdminContextProvider } from '../Records/context/RecordsAdminContext';
import { RecordsAdminContainer } from '../Records';

export const AdminPageRender: React.FC = () => {
  const { page } = useAdminPagesContext();
  if (page === 'Ajuda') {
    return <></>;
  }
  if (page === 'Configurações') {
    return (
      <ConfigAdminContextProvider>
        <AdminConfigContainer />
      </ConfigAdminContextProvider>
    );
  }
  if (page === 'Home') {
    return (
      <HomeAdminContextProvider>
        <HomeAdminContainer />
      </HomeAdminContextProvider>
    );
  }
  if (page === 'Indicadores') {
    return (
      <IndicatorsAdminContextProvider>
        <IndicatorsAdminContainer />
      </IndicatorsAdminContextProvider>
    );
  }
  if (page === 'Monitoramento') {
    return (
      <MonitoringAdminContextProvider>
        <MonitoringAdminContainer />
      </MonitoringAdminContextProvider>
    );
  }
  if (page === 'Registros') {
    return (
      <RecordsAdminContextProvider>
        <RecordsAdminContainer />
      </RecordsAdminContextProvider>
    );
  }
  return <></>;
};
