import { useCallback, useEffect, useMemo, useState } from 'react';

export function useUrlState<T = Record<string, string>, K = string>() {
  const [searchParams, setSearchParams] = useState<Record<string, K>>({});
  const [defaultValues, setDefaultValues] = useState<T extends string ? { [key in T]: K } : T>();

  let params = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);

  const onChangeParams = (key: T extends string ? T : keyof T, value: K | '') => {
    const newUrlSearchParams = new URLSearchParams(window.location.search);
    newUrlSearchParams.set(key as string, value as string);
    reloadUrlParams(newUrlSearchParams);
    setSearchParams((prev) => ({ ...prev, [key]: value as K }));
  }

  const clearUrlparams = useCallback(() => {
    const newUrl = window.location.pathname;
    window.history.replaceState(null, '', newUrl);
  }, []);

  const reloadUrlParams = useCallback(
    (newUrlSearchParams: URLSearchParams) => {
      if (!newUrlSearchParams.toString()) {
        return clearUrlparams();
      }
      const newUrl = `${window.location.pathname}?${newUrlSearchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    },
    [clearUrlparams]
  );

  useEffect(() => {
    let values: Record<string, K> = {};
    params.forEach((v, k) => values[k] = v as K);
    setSearchParams(values);
    setDefaultValues(values as T extends string ? { [key in T]: K } : T);
  }, [params]);

  useEffect(() => {
    const newParams = new URLSearchParams(window.location.search);

    const values = newParams
      .toString()
      .split('&')
      .map((v) => v.split('='));

    values.forEach(([key, value]) => {
      if (!value)
        newParams.delete(key);
    });

    return reloadUrlParams(newParams);
  }, [searchParams, params, reloadUrlParams, clearUrlparams]);

  return {
    onChangeParams,
    defaultValues,
  };
}
