import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.6rem 1.6rem 1.6rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #0823ad;
  @media (max-width: 576px) {
    padding: 2.6rem 0 1.6rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 3.5rem;
  padding: 3.5rem 2.4rem;
  overflow: hidden;
  background: #f3f3f3;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  @media (max-width: 576px) {
    border-top-right-radius: 0;
    padding: 2.5rem 1.5rem;
  }
`;

export const HeaderSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const HeaderButton = styled.button`
  all: unset;
  background-color: #e8e8e8;
  padding: 1.2rem 1.4rem;
  border-radius: 0.8rem;
  gap: 1.2rem;
  display: flex;
  align-items: center;
  color: #717171;
  font-family: Work Sans;
  font-weight: 500;
  & span {
    color: #2f4cdd;
  }
`;

export const AdminContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  border-radius: 2.4rem;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
`;

export const ContentTitle = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1.6rem;
  & span {
    color: #0e1742;
    font-family: Work Sans;
    font-weight: 500;
  }
`;

export const ContentItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.8rem;
`;
