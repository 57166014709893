import React, { useState } from 'react';
import * as S from './styles';
import { MenuPermission } from '../../../../../types';
import { pagesIcons } from './pages';
import { ArrowLeftVuesax } from '../../../../../Icons/ArrowLeft-Vuesax';
import { ArrowRightVuesax } from '../../../../../Icons/ArrowRight-Vuesax';

interface Props {
  pages: MenuPermission[];
}

export const PagesCarousel: React.FC<Props> = ({ pages }) => {
  const [currentPages, setCurrentPages] = useState<MenuPermission[]>(pages.slice(0, 4));
  const [currentPageHovered, setCurrentPageHovered] = useState('');

  const nextPage = () => {
    const localPages = [...currentPages];
    const lastItem = pages.findIndex((item) => item.key === localPages.at(-1)?.key);
    localPages.shift();
    if (lastItem === pages.length - 1) {
      localPages.push(pages[0]);
    } else {
      localPages.push(pages[lastItem + 1]);
    }
    setCurrentPages(localPages);
  };

  const prevPage = () => {
    const localPages = [...currentPages];
    const firstItem = pages.findIndex((item) => item.key === localPages[0].key);
    localPages.pop();
    if (firstItem === 0) {
      localPages.unshift(pages[pages.length - 1]);
    } else {
      localPages.unshift(pages[firstItem - 1]);
    }
    setCurrentPages(localPages);
  };

  return (
    <>
      <S.CarouselContainer>
        {currentPages.map((page, index, arr) => {
          const isCardHovered = currentPageHovered === page.key;
          return (
            <S.CardContainer
              style={{ width: `${100 / arr.length}%`, color: isCardHovered ? '#2f4cdd' : '#666666' }}
              key={page.key}
            >
              <S.PageCard
                onMouseEnter={() => setCurrentPageHovered(page.key)}
                onMouseLeave={() => setCurrentPageHovered('')}
              >
                {pagesIcons[page.icon]({
                  fill: isCardHovered ? '#ffffff' : '#666666',
                  style: {
                    width: '75%',
                    minWidth: '6.3rem',
                    minHeight: '7.2rem',
                  },
                })}
              </S.PageCard>
              {page.description}
            </S.CardContainer>
          );
        })}
      </S.CarouselContainer>
      <S.PageDescription></S.PageDescription>
      <S.PageControlButtons>
        <S.ControlButton disabled={currentPages[0].key === pages[0].key} onClick={prevPage}>
          <ArrowLeftVuesax />
        </S.ControlButton>
        <S.ControlButton
          disabled={currentPages[currentPages.length - 1].key === pages[pages.length - 1].key}
          onClick={nextPage}
        >
          <ArrowRightVuesax fill="#ffffff" />
        </S.ControlButton>
      </S.PageControlButtons>
    </>
  );
};
