export interface ModalForm {
  icon: string;
  info: string;
}

export const modalFormBlankValues = {
  icon: '',
  info: '',
};
export type HomeComponentList =
  | 'Monitoramento'
  | 'Emergência (tempo real)'
  | 'Ajuda'
  | 'Chegadas e saídas'
  | 'Retirada de PT'
  | 'Páginas';
