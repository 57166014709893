import React from 'react';
import { Moment } from 'moment';

import LogoNormal from '../../../../assets/brand/LogoNormal';
import { transformDate } from '../../../../utils/DateTime';

import * as S from './styles';

interface Period {
  initial: Moment;
  final: Moment;
}

interface Props {
  period?: Period;
  title: string;
  isBulletin?: boolean;
}

const getPeriod = (isBulletin?: boolean, period?: Period) => {
  if(isBulletin)
    return <></>;
  
  if(period)
    return `${transformDate(period.initial.toDate(), 'pt-BR')} 
      - ${transformDate(period.final.toDate(), 'pt-BR')}`;

  return 'Hoje'
}

export const PreviewHeader: React.FC<Props> = ({ period, title, isBulletin }) => {
  return (
    <S.PreviewHeader>
      <S.HeaderTitle>Relatório</S.HeaderTitle>
      <S.HeaderDate>
        {title}
        <br />
        {getPeriod(isBulletin, period)}
      </S.HeaderDate>
      <div>
        <LogoNormal />
      </div>
    </S.PreviewHeader>
  );
};
