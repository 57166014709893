import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { MonitoringComponentList } from '../types';
import { monitoringRowsMock } from '../mock';
import { RowItem } from '../../Config/types';
import { AdminModalForm, adminModalFormBlankValues } from '../../../types';

type Props = {
  children: ReactNode;
};

type MonitoringAdminContextProps = {
  modalForm: AdminModalForm;
  modalTitle: string;
  changeModalTitle: (title: string) => void;
  cards: RowItem<MonitoringComponentList>[];
  setCards: Dispatch<SetStateAction<RowItem<MonitoringComponentList>[]>>;
  modal: boolean;
  openModal: (name: string) => void;
  closeModal: () => void;
  setModalForm: Dispatch<SetStateAction<AdminModalForm>>;
  activeRow: RowItem<MonitoringComponentList> | undefined;
  setActiveRow: Dispatch<SetStateAction<RowItem<MonitoringComponentList> | undefined>>;
  onModalConfirm: () => void;
  isChanged: boolean;
  resetRows: () => void;
  initialCards: RowItem<MonitoringComponentList>[];
};

const MonitoringAdminContext = createContext<MonitoringAdminContextProps>({} as MonitoringAdminContextProps);

export function MonitoringAdminContextProvider(props: Props) {
  const [initialCards, setInitialCards] = useState<RowItem<MonitoringComponentList>[]>(monitoringRowsMock);
  const [cards, setCards] = useState<RowItem<MonitoringComponentList>[]>(monitoringRowsMock);
  const [modal, setModal] = useState(false);
  const [modalForm, setModalForm] = useState<AdminModalForm>(adminModalFormBlankValues);
  const [modalTitle, setModalTitle] = useState('');
  const [activeRow, setActiveRow] = useState<RowItem<MonitoringComponentList> | undefined>();

  const { children } = props;

  const closeModal = () => {
    setModalForm(adminModalFormBlankValues);
    setModal(false);
  };

  const openModal = (name: string) => {
    const row = cards.find((v) => v.name === name);
    if (!row) {
      return;
    } else {
      changeModalTitle(name);
      setModalForm({
        icon: row.icon,
        info: row.info || '',
      });
    }
    setModal(true);
  };

  const changeModalTitle = (value: string) => {
    setModalTitle(value);
  };

  const onModalConfirm = () => {
    const localCards = [...cards];
    if (!activeRow) {
      return;
    }
    const index = localCards.findIndex((item) => item.name === activeRow.name);
    if (index >= 0) {
      localCards.splice(index, 1, { ...activeRow, ...modalForm });
    }
    setCards(localCards);
    setActiveRow(localCards[index]);
    closeModal();
  };

  const isChanged = useMemo(() => {
    return JSON.stringify(cards.map((v) => v.selected)) !== JSON.stringify(initialCards.map((v) => v.selected));
  }, [cards]);

  const resetRows = () => {
    setCards(initialCards);
  };

  const value: MonitoringAdminContextProps = {
    cards,
    closeModal,
    modal,
    modalForm,
    modalTitle,
    openModal,
    setCards,
    changeModalTitle,
    setModalForm,
    activeRow,
    setActiveRow,
    onModalConfirm,
    isChanged,
    resetRows,
    initialCards,
  };

  return <MonitoringAdminContext.Provider value={value}>{children}</MonitoringAdminContext.Provider>;
}

export function useMonitoringAdminContext() {
  const context = useContext(MonitoringAdminContext);

  if (typeof context === 'undefined') {
    throw new Error('MonitoringAdminContext must be used within an useFaqContext');
  }

  return context;
}
