import React from 'react';
import { DefaultIconProps } from './types';

export const ClockArrowUpIcon: React.FC<DefaultIconProps> = ({ fill, height, onClick, stroke, style, width }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <g>
        <path
          d="M8.5 19.5C6.41667 19.5 4.64583 18.7708 3.1875 17.3125C1.72917 15.8542 1 14.0833 1 12C1 9.91667 1.72917 8.14583 3.1875 6.6875C4.64583 5.22917 6.41667 4.5 8.5 4.5C10.5833 4.5 12.3542 5.22917 13.8125 6.6875C15.2708 8.14583 16 9.91667 16 12C16 14.0833 15.2708 15.8542 13.8125 17.3125C12.3542 18.7708 10.5833 19.5 8.5 19.5ZM18.5 20V7.8L17.4 8.9L16 7.5L19.5 4L23 7.5L21.575 8.9L20.5 7.825V20H18.5ZM8.5 17.5C10.0333 17.5 11.3333 16.9667 12.4 15.9C13.4667 14.8333 14 13.5333 14 12C14 10.4667 13.4667 9.16667 12.4 8.1C11.3333 7.03333 10.0333 6.5 8.5 6.5C6.96667 6.5 5.66667 7.03333 4.6 8.1C3.53333 9.16667 3 10.4667 3 12C3 13.5333 3.53333 14.8333 4.6 15.9C5.66667 16.9667 6.96667 17.5 8.5 17.5ZM10.5 15.5L11.9 14.1L9.5 11.675V8H7.5V12.5L10.5 15.5Z"
          fill={fill || stroke || '#707070'}
        />
      </g>
    </svg>
  );
}