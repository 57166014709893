import React, { useEffect, useMemo, useState } from 'react';

import { ContentContainer } from '../../../../components/PageStructure/styles';
import { Page } from '../../../../components/PageStructure/Page';
import { AdminCustomTitle } from '../CustomTitle';
import { AdminCardRow } from '../CardRow';
import { AdminModalEdit } from '../ModalEdit';
import { MonitoringHomePreviewCard } from './PreviewCard';
import { useMonitoringAdminContext } from './context/MonitoringAdminContext';
import { TrackfyIcon } from '../../../../Icons/Trackfy';
import { IntegrationIcon } from '../../../../Icons/Intregration';
import { ToastConfirm } from '../ToastConfirm';
import { useAdminPagesContext } from '../../context/AdminPagesContext';

import * as S from './styles';

export const MonitoringAdminContainer: React.FC = () => {
  const {
    cards,
    activeRow,
    setActiveRow,
    setCards,
    modal,
    closeModal,
    onModalConfirm,
    modalTitle,
    setModalForm,
    modalForm,
    openModal,
    isChanged,
    resetRows,
    initialCards,
  } = useMonitoringAdminContext();
  const { updateComponents } = useAdminPagesContext();

  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    if (isChanged) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [isChanged]);

  const onToggleSelect = (row: string) => {
    const localRows = [...cards];
    const findRow = localRows.findIndex((v) => v.name === row);
    if (findRow >= 0) {
      localRows.splice(findRow, 1, { ...localRows[findRow], selected: !localRows[findRow].selected });
    }
    setActiveRow(localRows[findRow]);
    return setCards(localRows);
  };
  const infleetCardRow = useMemo(() => {
    const infleet = cards.find((item) => item.name === 'Infleet');
    return infleet;
  }, [cards]);
  return (
    <Page
      title=""
      customTitle={<AdminCustomTitle title="Monitoramento" />}
      components={
        <ContentContainer value="">
          <ToastConfirm
            onConfirm={() => {
              const componentsList = cards.map((card) => {
                return { name: card.name, active: card.selected };
              });
              updateComponents(componentsList);
            }}
            show={showToast}
            onCancel={() => {
              const componentsList = initialCards.map((card) => {
                return { name: card.name, active: card.selected };
              });
              updateComponents(componentsList);
              setShowToast(false);
              resetRows();
            }}
          />
          <AdminModalEdit
            onClickConfirm={onModalConfirm}
            closeModal={closeModal}
            modal={modal}
            modalForm={modalForm}
            modalTitle={modalTitle}
            setModalForm={setModalForm}
            tags={cards.find((v) => v.name === modalTitle)?.tags || []}
            onChangeTag={(tags) => {
              const localRows = [...cards];
              const findRow = localRows.findIndex((v) => v.name === modalTitle);
              if (findRow >= 0) {
                localRows.splice(findRow, 1, { ...localRows[findRow], tags });
              }
              setCards(localRows);
            }}
          />
          <S.Container>
            <S.AdminCard>
              <S.AdminCardHeader>
                <TrackfyIcon />
                Trackfy
              </S.AdminCardHeader>
              <S.AdminCardContent>
                {cards.map((row, index) =>
                  row.name === 'Infleet' ? (
                    <></>
                  ) : (
                    <AdminCardRow
                      key={`${row.name}-${index}`}
                      onClickRow={() => setActiveRow(row)}
                      disabled={row.disabled || false}
                      icon={row.icon}
                      isActive={activeRow?.name === row.name}
                      name={row.name}
                      onClickEdit={() => openModal(row.name)}
                      onClickSelect={() => {
                        onToggleSelect(row.name);
                      }}
                      selected={row.selected}
                    />
                  )
                )}
              </S.AdminCardContent>
              <S.AdminCardHeader>
                <IntegrationIcon />
                Integrações
              </S.AdminCardHeader>
              {infleetCardRow ? (
                <AdminCardRow
                  disabled={infleetCardRow.disabled || false}
                  icon={infleetCardRow.icon}
                  isActive={activeRow?.name === 'Infleet'}
                  name={infleetCardRow.name}
                  onClickSelect={() => onToggleSelect(infleetCardRow.name)}
                  selected={infleetCardRow.selected}
                  onClickRow={() => setActiveRow(infleetCardRow)}
                />
              ) : (
                <></>
              )}
            </S.AdminCard>
            <MonitoringHomePreviewCard />
          </S.Container>
        </ContentContainer>
      }
    ></Page>
  );
};
