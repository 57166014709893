import React from 'react';

import { CardHeader } from './CardHeader';
import { useCard } from './hooks/useCard';
import { useAuthContext } from '../../../../stores';
import { SummaryComponentsFactory } from './SummaryComponentsFactory';

import * as S from './styles';
import { CompanyFilter } from './components/CompanyFilter';
import { SatisfactionSurvey } from '../../../monitoring/components/MonitoringIndicators/satisfactionSurvey';

type Props = {
  titulo: string;
  civil: boolean;
  style?: React.CSSProperties;
};

export const IndicatorsCard: React.FC<Props> = ({ titulo, civil, style }: Props) => {
  const { authData } = useAuthContext();
  const showSurvey = authData?.showSurvey ? authData.showSurvey : false;
  const { cardExpanded, handleClickExpand } = useCard();

  return (
    <React.Fragment>
      {(authData?.components.summary.find((component:string) => component == "companyfilter")) 
        && <CompanyFilter />}

      <S.MonitoringIndicatorsContainer style={style}>
        <S.IndicatorsExpandableCard>
          <CardHeader titulo={titulo} expanded={cardExpanded} onClick={handleClickExpand} />
        </S.IndicatorsExpandableCard>
        {cardExpanded && (
          <SummaryComponentsFactory summaryComponents={authData ? authData.components.summary : []}
									clientId={authData ? authData.clientId : 0} />
        )}
      </S.MonitoringIndicatorsContainer>

      {showSurvey && <SatisfactionSurvey clientId="0" />}
    </React.Fragment>
  );
};