import React from 'react';

import { ActionColumn, TableRow as ITableRow } from './types';
import { ActionColumnTable } from './ActionColumn';

import * as S from './styles';

interface Props extends ITableRow {
  isOdd: boolean;
  background: string;
  height?: string;
  leftActionColumn?: ActionColumn;
  rightActionColumn?: ActionColumn;
}

export const TableRow: React.FC<Props> = ({ id, 
                                            cells, 
                                            isOdd, 
                                            background, 
                                            height, 
                                            leftActionColumn,
                                            rightActionColumn,
                                            props }) => {

  if (isOdd) {
    return (
      <S.TrstyledOdd key={'TBRow_' + id} backgroundColor={background} height={height ?? '22px'}>
        {leftActionColumn ? <ActionColumnTable {...leftActionColumn} row={{ id, cells, props }} /> : <></>}
        {cells.map((cell, index) => (<td key={`${index}-${cell}-${id}`}>{cell.value || '-'}</td>))}
        {rightActionColumn ? <ActionColumnTable {...rightActionColumn} row={{ id, cells, props }} /> : <></>}
      </S.TrstyledOdd>
    );
  }

  return (
    <S.Trstyled key={'TBRow_' + id} backgroundColor={background} height={height ?? '22px'}>
      {leftActionColumn ? <ActionColumnTable {...leftActionColumn} row={{ id, cells, props }} /> : <></>}
      {cells.map((cell, index) => (<td key={`${index}-${cell}-${id}`}>{cell.value || '-'}</td>))}
      {rightActionColumn ? <ActionColumnTable {...rightActionColumn} row={{ id, cells, props }} /> : <></>}
    </S.Trstyled>
  );
};