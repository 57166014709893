import React from 'react';
import { DefaultIconProps } from './types';
export const FlashIcon: React.FC<DefaultIconProps> = ({ fill, width, height, style, onClick }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M5.07492 11.0648H7.64992V17.0648C7.64992 18.4648 8.40826 18.7481 9.33326 17.6981L15.6416 10.5315C16.4166 9.65646 16.0916 8.93146 14.9166 8.93146H12.3416V2.93146C12.3416 1.53146 11.5833 1.24812 10.6583 2.29812L4.34992 9.46479C3.58326 10.3481 3.90826 11.0648 5.07492 11.0648Z"
        fill={fill || '#2F4CDD'}
      />
    </svg>
  );
};
