export type AdminComponentList =
  | 'Home'
  | 'Monitoramento'
  | 'Indicadores'
  | 'Registros'
  | 'Configurações'
  | 'Ajuda';

export interface AdminModalForm {
  icon: string;
  info: string;
}

export const adminModalFormBlankValues: AdminModalForm = {
  icon: '',
  info: '',
};

export type AdminTag = 'basic' | 'beta' | 'pro';
