import moment from 'moment';

export const getCurrentDateBrazilianFormat = () => {
  const currentDate: Date = new Date();
  const dt: string = currentDate.getDate().toString().padStart(2, "0");
  const month: string = (currentDate.getMonth() + 1).toString();
  const year: string = currentDate.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
}

export const parseToDateFormatted = (initialDate: Date, finalDate: Date) => {
  let parsedDate: any = '';

  if (moment(initialDate).format('DD/MM/YYYY') === moment(finalDate).format('DD/MM/YYYY')) {
    parsedDate = moment(initialDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ?
      'Hoje' : `${moment(initialDate).locale('pt-br').format('DD/MM/YY')}`;
  } 
  
  else if (moment(initialDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY'))
    parsedDate = `Hoje ~ ${moment(finalDate).locale('pt-br').format('DD/MM/YY')}`;
  
  else if (moment(finalDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY'))
    parsedDate = `${moment(initialDate).locale('pt-br').format('DD/MM/YY')} ~ Hoje`;

  else {
    parsedDate = `${moment(initialDate).locale('pt-br').format('DD/MM/YY')} ~ ` +
      `${moment(finalDate).locale('pt-br').format('DD/MM/YY')}`;
  }

  return parsedDate;
}

export const getFollowingFriday = (currentDate: Date): Date => {
  const friday: number = 5;

  if (currentDate.getDay() > 5) {
    currentDate.setDate(currentDate.getDate() + 6);
  } else {
    const dayDiff: number = friday - currentDate.getDay();
    currentDate.setDate(currentDate.getDate() + dayDiff);
  }

  return currentDate;
}

export const getLastCommercialDateBrazilianFormat = (): string => {
  const currentDate: Date = new Date();
  const weekDay: number = currentDate.getDay();

  if (weekDay === 1)
    currentDate.setDate(currentDate.getDate() - 3);
  else if (weekDay === 7) 
    currentDate.setDate(currentDate.getDate() - 2);
  else 
    currentDate.setDate(currentDate.getDate() - 1);

  const dt: string = currentDate.getDate().toString().padStart(2, "0");
  const month: string = (currentDate.getMonth() + 1).toString();
  const year: string = currentDate.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
}

export const getDateBrFormat = (currentDate: moment.Moment): string => {
  const dateObj: Date = currentDate.toDate();
  const dt: string = dateObj.getDate().toString().padStart(2, "0");
  const month: string = dateObj.getMonth().toString().padStart(2, "0");
  const year: string = dateObj.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
}

export const getDateBrFormatFromString = (currentDate: string): string => {
  const dateObj: Date = new Date(currentDate);
  dateObj.setHours(dateObj.getHours() + 3);

  const dt: string = dateObj.getDate().toString().padStart(2, "0");
  const month: string = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year: string = dateObj.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
}

export const getLastCommercialDateUSFormat = (): string => {
  const currentDate: Date = new Date();
  const weekDay: number = currentDate.getDay();

  if (weekDay === 1)
    currentDate.setDate(currentDate.getDate() - 3);
  else if (weekDay === 7) 
    currentDate.setDate(currentDate.getDate() - 2);
  else 
    currentDate.setDate(currentDate.getDate() - 1);

  const dt: string = currentDate.getDate().toString().padStart(2, "0");
  const month: string = (currentDate.getMonth() + 1).toString();
  const year: string = currentDate.getFullYear().toString();
  return `${year}-${month}-${dt}`;
}

export const createDateObjtWithTmezoffset = (currentDate: Date): Date => {
  const offset: number = currentDate.getTimezoneOffset();
  currentDate.setHours(currentDate.getHours() + offset / 60);
  return currentDate;
}

export const getLasCommercialMommentDate = (): moment.Moment => {
  const commercialDate: string = getLastCommercialDateUSFormat();
  const dtParts: string[] = commercialDate.split('-');
  const date: moment.Moment = moment(dtParts[0] + '-' + dtParts[1] + '-' + dtParts[2], 'YYYY-MM-DD');
  return date;
}

export const transformDate = (date: Date, format: string) =>
  new Intl.DateTimeFormat(format, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);

export const transformMinutesToHours = (minutes: number) => {
  const hours = (String(Math.floor(minutes / 60))).padStart(2,'0');
  const remainingMinutes = (String(Math.floor(minutes % 60))).padStart(2,'0');
  return `${hours}:${remainingMinutes}`;
}

export const transformHoursToMinutes = (value: number) => {
  const values = String(value).split('.');
  let [hours, minutes] = values.map(Number);
  hours = hours * 60;
  if (minutes < 10) {
    minutes = minutes * 10;
  }
  return hours + minutes;
}

export const compareISODates = (isoDate: string, isoDate2: string) => {
  const date = new Date(isoDate);
  const date2 = new Date(isoDate2);
  return (
    date.getDay() === date2.getDay() &&
    date.getMonth() === date2.getMonth() &&
    date.getUTCFullYear() === date2.getFullYear()
  );
}

export const contervSecondsToTime = (total: number):string => {  
  if(!total)
    return "00:00:00";

	const minutesRaw: number = Number.parseInt((total/ 60).toString());

	const hours: number = Number.parseInt((minutesRaw / 60).toString());
  const hourString = (hours.toString()).padStart(2,"0");

	const minutes: number = Number.parseInt((minutesRaw % 60).toString());
  const minuteString = (minutes.toString()).padStart(2,"0");

  const seconds: number = Number.parseInt((total % 60).toString());
  const secondsString = (seconds.toString()).padStart(2,"0");

	const timeString = hourString + ":" + minuteString + ":" + secondsString;
	return timeString;
}

export const transformISODateToObject = (dateString: string) => {
  const [date, hour] = dateString.split(' ');
  const [day, month, year] = date.split('/').map(Number);
  const [hours, minutes, seconds] = hour.split(':').map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
}

export const transformDateTime = (date: Date, format: string) =>
  new Intl.DateTimeFormat(format, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);

export const transformISOToDate = (isoDate: string) => {
  const date = new Date(isoDate);
  return new Intl.DateTimeFormat('pt-br', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);
}

export const transformISOToHours = (isoDate: string) => {
  const date = new Date(isoDate);
  return new Intl.DateTimeFormat('pt-br', {
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
}

export const transformISOToHoursWithSeconds = (isoDate: string) => {
  const date = new Date(isoDate);
  return new Intl.DateTimeFormat('pt-br', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(date);
}