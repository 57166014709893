import React, { useMemo } from 'react';

import { CartesianGrid, LineChart, XAxis, YAxis, Line, LabelProps } from 'recharts';
import { IPlanning } from '../../../types';

interface ResourcesAmount {
  available: number;
  registered: number;
  date: string;
}

interface Props {
  data: IPlanning[];
}

interface CustomizedLabelProps {
  x: number;
  y: number;
  stroke: string;
  value: number;
}

const CustomizedLabel: React.FC<CustomizedLabelProps> = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y - 5} dy={-4} fill={stroke} fontSize={14} textAnchor="middle" fontFamily="Work Sans">
      {value}
    </text>
  );
}

const getTicks = (data: ResourcesAmount[]) => {
  const maxAvailable = Math.max(...data.map((item) => item.available));
  const maxRegistered = Math.max(...data.map((item) => item.registered));
  const maxValue = Math.max(maxAvailable, maxRegistered);

  const roundedMaxValue = Math.ceil(maxValue / 10) * 10;

  let ticks = [];
  for (let i = 10; i <= roundedMaxValue; i += 10) {
    ticks.push(i);
  }

  return ticks;
}

const getDate = (dt: string) => {
  const crrdt = new Date(dt);
  crrdt.setDate(crrdt.getDate() - 6);
  return (crrdt.getDate().toString()).padStart(2, "0") + "/" 
    + ((crrdt.getMonth() + 1).toString()).padStart(2, "0")  
}

export const ResourcesAmountChart: React.FC<Props> = ({ data }) => {
  const sanitizedData: ResourcesAmount[] = useMemo(() => {
    const allAvailable = data.filter((v) => v.type === 'Quantidade de recursos disponíveis');
    const allRegistered = data.filter((v) => v.type === 'Quantidade de recursos registrados');

    return allAvailable.map((availableItem) => ({
      available: availableItem.value,
      registered: allRegistered.find((registeredItem) => registeredItem.time === availableItem.time)?.value || 0,
      date: availableItem.time,
    }));
  }, [data]);

  return (
    <LineChart
      defaultShowTooltip={false}
      className={'pageBreakAfter'}
      width={700}
      height={400}
      data={sanitizedData}
    >
      <CartesianGrid vertical={false} horizontal={false} />
      <XAxis padding={{ right: 30, left: 10 }} dataKey={'date'} allowDataOverflow minTickGap={15} />
      <YAxis
        domain={['dataMin - 10', 'dataMax + 2']}
        ticks={getTicks(sanitizedData)}
        interval={0}
        minTickGap={10}
        dataKey={'available'}
      />
      <Line
        //label={(props: CustomizedLabelProps) => <CustomizedLabel {...props} stroke={'#2f4cdd'} />}
        dataKey={'available'}
        stroke={'#2f4cdd'}
        activeDot={{ r: 8, color: '#2f4cdd' }}
      />
      <Line
        //label={(props: CustomizedLabelProps) => <CustomizedLabel {...props} stroke={'#E74011'} />}
        dataKey={'registered'}
        stroke={'#E74011'}
        activeDot={{ r: 8, color: '#E74011' }}
      />
    </LineChart>
  );
}