import { CardItem, ConfigRowNames } from './types';

export const configCardsMock: CardItem<ConfigRowNames>[] = [
  {
    title: 'Parametrização',
    active: true,
    rows: [
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.9082 6.48299V14.5913C1.9082 16.1747 3.0332 16.8247 4.39987 16.0413L6.3582 14.9247C6.7832 14.683 7.49154 14.658 7.9332 14.883L12.3082 17.0747C12.7499 17.2913 13.4582 17.2747 13.8832 17.033L17.4915 14.9663C17.9499 14.6997 18.3332 14.0497 18.3332 13.5163V5.40799C18.3332 3.82465 17.2082 3.17465 15.8415 3.95799L13.8832 5.07465C13.4582 5.31632 12.7499 5.34132 12.3082 5.11632L7.9332 2.93299C7.49154 2.71632 6.7832 2.73299 6.3582 2.97465L2.74987 5.04132C2.2832 5.30799 1.9082 5.95799 1.9082 6.48299Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.13379 3.33594V14.1693" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.1084 5.51562V16.6656" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        name: 'Áreas',
        selected: false,
      },
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0165 2.42188C7.25816 2.42188 5.0165 4.66354 5.0165 7.42187V9.83021C5.0165 10.3385 4.79983 11.1135 4.5415 11.5469L3.58316 13.1385C2.9915 14.1219 3.39983 15.2135 4.48316 15.5802C8.07483 16.7802 11.9498 16.7802 15.5415 15.5802C16.5498 15.2469 16.9915 14.0552 16.4415 13.1385L15.4832 11.5469C15.2332 11.1135 15.0165 10.3385 15.0165 9.83021V7.42187C15.0165 4.67187 12.7665 2.42188 10.0165 2.42188Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M11.5579 2.66719C11.2996 2.59219 11.0329 2.53385 10.7579 2.50052C9.95794 2.40052 9.19128 2.45885 8.47461 2.66719C8.71628 2.05052 9.31628 1.61719 10.0163 1.61719C10.7163 1.61719 11.3163 2.05052 11.5579 2.66719Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5166 15.8828C12.5166 17.2578 11.3916 18.3828 10.0166 18.3828C9.33327 18.3828 8.69993 18.0995 8.24993 17.6495C7.79993 17.1995 7.5166 16.5661 7.5166 15.8828" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10"/>
</svg>
`,
        name: 'Alertas',
        selected: true,
      },
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.2913 11.0417C17.2913 15.0667 14.0247 18.3333 9.99967 18.3333C5.97467 18.3333 2.70801 15.0667 2.70801 11.0417C2.70801 7.01667 5.97467 3.75 9.99967 3.75C14.0247 3.75 17.2913 7.01667 17.2913 11.0417Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 6.66406V10.8307" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.5 1.66406H12.5" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        name: 'Turnos',
        selected: false,
      },
      {
        icon: `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0004 5.96407C14.9504 5.95573 14.8921 5.95573 14.8421 5.96407C13.6921 5.9224 12.7754 4.98073 12.7754 3.81406C12.7754 2.6224 13.7337 1.66406 14.9254 1.66406C16.1171 1.66406 17.0754 2.63073 17.0754 3.81406C17.0671 4.98073 16.1504 5.9224 15.0004 5.96407Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.1422 12.0309C15.2839 12.2225 16.5422 12.0225 17.4255 11.4309C18.6005 10.6475 18.6005 9.3642 17.4255 8.58086C16.5339 7.9892 15.2589 7.78919 14.1172 7.98919" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.97539 5.96407C5.02539 5.95573 5.08372 5.95573 5.13372 5.96407C6.28372 5.9224 7.20039 4.98073 7.20039 3.81406C7.20039 2.6224 6.24206 1.66406 5.05039 1.66406C3.85873 1.66406 2.90039 2.63073 2.90039 3.81406C2.90872 4.98073 3.82539 5.9224 4.97539 5.96407Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.83353 12.0309C4.69186 12.2225 3.43353 12.0225 2.5502 11.4309C1.3752 10.6475 1.3752 9.3642 2.5502 8.58086C3.44186 7.9892 4.71686 7.78919 5.85853 7.98919" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99941 12.1906C9.94941 12.1823 9.89108 12.1823 9.84108 12.1906C8.69108 12.149 7.77441 11.2073 7.77441 10.0406C7.77441 8.84896 8.73275 7.89062 9.92441 7.89062C11.1161 7.89062 12.0744 8.85729 12.0744 10.0406C12.0661 11.2073 11.1494 12.1573 9.99941 12.1906Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.57461 14.8187C6.39961 15.6021 6.39961 16.8854 7.57461 17.6687C8.90794 18.5604 11.0913 18.5604 12.4246 17.6687C13.5996 16.8854 13.5996 15.6021 12.4246 14.8187C11.0996 13.9354 8.90794 13.9354 7.57461 14.8187Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

        `,
        name: 'Equipes',
        selected: true,
      },
      {
        icon: `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.4166 18.331H3.39993C2.43327 18.331 1.6416 17.556 1.6416 16.606V4.23935C1.6416 2.05602 3.2666 1.06435 5.25827 2.03935L8.95827 3.85602C9.75827 4.24769 10.4166 5.28935 10.4166 6.17269V18.331Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.3087 12.5464V15.6964C18.3087 17.4964 17.4753 18.3297 15.6753 18.3297H10.417V8.67969L10.8087 8.76302L14.5587 9.60469L16.2503 9.97969C17.3503 10.2214 18.2503 10.788 18.3003 12.388C18.3087 12.438 18.3087 12.488 18.3087 12.5464Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.58301 7.5H7.47467" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.58301 10.8359H7.47467" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.5586 9.60729V12.2906C14.5586 13.324 13.7169 14.1656 12.6836 14.1656C11.6503 14.1656 10.8086 13.324 10.8086 12.2906V8.76562L14.5586 9.60729Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.3003 12.3927C18.2503 13.376 17.4336 14.1677 16.4336 14.1677C15.4003 14.1677 14.5586 13.326 14.5586 12.2927V9.60938L16.2503 9.98437C17.3503 10.226 18.2503 10.7927 18.3003 12.3927Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

        `,
        name: 'Empresas',
        selected: true,
      },
      {
        icon: `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.167 17.5H5.83366C2.50033 17.5 1.66699 16.6667 1.66699 13.3333V6.66667C1.66699 3.33333 2.50033 2.5 5.83366 2.5H14.167C17.5003 2.5 18.3337 3.33333 18.3337 6.66667V13.3333C18.3337 16.6667 17.5003 17.5 14.167 17.5Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.667 6.66406H15.8337" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5 10H15.8333" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.167 13.3359H15.8337" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.08353 9.40729C7.91656 9.40729 8.59186 8.73199 8.59186 7.89896C8.59186 7.06593 7.91656 6.39062 7.08353 6.39062C6.2505 6.39062 5.5752 7.06593 5.5752 7.89896C5.5752 8.73199 6.2505 9.40729 7.08353 9.40729Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.0003 13.6104C9.88366 12.4021 8.92533 11.4521 7.71699 11.3437C7.30033 11.3021 6.87533 11.3021 6.45033 11.3437C5.24199 11.4604 4.28366 12.4021 4.16699 13.6104" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        name: 'Funções',
        selected: true,
      },
    ],
  },
  {
    title: 'Vinculação',
    active: true,
    rows: [
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        name: 'Vinculação de Colaboradores',
        selected: true,
      },
    ],
  },
  {
    title: 'Usuários',
    active: false,
    rows: [
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.63314 9.05817C7.5498 9.04984 7.4498 9.04984 7.35814 9.05817C5.3748 8.9915 3.7998 7.3665 3.7998 5.3665C3.7998 3.32484 5.4498 1.6665 7.4998 1.6665C9.54147 1.6665 11.1998 3.32484 11.1998 5.3665C11.1915 7.3665 9.61647 8.9915 7.63314 9.05817Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.6747 3.3335C15.2914 3.3335 16.5914 4.64183 16.5914 6.25016C16.5914 7.82516 15.3414 9.1085 13.7831 9.16683C13.7164 9.1585 13.6414 9.1585 13.5664 9.16683" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.4666 12.1335C1.44993 13.4835 1.44993 15.6835 3.4666 17.0252C5.75827 18.5585 9.5166 18.5585 11.8083 17.0252C13.8249 15.6752 13.8249 13.4752 11.8083 12.1335C9.52494 10.6085 5.7666 10.6085 3.4666 12.1335Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.2832 16.6665C15.8832 16.5415 16.4499 16.2998 16.9165 15.9415C18.2165 14.9665 18.2165 13.3582 16.9165 12.3832C16.4582 12.0332 15.8999 11.7998 15.3082 11.6665" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

`,
        name: 'Convidar usuário',
        selected: true,
      },
    ],
  },
  {
    title: 'Log de configurações',
    active: true,
    rows: [
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        name: 'Log de configurações',
        selected: true,
      },
    ],
  },
];
