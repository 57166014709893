import React from 'react';

import { DefaultIconProps } from './types';

export const EditIcon: React.FC<DefaultIconProps> = ({ fill, width, height, style, onClick }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M7.33398 1.33203H6.00065C2.66732 1.33203 1.33398 2.66536 1.33398 5.9987V9.9987C1.33398 13.332 2.66732 14.6654 6.00065 14.6654H10.0007C13.334 14.6654 14.6673 13.332 14.6673 9.9987V8.66536"
        stroke={fill || '#2F4CDD'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6933 2.01155L5.43992 7.26488C5.23992 7.46488 5.03992 7.85822 4.99992 8.14488L4.71325 10.1515C4.60659 10.8782 5.11992 11.3849 5.84659 11.2849L7.85325 10.9982C8.13325 10.9582 8.52659 10.7582 8.73325 10.5582L13.9866 5.30488C14.8933 4.39822 15.3199 3.34488 13.9866 2.01155C12.6533 0.678215 11.5999 1.10488 10.6933 2.01155Z"
        stroke={fill || '#2F4CDD'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.93945 2.76562C10.3861 4.35896 11.6328 5.60562 13.2328 6.05896"
        stroke={fill || '#2F4CDD'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};