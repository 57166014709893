import React, { useState } from 'react';

import { InfoTooltip, TooltipContent } from './Tooltip';
import { AlertIconVuesax } from '../../Icons/Alert-Vuesax';
import { useAuthContext } from '../../stores';
import { useAuth } from '../../hooks';
import { mixpanelTrack } from '../../services/SystemMonitor';

import * as S from './styles';

type InfoProps = {
  content: TooltipContent[];
  position: string;
  burndown: boolean;
  screen?: string;
  eventName?: string;
};

export const Info: React.FC<InfoProps> = (props: InfoProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  
  const onHover = async () => {
    setShowTooltip(true)
    mixpanelTrack(monitorAgent, `Ícone de informação - ${props.eventName}`, userName, authData, `info>${props.screen}${props.eventName}`)
  }

  return(
    <S.InfoContainer onMouseEnter={() => onHover()} onMouseLeave={() => setShowTooltip(false)}>
      <AlertIconVuesax/>
      <InfoTooltip visible={showTooltip} content={props.content} position={props.position} burndown={props.burndown}/>
    </S.InfoContainer>
  )
};