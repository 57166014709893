export const getEndpoint = (method: string, local: boolean): string => {  
  const maindomain = "https://backend.trackfyapp.com"; 
  const localdomain = "http://localhost:8083"; 
  const serverHost = (local ? localdomain : maindomain) + `/rest`;

  const endpoints: Record<string, string> = {
    absenteeism: `/v2/monitoring/absenteeism`,
    adminComponentAccess: `/v2/admin/componentaccess`,
    adminPageAccess: `/v2/admin/pageaccess`,
    areasFloor: `/monitoring/areas/floor`,
    areasTimeDivision: `/v2/statistics/areasTimeDivision`,
    areaTimeline: `/v2/statistics/areatimeline`,
    arrivalAndDeparture: `/v2/statistics/arrivalanddeparture`,
    availableCollaborators: `/v2/monitoring/availableCollaborators`,
    availablevsmonitoredresources: `/v2/statistics/availablevsmonitoredresources`,
    averageTimeByPeople: `/v2/statistics/averageTimeByPeople`,
    bulletinInfos: `/reports/v1/informations`,
    burndown: `/v2/statistics/burndown`,
    cancelinvite: `/user/invite/cancel`,
    calendar: `/v2/statistics/calendar`,
    changePassword: `/auth/change-password`,
    collaborators: `/v2/monitoring/collaborators`,
    collaboratorsCount: `/v2/monitoring/collaboratorsCount`,
    collaboratorsMonitored: `/v1/collaborators`,
    collaboratorTimeline: `/v2/statistics/collaboratorTimeline`,
    coordinates: `/monitoring/coordinates`,
    createdclients: `/v1/clients/created`,
    createdcompanies: `/v1/companies/created`,
    createarea: `/v1/area/create`,
    createclient: `/v1/client/create`,
    createcompany: `/v1/company/create`,
    currentlocation: `/v2/engine/currentlocation`,
    currentlocationtype: `/engine/currentlocationtype`,
    deleteCollaborator: `/v1/resourceMonitoring/deleteCollaborators`,
    displacementsAtoB: `/v2/statistics/displacementsAtoB`,
    edituser: `/user/edit`,
    emergencyAreasV1: `/monitoring/emergency/areas`,
    emergencyAreas: `/v2/monitoring/emergency/areas`,
    emergencyNotifications: `/v2/monitoring/emergency/notifications`,
    emergencyEvacuation: `/v2/monitoring/emergency/evacuationnew`,
    emergencyEvacuationList: `/v2/monitoring/emergency/evacuations`,
    entryCount: `/v2/statistics/entryCount`,
    floors: `/v2/monitoring/floors`,
    tagsToLink: `/v1/resourceMonitoring/tagsToLink`,
    linkedTags: `/v1/resourceMonitoring/linkedTags`,
    heatmaps: `/v2/statistics/heatmaps`,
    hhquantity: `/v2/monitoring/hhquantity`,
    homePageInOut: `/v2/statistics/homePageInOut`,
    homePageWorkingPermit: `/v2/statistics/homePageWorkingPermit`,
    incidents: `/v2/monitoring/emergency/incidents`,
    infleet: `/v2/monitoring/infleet/resources`,
    inOut: `/v2/statistics/inOut`,
    invites: `/user/invites`,
    inviteusers: `/user/invite`,
    linkNewCollaborator: `/v1/resourceMonitoring/addCollaborators`,
    locationsByArea: `/v2/monitoring/locationsbyarea`,
    login: `/auth/login`,
    logtypes: `/v1/logtypes`,
    logs: `/v1/logs`,
    monitoringAreas: `/v2/monitoring/areas`,
    monitoredhh: `/v2/statistics/monitoredhh`,
    movementhistory: `/v2/statistics/movementhistory`,
    newCollaboratorAlias: `/v1/resourceMonitoring/newCollaboratorAlias`,
    peopleInAreas: `/v2/statistics/peopleInAreas`,
    profiles: `/user/profile`,
    productivityByArea: `/v2/statistics/productivityByArea`,
    quantityResources: `/v2/statistics/quantityresources`,
    rankByProfession: `/v2/statistics/rankByProfession`,
    recoverPassword: `/auth/recover-password`,
    refreshToken: `/auth/refreshToken`,
    register: `/auth/register`,
    replaceCollaborator: `/v1/resourceMonitoring/replaceCollaborators`,
    resourcesBurndown: `/v2/statistics/resourcesburndown`,
    resourcestimedivision: `/v2/statistics/resourcestimedivision`,
    resourcestimedivisionbyhr: `/v2/statistics/resourcestimedivisionbyhr`,
    revokeuser: `/user/revoke`,
    statisticsCollaborators: `/v2/statistics/collaborators`,
    scannersStatus: `/v2/resourcesmonitoring/scanners/status`,
    simpleareas: `/monitoring/simpleareas`,
    sitecoordinates: `/monitoring/sitecoordinates`,
    solveincident: `/v2/monitoring/emergency/solveincident`,
    timeline: `/v2/statistics/timeline`,
    workingPermit: `/v2/statistics/workingPermit`,
  }

  return `${serverHost}${endpoints[method] ?? ""}`;
}
