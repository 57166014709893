import React, { useState } from 'react';
import * as S from './styles';
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  show: boolean;
}
export const ToastConfirm: React.FC<Props> = ({ onConfirm, onCancel, show }) => {
  return (
    <S.Container show={show}>
      <h1>Deseja salvar as alterações?</h1>
      <S.ButtonsContainer>
        <SecondaryButton onClick={onCancel} style={{ width: '100%' }}>
          Cancelar
        </SecondaryButton>
        <PrimaryButton onClick={onConfirm} style={{ width: '100%' }} disabled={!show}>
          Salvar
        </PrimaryButton>
      </S.ButtonsContainer>
    </S.Container>
  );
};
