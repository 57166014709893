import React from 'react';
import * as S from './styles';
import { useMonitoringAdminContext } from './context/MonitoringAdminContext';
import { MonitorIcon } from '../../../../Icons/Monitor';
import { AlertIconVuesax } from '../../../../Icons/Alert-Vuesax';
import { PreviewCardMonitoring } from './PreviewCardMonitoring';
import { DetectiveContextProvider } from '../../../monitoring/components/MonitoringIndicators/detective/context/DetectiveContext';
import { CompaniesContextProvider } from '../../../realTime/context/CompaniesContext';
import { AreasContextProvider } from '../../../realTime/context/AreasContext';

export const MonitoringHomePreviewCard: React.FC = () => {
  const { activeRow } = useMonitoringAdminContext();
  return (
    <S.PreviewContainer>
      <S.AdminCardHeader>
        <MonitorIcon width={20} height={20} fill="#666666" />
        Preview
        <AlertIconVuesax />
      </S.AdminCardHeader>
      {activeRow ? (
        <DetectiveContextProvider>
          <AreasContextProvider>
            <CompaniesContextProvider>
              <PreviewCardMonitoring {...activeRow} component={activeRow.name} />
            </CompaniesContextProvider>
          </AreasContextProvider>
        </DetectiveContextProvider>
      ) : (
        <></>
      )}
    </S.PreviewContainer>
  );
};
