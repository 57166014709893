import React, { useEffect, useState } from 'react';

import { ContentContainer } from '../../../../components/PageStructure/styles';
import { Page } from '../../../../components/PageStructure/Page';
import { AdminCustomTitle } from '../CustomTitle';
import { AdminCardRow } from '../CardRow';
import { BoxesIcon } from '../../../../Icons/Boxes';
import { AlertIconVuesax } from '../../../../Icons/Alert-Vuesax';
import { AdminHomePreviewCard } from './PreviewCard';
import { useHomeAdminContext } from './context/HomeContext';
import { AdminModalEdit } from '../ModalEdit';
import { ToastConfirm } from '../ToastConfirm';
import { useAdminPagesContext } from '../../context/AdminPagesContext';

import * as S from './styles';

export const HomeAdminContainer: React.FC = () => {
  const {
    cards,
    setCards,
    openModal,
    activeRow,
    setActiveRow,
    onModalConfirm,
    closeModal,
    modal,
    modalForm,
    modalTitle,
    setModalForm,
    isChanged,
    resetRows,
    initialCards,
  } = useHomeAdminContext();
  const { updateComponents } = useAdminPagesContext();
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (isChanged) 
      setShowToast(true);
    else
      setShowToast(false);
  }, [isChanged]);

  const onToggleSelect = (row: string) => {
    const localRows = [...cards];
    const findRow = localRows.findIndex((v) => v.name === row);
    if (findRow >= 0) {
      localRows.splice(findRow, 1, { ...localRows[findRow], selected: !localRows[findRow].selected });
    }
    return setCards(localRows);
  }

  return (
    <Page
      title=""
      customTitle={<AdminCustomTitle title="Home" />}
      components={
        <ContentContainer value="">
          <ToastConfirm
            onConfirm={() => {
              const componentsList = cards.map((card) => ({ name: card.name, active: card.selected }) );
              updateComponents(componentsList);
            }}
            show={showToast}
            onCancel={() => {
              const componentsList = initialCards.map((card) => ({ name: card.name, active: card.selected }) );
              updateComponents(componentsList);
              setShowToast(false);
              resetRows();
            }}
          />
          <AdminModalEdit
            onClickConfirm={onModalConfirm}
            closeModal={closeModal}
            modal={modal}
            modalForm={modalForm}
            modalTitle={modalTitle}
            setModalForm={setModalForm}
            tags={activeRow?.tags || []}
            onChangeTag={(tags) => {
              if (activeRow)
                setActiveRow({ ...activeRow, tags });
            }}
          />
          <S.Container>
            <S.AdminCard>
              <S.AdminCardHeader>
                <BoxesIcon />
                Funcionalidades
                <AlertIconVuesax />
              </S.AdminCardHeader>
              <S.AdminCardContent>
                {cards.map((row, index) => (
                  <AdminCardRow
                    key={`${row.name}-${index}`}
                    onClickRow={() => setActiveRow(row)}
                    disabled={false}
                    icon={row.icon}
                    isActive={activeRow?.name === row.name}
                    name={row.name}
                    onClickEdit={() => openModal(row.name)}
                    onClickSelect={() => {
                      onToggleSelect(row.name);
                    }}
                    selected={row.selected}
                  />
                ))}
              </S.AdminCardContent>
            </S.AdminCard>
            <AdminHomePreviewCard />
          </S.Container>
        </ContentContainer>
      }
    ></Page>
  );
};
