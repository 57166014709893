import React from 'react';
import { DefaultIconProps } from './types';

export const CalendarRepeat: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <mask id="mask0_11776_1796" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11776_1796)">
        <path
          d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V12H19V10H5V20H12V22H5ZM19 24C17.7833 24 16.7208 23.6208 15.8125 22.8625C14.9042 22.1042 14.3333 21.15 14.1 20H15.65C15.8667 20.7333 16.2792 21.3333 16.8875 21.8C17.4958 22.2667 18.2 22.5 19 22.5C19.9667 22.5 20.7917 22.1583 21.475 21.475C22.1583 20.7917 22.5 19.9667 22.5 19C22.5 18.0333 22.1583 17.2083 21.475 16.525C20.7917 15.8417 19.9667 15.5 19 15.5C18.5167 15.5 18.0667 15.5875 17.65 15.7625C17.2333 15.9375 16.8667 16.1833 16.55 16.5H18V18H14V14H15.5V15.425C15.95 14.9917 16.475 14.6458 17.075 14.3875C17.675 14.1292 18.3167 14 19 14C20.3833 14 21.5625 14.4875 22.5375 15.4625C23.5125 16.4375 24 17.6167 24 19C24 20.3833 23.5125 21.5625 22.5375 22.5375C21.5625 23.5125 20.3833 24 19 24ZM5 8H19V6H5V8Z"
          fill="#707070"
        />
      </g>
    </svg>
  );
}