import { RowItem } from './components/Config/types';
import { AdminComponentList } from './types';

export const adminRowsMock: RowItem<AdminComponentList>[] = [
  {
    name: 'Home',
    icon: `homepage`,
    selected: true,
    path: 'admin/home',
  },
  {
    name: 'Monitoramento',
    icon: `realtime`,
    selected: true,
    path: 'admin/temporeal',
  },
  {
    name: 'Indicadores',
    icon: `indicators`,
    selected: true,
    path: 'admin/indicadores',
  },
  {
    name: 'Registros',
    icon: `incidents`,
    selected: true,
    path: 'admin/registros',
  },
  {
    name: 'Configurações',
    icon: `config`,
    selected: true,
    path: 'admin/config',
  },
  {
    name: 'Ajuda',
    icon: `support`,
    selected: true,
    path: 'admin/help',
  },
];

export const adminIcons: Record<string, string> = {
  support: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.167 15.3568H10.8337L7.12532 17.8235C6.57532 18.1901 5.83366 17.7985 5.83366 17.1318V15.3568C3.33366 15.3568 1.66699 13.6902 1.66699 11.1902V6.1901C1.66699 3.6901 3.33366 2.02344 5.83366 2.02344H14.167C16.667 2.02344 18.3337 3.6901 18.3337 6.1901V11.1902C18.3337 13.6902 16.667 15.3568 14.167 15.3568Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.4654 10.54L10.2016 9.83637M7.55846 10.54L7.82234 9.83637M6.58947 6.66406H4.16699V8.60205M4.16699 10.54V8.60205M4.16699 8.60205H6.10498M13.8569 9.16406C14.3414 9.16406 15.698 10.8307 16.0856 10.8307M10.2016 9.83637L10.1021 9.57104L9.01195 6.66406C9.01195 6.66406 8.34755 8.43579 7.92184 9.57104C7.88298 9.67466 7.82234 9.83637 7.82234 9.83637M10.2016 9.83637H7.82234M15.7949 8.60205C15.7949 9.67237 14.9272 10.54 13.8569 10.54C12.7866 10.54 11.9189 9.67237 11.9189 8.60205C11.9189 7.53173 12.7866 6.66406 13.8569 6.66406C14.9272 6.66406 15.7949 7.53173 15.7949 8.60205Z" stroke="#808080" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
  config: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.66699 10.7319V9.26523C1.66699 8.39856 2.37533 7.6819 3.25033 7.6819C4.75866 7.6819 5.37533 6.61523 4.61699 5.3069C4.18366 4.5569 4.44199 3.5819 5.20033 3.14856L6.64199 2.32356C7.30033 1.9319 8.15033 2.16523 8.54199 2.82356L8.63366 2.9819C9.38366 4.29023 10.617 4.29023 11.3753 2.9819L11.467 2.82356C11.8587 2.16523 12.7087 1.9319 13.367 2.32356L14.8087 3.14856C15.567 3.5819 15.8253 4.5569 15.392 5.3069C14.6337 6.61523 15.2503 7.6819 16.7587 7.6819C17.6253 7.6819 18.342 8.39023 18.342 9.26523V10.7319C18.342 11.5986 17.6337 12.3152 16.7587 12.3152C15.2503 12.3152 14.6337 13.3819 15.392 14.6902C15.8253 15.4486 15.567 16.4152 14.8087 16.8486L13.367 17.6736C12.7087 18.0652 11.8587 17.8319 11.467 17.1736L11.3753 17.0152C10.6253 15.7069 9.39199 15.7069 8.63366 17.0152L8.54199 17.1736C8.15033 17.8319 7.30033 18.0652 6.64199 17.6736L5.20033 16.8486C4.44199 16.4152 4.18366 15.4402 4.61699 14.6902C5.37533 13.3819 4.75866 12.3152 3.25033 12.3152C2.37533 12.3152 1.66699 11.5986 1.66699 10.7319Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
  incidents: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.33301 15.8307V5.71168C3.33301 2.47359 4.11732 1.66406 7.25458 1.66406H12.7448C15.882 1.66406 16.6663 2.47359 16.6663 5.71168V13.8069C16.6663 13.9203 16.6663 14.5031 16.6585 14.6164" stroke="#808080" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.5683 15H16.6663V16.6667C16.6663 17.5857 15.435 18.3333 13.9212 18.3333H6.07811C4.56438 18.3333 3.33301 17.5857 3.33301 16.6667V16.3571C3.33301 15.6095 4.33693 15 5.5683 15Z" stroke="#808080" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.66699 5H13.3337" stroke="#808080" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.66699 7.91406H10.8337" stroke="#808080" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.66699 10.8359H9.16699" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
  indicators: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.66699 18.3359H18.3337" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.125 3.33073V18.3307H11.875V3.33073C11.875 2.41406 11.5 1.66406 10.375 1.66406H9.625C8.5 1.66406 8.125 2.41406 8.125 3.33073Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2.5 8.33073V18.3307H5.83333V8.33073C5.83333 7.41406 5.5 6.66406 4.5 6.66406H3.83333C2.83333 6.66406 2.5 7.41406 2.5 8.33073Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.167 12.5026V18.3359H17.5003V12.5026C17.5003 11.5859 17.167 10.8359 16.167 10.8359H15.5003C14.5003 10.8359 14.167 11.5859 14.167 12.5026Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
  realtime: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
  homepage: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.51699 2.36517L3.02533 5.86517C2.27533 6.44851 1.66699 7.69017 1.66699 8.63184V14.8068C1.66699 16.7402 3.24199 18.3235 5.17533 18.3235H14.8253C16.7587 18.3235 18.3337 16.7402 18.3337 14.8152V8.74851C18.3337 7.74017 17.6587 6.44851 16.8337 5.87351L11.6837 2.26517C10.517 1.44851 8.64199 1.49017 7.51699 2.36517Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 14.9922V12.4922" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
};
