import { CardItem, IndicatorsRowNames } from './types';

export const indicatorsCardMock: CardItem<IndicatorsRowNames>[] = [
  {
    title: 'Conformidade',
    active: true,
    rows: [
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        name: 'Planejado x Realizado',
        selected: true,
      },
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        name: 'Linha do tempo de presença',
        selected: true,
      },
    ],
  },
  {
    active: true,
    title: 'Produtividade',
    rows: [
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        name: 'Chegada/saída das áreas produtivas',
        selected: true,
      },
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
        name: 'Estimativa de retirada de PT',
      },
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
        name: 'Tempo médio por recurso',
      },
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
        name: 'Ranking de produtividade',
      },
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
        name: 'Recursos por áreas',
      },
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
        name: 'Tempo acumulado de presença por área',
      },
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
        name: 'Contador de entrada em áreas por função',
      },
    ],
  },
  {
    title: 'Movimentação',
    active: false,
    rows: [
      {
        name: 'Mapa de calor',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
      },
      {
        name: 'Linha do tempo de produtividade',
        selected: true,
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
      },
      {
        name: 'Movimentação geral',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>`,
        selected: true,
      },
    ],
  },
  {
    title: 'Deslocamento',
    active: true,
    rows: [
      {
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        name: 'Deslocamento entre áreas',
        selected: true,
      },
    ],
  },
  {
    title: 'Segurança',
    active: true,
    rows: [
      {
        name: 'Quantidade de alertas',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
      },
      {
        name: 'Tempo de resolução médio',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
      },
      {
        name: 'Ranking de alertas',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
      },
      {
        name: 'Horário médio dos alertas',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.5V11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99986 17.8453H4.94986C2.0582 17.8453 0.849863 15.7786 2.24986 13.2536L4.84986 8.57031L7.29986 4.17031C8.7832 1.49531 11.2165 1.49531 12.6999 4.17031L15.1499 8.57865L17.7499 13.262C19.1499 15.787 17.9332 17.8536 15.0499 17.8536H9.99986V17.8453Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99512 14.1641H10.0026" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
        selected: true,
      },
    ],
  },
];
