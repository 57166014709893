import { RowItem } from '../Config/types';
import { MonitoringComponentList } from './types';

export const monitoringRowsMock: RowItem<MonitoringComponentList>[] = [
  {
    name: 'Áreas monitoradas',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.1207 15.9788L7.9332 14.883C7.49154 14.658 6.7832 14.683 6.3582 14.9247L4.39987 16.0413C3.0332 16.8247 1.9082 16.1747 1.9082 14.5913V6.48299C1.9082 5.95799 2.2832 5.30799 2.74987 5.04132L6.3582 2.97465C6.7832 2.73299 7.49154 2.71632 7.9332 2.93299L12.3082 5.11632C12.7499 5.34132 13.4582 5.31632 13.8832 5.07465L15.8415 3.95799C17.2082 3.17465 18.3332 3.82465 18.3332 5.40799V10.833" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.13379 3.33594V14.1693" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.1084 5.51562V10.8322" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.1667 17.8334C16.6394 17.8334 17.8333 16.6394 17.8333 15.1667C17.8333 13.6939 16.6394 12.5 15.1667 12.5C13.6939 12.5 12.5 13.6939 12.5 15.1667C12.5 16.6394 13.6939 17.8334 15.1667 17.8334Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.3333 18.3333L17.5 17.5" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
    selected: true,
  },
  {
    name: 'Pesquisar recurso',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.58366 17.4974C13.9559 17.4974 17.5003 13.953 17.5003 9.58073C17.5003 5.20847 13.9559 1.66406 9.58366 1.66406C5.2114 1.66406 1.66699 5.20847 1.66699 9.58073C1.66699 13.953 5.2114 17.4974 9.58366 17.4974Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.3337 18.3307L16.667 16.6641" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
    selected: true,
  },
  {
    name: 'Filtrar empresa',
    icon: `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.49967 1.75H15.4997C16.4163 1.75 17.1663 2.5 17.1663 3.41667V5.25C17.1663 5.91667 16.7497 6.75 16.333 7.16667L12.7497 10.3333C12.2497 10.75 11.9163 11.5833 11.9163 12.25V15.8333C11.9163 16.3333 11.583 17 11.1663 17.25L9.99967 18C8.91634 18.6667 7.41634 17.9167 7.41634 16.5833V12.1667C7.41634 11.5833 7.08301 10.8333 6.74967 10.4167L3.58301 7.08333C3.16634 6.66667 2.83301 5.91667 2.83301 5.41667V3.5C2.83301 2.5 3.58301 1.75 4.49967 1.75Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    `,
    selected: true,
  },
  {
    name: 'Resumo',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66699 10.1641H12.5003" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.66699 13.5H10.317" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.33366 4.9974H11.667C13.3337 4.9974 13.3337 4.16406 13.3337 3.33073C13.3337 1.66406 12.5003 1.66406 11.667 1.66406H8.33366C7.50033 1.66406 6.66699 1.66406 6.66699 3.33073C6.66699 4.9974 7.50033 4.9974 8.33366 4.9974Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.3333 3.35156C16.1083 3.50156 17.5 4.52656 17.5 8.3349V13.3349C17.5 16.6682 16.6667 18.3349 12.5 18.3349H7.5C3.33333 18.3349 2.5 16.6682 2.5 13.3349V8.3349C2.5 4.5349 3.89167 3.50156 6.66667 3.35156" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
    selected: true,
  },
  {
    name: 'Modo de evacuação',
    icon: `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.33301 13.0641L6.24967 13.8641L8.74967 8.14099M4.58301 8.66406L6.24967 6.66406L11.6663 9.86406L13.333 7.86406M7.34417 11.3585L9.99967 13.4641V16.6641" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.0426 6.66406C10.0142 6.66406 9.97869 6.66406 9.95028 6.66406C9.28267 6.64276 8.75 6.08877 8.75 5.41406C8.75 4.72515 9.31108 4.16406 10 4.16406C10.6889 4.16406 11.25 4.72515 11.25 5.41406C11.2429 6.09588 10.7102 6.64276 10.0426 6.66406Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.66703 17.0807C6.66703 17.4974 7.08372 18.3307 8.3337 18.3307H11.667C14.4836 18.3307 15.517 17.7391 15.7586 15.5474C15.817 15.1391 15.8336 14.6807 15.8336 14.1641V5.83073C15.8336 5.31406 15.817 4.85573 15.7586 4.4474C15.517 2.25573 14.4836 1.66406 11.667 1.66406H8.3337C7.08366 1.66406 6.66699 2.4974 6.66699 2.91406" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    `,
    selected: false,
  },
  {
    name: 'Alertas',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0165 2.42188C7.25816 2.42188 5.0165 4.66354 5.0165 7.42187V9.83021C5.0165 10.3385 4.79983 11.1135 4.5415 11.5469L3.58316 13.1385C2.9915 14.1219 3.39983 15.2135 4.48316 15.5802C8.07483 16.7802 11.9498 16.7802 15.5415 15.5802C16.5498 15.2469 16.9915 14.0552 16.4415 13.1385L15.4832 11.5469C15.2332 11.1135 15.0165 10.3385 15.0165 9.83021V7.42187C15.0165 4.67187 12.7665 2.42188 10.0165 2.42188Z" stroke="#808080" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M11.5579 2.66719C11.2996 2.59219 11.0329 2.53385 10.7579 2.50052C9.95794 2.40052 9.19128 2.45885 8.47461 2.66719C8.71628 2.05052 9.31628 1.61719 10.0163 1.61719C10.7163 1.61719 11.3163 2.05052 11.5579 2.66719Z" stroke="#808080" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5166 15.8828C12.5166 17.2578 11.3916 18.3828 10.0166 18.3828C9.33327 18.3828 8.69993 18.0995 8.24993 17.6495C7.79993 17.1995 7.5166 16.5661 7.5166 15.8828" stroke="#808080" strokeWidth="1.3" strokeMiterlimit="10"/>
</svg>
`,
    selected: false,
  },
  {
    name: 'Switch de mapa',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3337 7.50156V12.5016C18.3337 14.5849 17.917 16.0432 16.9837 16.9849L11.667 11.6682L18.1087 5.22656C18.2587 5.8849 18.3337 6.63489 18.3337 7.50156Z" stroke="#808080" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.1087 5.2224L5.22532 18.1057C2.71698 17.5307 1.66699 15.7974 1.66699 12.4974V7.4974C1.66699 3.33073 3.33366 1.66406 7.50033 1.66406H12.5003C15.8003 1.66406 17.5337 2.71407 18.1087 5.2224Z" stroke="#808080" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.983 16.9807C16.0413 17.9141 14.583 18.3307 12.4996 18.3307H7.49962C6.63295 18.3307 5.88294 18.2557 5.22461 18.1057L11.6663 11.6641L16.983 16.9807Z" stroke="#808080" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.19973 6.65156C5.7664 4.2099 9.43307 4.2099 9.99974 6.65156C10.3247 8.0849 9.42473 9.30156 8.63306 10.0516C8.05806 10.6016 7.14974 10.6016 6.56641 10.0516C5.77474 9.30156 4.8664 8.0849 5.19973 6.65156Z" stroke="#808080" strokeWidth="1.3"/>
<path d="M7.57908 7.2526H7.58657" stroke="#808080" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
    selected: true,
  },
  {
    name: 'Infleet',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8423 14.1641L4.13702 14.1641C3.4786 14.1641 3.11281 13.3663 3.4786 12.8079L9.33122 2.9162C9.62386 2.35779 10.3554 2.35779 10.7212 2.9162L16.5738 12.8079C16.8665 13.4461 16.4275 14.1641 15.8423 14.1641Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.1114 17.5L4.86928 17.5C4.29316 17.5 3.9731 16.8162 4.29316 16.3376L9.41421 7.85897C9.67026 7.38034 10.3104 7.38034 10.6305 7.85897L15.7515 16.3376C16.0076 16.8846 15.6235 17.5 15.1114 17.5Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
    selected: true,
  },
];
