import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  align-items: center;
  border-radius: 1.6rem;
  display: grid;
`;

export const AdminCard = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 6 / span 6;
  height: 100%;
  padding: 2.4rem;
  border-radius: 1.6rem;
`;

export const AdminCardHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 2.4rem;
  color: #0e1742;
  font-family: Work Sans;
  font-weight: 500;
`;

export const AdminCardContent = styled.div`
  overflow-y: auto;
  max-height: 65rem;
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2.4rem;
`;

export const PreviewContainer = styled.div`
  grid-column: span 6 / span 6;
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  border-radius: 1.6rem;
  background-color: #f8f8f8;
  height: 100%;
`;

export const PreviewContent = styled.div``;

export const MonitoredDataSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Work Sans;
    & span {
      color: #262424;
      font-weight: 500;
      font-size: 1.6rem;
    }
    & h1 {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 4.8rem;
    }
  }
`;
