import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  gap: 1.6rem;
  margin-bottom: 3.2rem;
  & p {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: Work Sans;
    color: '#0e1742';
  }
  & span {
    color: #b3b3b3;
    margin-left: 0.4rem;
  }
`;
