import React from 'react';
import { DefaultIconProps } from './types';
export const EditIcon: React.FC<DefaultIconProps> = ({ fill, width, height, style, onClick }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M9.16669 1.6665H7.50002C3.33335 1.6665 1.66669 3.33317 1.66669 7.49984V12.4998C1.66669 16.6665 3.33335 18.3332 7.50002 18.3332H12.5C16.6667 18.3332 18.3334 16.6665 18.3334 12.4998V10.8332"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3667 2.51688L6.80002 9.08354C6.55002 9.33354 6.30002 9.82521 6.25002 10.1835L5.89169 12.6919C5.75835 13.6002 6.40002 14.2335 7.30835 14.1085L9.81669 13.7502C10.1667 13.7002 10.6584 13.4502 10.9167 13.2002L17.4834 6.63354C18.6167 5.50021 19.15 4.18354 17.4834 2.51688C15.8167 0.850211 14.5 1.38354 13.3667 2.51688Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.425 3.4585C12.9833 5.45016 14.5417 7.0085 16.5417 7.57516"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};