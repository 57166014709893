import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useEffect,
} from 'react';

import { CardItem, IndicatorsRowNames, RowItem } from '../types';
import { AdminModalForm, adminModalFormBlankValues } from '../../../types';
import { indicatorsCardMock } from '../mock';

type Props = {
  children: ReactNode;
};

type IndicatorsAdminContextProps = {
  modalForm: AdminModalForm;
  modalTitle: string;
  changeModalTitle: (title: string) => void;
  cards: CardItem<IndicatorsRowNames>[];
  setCards: Dispatch<SetStateAction<CardItem<IndicatorsRowNames>[]>>;
  modal: boolean;
  openModal: (name: string, cardName: string) => void;
  closeModal: () => void;
  setModalForm: Dispatch<SetStateAction<AdminModalForm>>;
  onModalConfirm: (cardName: string) => void;
  activeRow: RowItem<IndicatorsRowNames> | undefined;
  setActiveRow: Dispatch<SetStateAction<RowItem<IndicatorsRowNames> | undefined>>;
  toggleCardActive: (name: string) => void;
  toggleRowSelect: (name: string, cardTitle: string) => void;
  cardActive: string;
  setCardActive: Dispatch<SetStateAction<string>>;
  isChanged: boolean;
  resetRows: () => void;
  initialCards: CardItem<IndicatorsRowNames>[];
};

const IndicatorsAdminContext = createContext<IndicatorsAdminContextProps>({} as IndicatorsAdminContextProps);

export function IndicatorsAdminContextProvider(props: Props) {
  const [cards, setCards] = useState<CardItem<IndicatorsRowNames>[]>([]);
  const [initialCards, setInitialCards] = useState<CardItem<IndicatorsRowNames>[]>([]);
  const [modal, setModal] = useState(false);
  const [modalForm, setModalForm] = useState<AdminModalForm>(adminModalFormBlankValues);
  const [modalTitle, setModalTitle] = useState('');
  const [activeRow, setActiveRow] = useState<RowItem<IndicatorsRowNames>>();
  const [cardActive, setCardActive] = useState('');

  useEffect(() => {
    setCards(indicatorsCardMock);
    setInitialCards(JSON.parse(JSON.stringify(indicatorsCardMock)));
  }, []);

  const { children } = props;

  const closeModal = () => {
    setModalForm(adminModalFormBlankValues);
    setModal(false);
    setCardActive('');
    setActiveRow(undefined);
  };

  const openModal = (name: string, cardName: string) => {
    const cardIndex = cards.findIndex((item) => item.title === cardName);
    if (cardIndex < 0) return;
    const card = cards[cardIndex];
    const row = card.rows.find((v) => v.name === name);
    if (!row) {
      return;
    } else {
      setActiveRow(row);
      setCardActive(card.title);
      changeModalTitle(name);
      setModalForm({
        icon: row.icon,
        info: row.info || '',
      });
    }
    setModal(true);
  };

  const changeModalTitle = (value: string) => {
    setModalTitle(value);
  };

  const onModalConfirm = (cardName: string) => {
    const localCards = [...cards];
    if (!activeRow) return;
    const cardIndex = localCards.findIndex((v) => v.title === cardName);
    const card = localCards[cardIndex];
    if (!card) return;
    const rowIndex = card.rows.findIndex((v) => v.name === activeRow.name);
    if (rowIndex >= 0) {
      card.rows.splice(rowIndex, 1, { ...activeRow, ...modalForm });
      localCards.splice(cardIndex, 1, card);
    }
    setCards(localCards);
    closeModal();
  };

  const toggleCardActive = (cardName: string) => {
    const cardIndex = cards.findIndex((item) => item.title === cardName);
    if (cardIndex < 0) return;
    const localCards = [...cards];
    const card = localCards[cardIndex];
    localCards.splice(cardIndex, 1, { ...card, active: !card.active });
    setCards(localCards);
  };

  const toggleRowSelect = (rowName: string, cardTitle: string) => {
    const cardIndex = cards.findIndex((item) => item.title === cardTitle);
    if (cardIndex < 0) return;
    const localCards = [...cards];
    const card = localCards[cardIndex];
    const rowIndex = card.rows.findIndex((row) => row.name === rowName);
    if (rowIndex < 0) return;
    const row = card.rows[rowIndex];
    card.rows.splice(rowIndex, 1, { ...row, selected: !row.selected });
    localCards.splice(cardIndex, 1, card);
    setCards(localCards);
  };

  const isChanged = useMemo(() => {
    return (
      JSON.stringify(
        cards.map((v) => ({
          active: v.active,
          rows: v.rows,
        }))
      ) !==
      JSON.stringify(
        initialCards.map((v) => ({
          active: v.active,
          rows: v.rows,
        }))
      )
    );
  }, [cards]);

  const resetRows = () => {
    setCards(initialCards);
  };

  const value: IndicatorsAdminContextProps = {
    cards,
    closeModal,
    modal,
    modalForm,
    modalTitle,
    openModal,
    setCards,
    changeModalTitle,
    setModalForm,
    activeRow,
    setActiveRow,
    onModalConfirm,
    toggleCardActive,
    toggleRowSelect,
    cardActive,
    setCardActive,
    isChanged,
    resetRows,
    initialCards,
  };

  return <IndicatorsAdminContext.Provider value={value}>{children}</IndicatorsAdminContext.Provider>;
}

export function useIndicatorsAdminContext() {
  const context = useContext(IndicatorsAdminContext);

  if (typeof context === 'undefined') {
    throw new Error('IndicatorsAdminContext must be used within an useFaqContext');
  }

  return context;
}
