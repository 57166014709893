import React, { useMemo } from 'react';
import * as S from './styles';
import { MonitorIcon } from '../../../../Icons/Monitor';
import { AlertIconVuesax } from '../../../../Icons/Alert-Vuesax';
import { PreviewCardHome } from './PreviewCardHome';
import { useHomeAdminContext } from './context/HomeContext';

export const AdminHomePreviewCard: React.FC = () => {
  const { activeRow } = useHomeAdminContext();
  const [title, subtitle] = useMemo(() => {
    if (!activeRow) {
      return ['', ''];
    }
    const newName = activeRow?.name;
    const [tit, sub] = newName.split('(').map((v) => v.replaceAll('(', '').replaceAll(')', ''));
    return [tit, sub];
  }, [activeRow]);
  return (
    <S.PreviewContainer>
      <S.AdminCardHeader>
        <MonitorIcon width={20} height={20} fill="#666666" />
        Preview
        <AlertIconVuesax />
      </S.AdminCardHeader>
      {activeRow ? (
        <PreviewCardHome
          component={activeRow.name}
          icon={activeRow?.icon || ''}
          info={activeRow?.info}
          subtitle={`(${subtitle})`}
          title={title || ''}
        />
      ) : (
        <></>
      )}
    </S.PreviewContainer>
  );
};
