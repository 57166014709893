import styled, { keyframes } from 'styled-components';

type NotificationBoardStyleProps = {
    new?: boolean;
    panic?: boolean;
}

function blinkingEffect(color:string) {
    return keyframes` {
        0%{background-color: ${color};}
        50%{background-color: white;}
        100%{background-color: ${color};}
    }`;
}

export const Container = styled.div`
    position: absolute;
    bottom: 28px;
    right: 10px;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    min-height: 100px;
    max-height: 40%;
    padding: 10px;
    gap: 10px;
    //background: #fff;
    background: #f3f3f3;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        //background: #ffffff;
        background: #f3f3f3;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 10px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid;
`;

export const Title = styled.div`
    font-weight: bold;
    font-size: medium;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const Notification = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid;
    border-radius: 5px;
    background: ${(props: NotificationBoardStyleProps) => props.panic ? '#ffaf7a' : '#fc7174'};
    animation: ${blinkingEffect('#fc7174')} 0.8s linear infinite;
`;

export const NotificationContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const NotificationIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    height: 80px;
    width: 80px;
    background: #e3242b;
    border-radius: 45px;
`;

export const NotificationContent = styled.div``;

export const NotificationDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    margin: 0px 5px 0px 5px;
`;

export const NotificationDetailsContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2px;
`;

export const NotificationTitle = styled.div`
    text-align: center;
    font-weight: bold;
    animation: ${(props: NotificationBoardStyleProps) => props.new ? '${blinkingEffect} 1s linear infinite' : ''};
    //color: ${(props: NotificationBoardStyleProps) => props.new ? '#ff0000': '#000'};
`;

export const NotificationInfo = styled.div`
    font-weight: bold;
    animation: ${(props: NotificationBoardStyleProps) => props.new ? '${blinkingEffect} 1s linear infinite' : ''};
    //color: ${(props: NotificationBoardStyleProps) => props.new ? '#ff0000': '#000'};
`;

export const NotificationLabel = styled.div`
    //animation: ${(props: NotificationBoardStyleProps) => props.new ? '${blinkingEffect} 1s linear infinite' : ''};
    //color: ${(props: NotificationBoardStyleProps) => props.new ? '#ff0000': '#000'};
`;