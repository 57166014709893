import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from 'react';
import { AdminComponentList } from '../types';
import { useUrlState } from '../../filters/hooks/useUrlState';
import { IAuthProfilePayload } from '../../../services/api/auth';
import { useAdmin } from '../hooks/useAdmin';

type Props = {
  children: ReactNode;
}

interface ComponentItem {
  name: string;
  active: boolean;
}

type AdminPagesContextProps = {
  page: AdminComponentList | '' | undefined;
  setPage: (page: AdminComponentList | '') => void;
  components: string[];
  updateComponents: (components: ComponentItem[]) => void;
}

const AdminPagesContext = createContext<AdminPagesContextProps>({} as AdminPagesContextProps);

export function AdminPagesContextProvider({ children }: Props) {
  const { defaultValues, onChangeParams } = useUrlState<'page', AdminComponentList>();
  const [page, setPage] = useState<AdminComponentList | '' | undefined>();
  const [components, setComponents] = useState<string[]>([]);
  const { changeComponentAccess } = useAdmin();

  useEffect(() => {
    const authData = localStorage.getItem('authData');
    const authDataParsed = authData ? JSON.parse(authData) : null;
    const authProfile: IAuthProfilePayload = authDataParsed?.profileData;

    if (authProfile)
      setComponents(authProfile.components);
  }, []);

  const changePage = (page: AdminComponentList | '') => {
    onChangeParams('page', page);
    setPage(page);
  }

  const updateComponents = (componentItems: ComponentItem[]) => {
    const newComponents = [...components];

    for (const item of componentItems) {
      const componentIndex = newComponents.findIndex((comp) => comp === item.name);
      const exists = componentIndex !== -1;

      if (item.active && !exists)
        newComponents.push(item.name);
      else if (!item.active && exists)
        newComponents.splice(componentIndex, 1);
    }

    setComponents(newComponents);
    changeComponentAccess(newComponents, []);
  }

  useEffect(() => {
    if (defaultValues?.page)
      setPage(defaultValues.page);
  }, [defaultValues]);

  const value: AdminPagesContextProps = {
    page,
    setPage: changePage,
    components,
    updateComponents,
  }

  return <AdminPagesContext.Provider value={value}>{children}</AdminPagesContext.Provider>;
}

export function useAdminPagesContext() {
  const context = useContext(AdminPagesContext);

  if (typeof context === 'undefined') {
    throw new Error('AdminPagesContext must be used within an useFaqContext');
  }

  return context;
}
