import React, { ChangeEvent } from 'react';
import { useDebounce } from '../../hooks';
import { InputProps } from '../types';

import * as S from './styles';

export const SearchInput: React.FC<InputProps> = ({ id, className, onChanging, ...rest }) => {
  const { autoFocus, placeholder, disabled, searchValue, setSearchValue } = { ...rest };
  const debouncedChange = useDebounce(onChanging, 750);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    debouncedChange(value);
  };

  return (
    <S.Input
      id={id}
      type="search"
      className={className}
      value={searchValue}
      onChange={handleOnChange}
      autoFocus={autoFocus}
      placeholder={placeholder}
      disabled={disabled}
      {...rest}
    />
  );
};