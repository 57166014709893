import React, { useEffect, useRef, useState } from "react";

import { useEvacuationContext } from "../../context/EvacuationContext";
import { useEmergencyNotifications } from "../../hooks/useEmergencyNotifications";
import { useAuth } from "../../../../hooks";
import EmergencyAudio from '../../../../assets/Alerta de incidente - Trackfy - Pixabay.mp3';
import { NotificationRow } from "./NotificationRow";

import * as S from './styles';

interface Props {
  style?: React.CSSProperties;
}

export const NotificationBoard: React.FC<Props> = ({ style }) => {
    const { getClientId } = useAuth();
    const { data } = useEvacuationContext();
    const { notifications, notificationsUpdates } = useEmergencyNotifications();
    const [loopsCount, setLoopsCount] = useState(0);
    const audioRef = useRef<HTMLAudioElement>(null);

    const handleTimeUpdate = () => {
      const audioElement = audioRef.current;
      
      if (!audioElement) return;

      if (audioElement.currentTime >= audioElement.duration - 0.3)
        setLoopsCount((prev) => prev + 1);
      
    };

    useEffect(() => {
      if (loopsCount > 3) {
        const audioElement = audioRef.current;
        if (!audioElement) return;

        audioElement.loop = false;
        audioElement.pause();
        audioElement.currentTime = 0;
        setLoopsCount(0);
      }
    }, [loopsCount]);

    useEffect(() => {
        if (notifications.loading) return;

      if (notifications.data.length && audioRef.current)
          audioRef.current.play();
    }, [notifications.loading]);
    
    useEffect(() => {
      if (notifications.data.length && audioRef.current)
          audioRef.current.play();
    }, [notificationsUpdates.current]);

    const audioNotification = <audio ref={audioRef}
                                     style={{ display: 'none', visibility: 'hidden', position: 'absolute' }}
                                     autoPlay
                                     src={EmergencyAudio}
                                     loop={loopsCount < 3}
                                     controls
                                     onTimeUpdate={() => handleTimeUpdate()} >
                                <track kind="captions" />
                              </audio>
    
    if(data.mode !== "notevacuating")
        return null;
    
    return (
        <S.Container style={style}>
            {(getClientId() == 3) && audioNotification}
            <S.TitleContainer>
                <S.Title>Alertas</S.Title>
            </S.TitleContainer>
            <S.ContentContainer>
                {notifications.data
                    .filter((note: any) => note.event == "Botão de pânico disparado" || 
                                        note.event == "Botão de pânico (clique único)")
                    .map((notification:any, index:number)=> {
                        return (
                            <NotificationRow
                            notification={notification}
                            index={index}
                            key={index + notification.timestamp + notification.area + notification.tag}
                            />
                        );
                })}
            </S.ContentContainer>
        </S.Container>);
}