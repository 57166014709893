import styled from 'styled-components';

interface ContainerProps {
  columns: number;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, minmax(0, 1fr))`};
`;

interface ContentProps {
  size: number;
}

export const Content = styled.div<ContentProps>`
  grid-column: ${({ size }) => `span ${size} / span ${size}`};
`;