import React, { useEffect, useMemo, useState } from 'react';

import { ContentContainer } from '../../components/PageStructure/styles';
import { Page } from '../../components/PageStructure/Page';
import { AdminCardRow } from './components/CardRow';
import { TrackfyIcon } from '../../Icons/Trackfy';
import { MaximizeIcon } from '../../Icons/Maximize';
import { AdminModalEdit } from './components/ModalEdit';
import { useAdminContext } from './context/AdminContext';
import { AlertIconVuesax } from '../../Icons/Alert-Vuesax';
import { ToastConfirm } from './components/ToastConfirm';
import { useAdmin } from './hooks/useAdmin';
import { adminIcons } from './mock';
import { useAdminPagesContext } from './context/AdminPagesContext';
import { AdminPageRender } from './components/AdminPageRender';
import { AdminComponentList } from './types';
import { useAuth } from '../../hooks';

import * as S from './styles';

export const AdminContainer: React.FC = () => {
  const {
    closeModal,
    modal,
    modalForm,
    setModalForm,
    activeRow,
    onModalConfirm,
    cards,
    openModal,
    setCards,
    isChanged,
    resetRows,
    saveRows,
  } = useAdminContext();
  const { changePageAccess, getRowProperty } = useAdmin();
  const { page, setPage } = useAdminPagesContext();
  const { getMenu } = useAuth();
  const menu = useMemo(() => getMenu(), [getMenu]);

  useEffect(() => {
    if (isChanged) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [isChanged]);

  const [showToast, setShowToast] = useState(false);

  const onClickSelectRow = (name: string) => {
    const localCards = [...cards];
    const row = localCards.findIndex((item) => item.name === name);
    if (row >= 0) {
      localCards.splice(row, 1, { ...localCards[row], selected: !localCards[row].selected });
    }
    setCards(localCards);
  };

  if (page)
    return <AdminPageRender />;

  return (
    <Page
      title="Admin"
      components={
        <ContentContainer value="" style={{ gap: '2.4rem' }}>
          {!page && (
            <ToastConfirm
              onConfirm={() => {
                const icons = getRowProperty(cards, 'icon');
                const iconsChanged = menu.filter((item) => icons.includes(item.icon)).map((item) => item.key);
                changePageAccess(getRowProperty(cards, 'name'), iconsChanged);
                saveRows();
              }}
              show={showToast}
              onCancel={() => {
                setShowToast(false);
                resetRows();
              }}
            />
          )}

          <S.ContentTitle>
            <TrackfyIcon />
            <span>Páginas Trackfy</span>
            <AlertIconVuesax />
          </S.ContentTitle>

          <AdminModalEdit
            hasTag={false}
            tags={activeRow?.tags || []}
            onChangeTag={(tags) => {
              const localRows = [...cards];
              const findRow = localRows.findIndex((v) => v.name === activeRow?.name);
              
              if (findRow >= 0) 
                localRows.splice(findRow, 1, { ...localRows[findRow], tags });

              setCards(localRows);
            }}
            closeModal={closeModal}
            modal={modal}
            modalForm={modalForm}
            modalTitle={activeRow?.name || ''}
            onClickConfirm={onModalConfirm}
            setModalForm={setModalForm}
          />
          
          <S.ContentItems>
            {cards.map((row, index) => (
              <AdminCardRow
                disabled={row.disabled || false}
                icon={adminIcons[row.icon] || ''}
                isActive
                key={`${row.name}-${index}`}
                name={row.name}
                onClickSelect={() => onClickSelectRow(row.name)}
                selected={row.selected}
                onClickEdit={() => openModal(row.name)}
                customButtons={
                  <span
                    onClick={() => setPage(row.name as AdminComponentList)}
                    style={{ height: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <MaximizeIcon width={20} height={20} />
                  </span>
                }
              />
            ))}
          </S.ContentItems>
        </ContentContainer>
      }
    ></Page>
  );
};
