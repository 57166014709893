import React, { ReactNode, useMemo } from 'react';

import { AlertIconVuesax } from '../../../../Icons/Alert-Vuesax';
import { EditIcon } from '../../../../Icons/Edit-Vuesax';
import { Switch } from '../../../../components/Switch';

import * as S from './styles';

interface Props {
  isActive: boolean;
  onClickEdit?: () => void;
  onClickSelect: () => void;
  selected: boolean;
  disabled: boolean;
  icon: string;
  name: string;
  onClickRow?: () => void;
  customButtons?: ReactNode;
  style?: React.CSSProperties;
}

export const AdminCardRow: React.FC<Props> = ({
  isActive,
  onClickEdit,
  onClickSelect,
  selected,
  disabled,
  icon,
  name,
  onClickRow,
  customButtons,
  style,
}) => {
  const [title, subtitle] = useMemo(() => {
    const newName = name;
    const [tit, sub] = newName.split('(').map((v) => v.replaceAll('(', '').replaceAll(')', ''));
    return [tit, sub];
  }, [name]);
  return (
    <S.AdminCardRow
      style={style}
      isActive={isActive}
      disabled={disabled}
      selected={selected}
      onClick={(e) => {
        if (!disabled) {
          if (onClickRow) {
            onClickRow();
          }
        }
      }}
    >
      <S.RowTitle>
        <div dangerouslySetInnerHTML={{ __html: icon }} style={{ display: 'flex', alignItems: 'center' }} />
        <p>
          {title}
          {subtitle && <span>({subtitle})</span>}
        </p>
        <AlertIconVuesax width={16.67} height={16.67} />
      </S.RowTitle>
      <S.RowActions>
        {customButtons}
        {onClickEdit && (
          <EditIcon
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            onClick={disabled ? undefined : onClickEdit}
            fill="#666666"
          />
        )}
        <Switch
          disabled={disabled}
          onClick={(e) => {
            if (disabled) {
              return;
            } else {
              onClickSelect();
            }
          }}
          selected={selected}
        />
      </S.RowActions>
    </S.AdminCardRow>
  );
};
