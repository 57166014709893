import React, { useMemo } from 'react';
import { BulletinInfos } from '../../../types';
import { ExampleBarChart } from '../Chart';

interface Props {
  data: BulletinInfos['data'];
}

const convertTimeStringToMinutes = (timeString: string) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return Number(`${hours}.${minutes}`);
}

const getDisplacement = (time1: string, time2: string) => {
  const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
  const [hours2, minutes2, seconds2] = time2.split(':').map(Number);

  const totalMinutes1 = hours1 * 60 + minutes1 + seconds1 / 60;
  const totalMinutes2 = hours2 * 60 + minutes2 + seconds2 / 60;

  const diffMinutes = totalMinutes2 - totalMinutes1;

  const hours = Math.floor(Math.abs(diffMinutes) / 60);
  const minutes = Math.floor(Math.abs(diffMinutes) % 60);

  if (hours === 0) {
    return `${minutes} minutos`;
  }

  return `${hours} ${hours > 1 ? 'horas' : 'hora'} e ${minutes} ${minutes > 1 ? 'minutos' : 'minuto'}`;
}

export const DisplacementChart: React.FC<Props> = ({ data }) => {
    const arrCurrentWeek = useMemo(() => {
        return [
          data.entranceMediaPortariaCurrentWeek,
          data.entranceMediaRefeitoriosCurrentWeek,
          data.entranceMediaGVsCurrentWeek,
          data.exitMediaGVsCurrentWeek,
          data.exitMediaPortariaCurrentWeek,
        ];
    }, [data]);
    
    const arrFirstWeek = useMemo(() => {
        return [
          data.entranceMediaPortariaFirstWeek,
          data.entranceMediaRefeitoriosFirstWeek,
          data.entranceMediaGVsFirstWeek,
          data.exitMediaGVsFirstWeek,
          data.exitMediaPortariaFirstWeek,
        ];
    }, [data]);

  const currentAreaHours = useMemo(() => {
    return {
        portariaEntrada: convertTimeStringToMinutes(arrCurrentWeek[0]),
        refeitorioEntrada: convertTimeStringToMinutes(arrCurrentWeek[1]),
        gvsEntrada: convertTimeStringToMinutes(arrCurrentWeek[2]),
        gvsSaida: convertTimeStringToMinutes(arrCurrentWeek[3]),
        portariaSaida: convertTimeStringToMinutes(arrCurrentWeek[4]),
        portariaToGV: getDisplacement(arrCurrentWeek[0], arrCurrentWeek[2]),
        GvToGvSaida: getDisplacement(arrCurrentWeek[2], arrCurrentWeek[3]),
        GvSaidaToPortaria: getDisplacement(arrCurrentWeek[3], arrCurrentWeek[4]),
    };
  }, [data]);

  const firstWeekAreaHours = useMemo(() => {
    return {
        portariaEntrada: convertTimeStringToMinutes(arrFirstWeek[0]),
        refeitorioEntrada: convertTimeStringToMinutes(arrFirstWeek[1]),
        gvsEntrada: convertTimeStringToMinutes(arrFirstWeek[2]),
        gvsSaida: convertTimeStringToMinutes(arrFirstWeek[3]),
        portariaSaida: convertTimeStringToMinutes(arrFirstWeek[4]),
        portariaToGV: getDisplacement(arrFirstWeek[0], arrFirstWeek[2]),
        GvToGvSaida: getDisplacement(arrFirstWeek[2], arrFirstWeek[3]),
        GvSaidaToPortaria: getDisplacement(arrFirstWeek[3], arrFirstWeek[4]),
    };
  }, [data]);

  return (
    <ExampleBarChart
      barSize={40}
      height={56}
      style={{ marginLeft: '-40px' }}
      tooltip={false}
      hideXAxis
      boldLabel
      data={[
        {
          hours: currentAreaHours.portariaEntrada,
          name: 'Portaria entrada',
          endLabel: arrCurrentWeek[0],
          color: firstWeekAreaHours.portariaEntrada < currentAreaHours.portariaEntrada ? '#E74011' : '#13A538',
        },
        {
          hours: currentAreaHours.refeitorioEntrada,
          name: 'Refeitório Entrada',
          endLabel: arrCurrentWeek[1],
          color: firstWeekAreaHours.refeitorioEntrada < currentAreaHours.refeitorioEntrada ? '#E74011' : '#13A538',
        },
        {
          hours: currentAreaHours.gvsEntrada,
          name: 'GVS Entrada',
          endLabel: arrCurrentWeek[2],
          color: firstWeekAreaHours.gvsEntrada < currentAreaHours.gvsEntrada ? '#E74011' : '#13A538',
        },
        {
          hours: currentAreaHours.gvsSaida,
          name: 'GVS Saída',
          endLabel: arrCurrentWeek[3],
          color: firstWeekAreaHours.gvsSaida < currentAreaHours.gvsSaida ? '#E74011' : '#13A538',
        },
        {
          hours: currentAreaHours.portariaSaida,
          name: 'Portaria saída',
          endLabel: arrCurrentWeek[4],
          color: firstWeekAreaHours.portariaSaida < currentAreaHours.portariaSaida ? '#E74011' : '#13A538',
        },
      ]}
      width={400}
      barRightPadding={50}
    />
  );
}