import { RowItem } from '../Config/types';
import { RecordsAdminComponentList } from './types';

export const recordsAdminRowsMock: RowItem<RecordsAdminComponentList>[] = [
  {
    name: 'Log de incidentes',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.33366 4.9974H11.667C13.3337 4.9974 13.3337 4.16406 13.3337 3.33073C13.3337 1.66406 12.5003 1.66406 11.667 1.66406H8.33366C7.50033 1.66406 6.66699 1.66406 6.66699 3.33073C6.66699 4.9974 7.50033 4.9974 8.33366 4.9974Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.3333 3.35156C16.1083 3.50156 17.5 4.52656 17.5 8.3349V13.3349C17.5 16.6682 16.6667 18.3349 12.5 18.3349H7.5C3.33333 18.3349 2.5 16.6682 2.5 13.3349V8.3349C2.5 4.5349 3.89167 3.50156 6.66667 3.35156" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 10.1719L9.99962 11.6685" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 15.3417H7.47468C6.02864 15.3417 5.42439 14.3083 6.12448 13.0456L7.42467 10.7036L8.64984 8.50327C9.39161 7.16558 10.6085 7.16558 11.3502 8.50327L12.5754 10.7077L13.8756 13.0497C14.5757 14.3124 13.9673 15.3459 12.5254 15.3459H10V15.3417Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 13.3359H10.0037" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
    selected: true,
  },
  {
    name: 'Diário de bordo',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.66699 16.2474V5.83073C1.66699 2.4974 2.40229 1.66406 5.34346 1.66406H10.4905C13.4317 1.66406 14.167 2.4974 14.167 5.83073V14.1641C14.167 14.2807 14.167 14.8807 14.1596 14.9974" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.76258 15H14.167V16.6667C14.167 17.5857 13.0126 18.3333 11.5935 18.3333H4.24052C2.8214 18.3333 1.66699 17.5857 1.66699 16.6667V16.3571C1.66699 15.6095 2.60817 15 3.76258 15Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5 5H11.6667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5 7.91406H9.16667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5 10.8359H7.5" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.0851 1.66406C16.3956 1.66406 15.833 1.97853 15.833 3.33073V14.944C15.833 15.3196 15.9134 15.8872 16.0107 16.2127L16.3575 17.3479C16.7552 18.6583 17.4108 18.6583 17.8085 17.3479L18.1553 16.2127C18.2526 15.8872 18.333 15.3196 18.333 14.944V3.33073C18.333 1.97853 17.7704 1.66406 17.0851 1.66406Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M18.333 5.83594H15.833" stroke="#666666" strokeWidth="1.5" strokeLinecap="round"/>
</svg>
`,
    selected: true,
  },
  {
    name: 'Relatórios',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 9.16406V14.1641L9.16667 12.4974" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.49967 14.1667L5.83301 12.5" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.3337 8.33073V12.4974C18.3337 16.6641 16.667 18.3307 12.5003 18.3307H7.50033C3.33366 18.3307 1.66699 16.6641 1.66699 12.4974V7.4974C1.66699 3.33073 3.33366 1.66406 7.50033 1.66406H11.667" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.3337 8.33073H15.0003C12.5003 8.33073 11.667 7.4974 11.667 4.9974V1.66406L18.3337 8.33073Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
`,
    selected: true,
  },
  {
    name: 'Notificações',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0165 2.42188C7.25816 2.42188 5.0165 4.66354 5.0165 7.42187V9.83021C5.0165 10.3385 4.79983 11.1135 4.5415 11.5469L3.58316 13.1385C2.9915 14.1219 3.39983 15.2135 4.48316 15.5802C8.07483 16.7802 11.9498 16.7802 15.5415 15.5802C16.5498 15.2469 16.9915 14.0552 16.4415 13.1385L15.4832 11.5469C15.2332 11.1135 15.0165 10.3385 15.0165 9.83021V7.42187C15.0165 4.67187 12.7665 2.42188 10.0165 2.42188Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M11.5579 2.66719C11.2996 2.59219 11.0329 2.53385 10.7579 2.50052C9.95794 2.40052 9.19128 2.45885 8.47461 2.66719C8.71628 2.05052 9.31628 1.61719 10.0163 1.61719C10.7163 1.61719 11.3163 2.05052 11.5579 2.66719Z" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5166 15.8828C12.5166 17.2578 11.3916 18.3828 10.0166 18.3828C9.33327 18.3828 8.69993 18.0995 8.24993 17.6495C7.79993 17.1995 7.5166 16.5661 7.5166 15.8828" stroke="#808080" strokeWidth="1.5" strokeMiterlimit="10"/>
</svg>
`,
    selected: true,
  },
];
