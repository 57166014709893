import React from 'react';
import { DefaultIconProps } from './types';

export const ConfigIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
        stroke={fill || 'white'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66699 17.1726V14.8259C2.66699 13.4393 3.80033 12.2926 5.20033 12.2926C7.61366 12.2926 8.60033 10.5859 7.38699 8.4926C6.69366 7.2926 7.10699 5.7326 8.32033 5.03926L10.627 3.71926C11.6803 3.0926 13.0403 3.46593 13.667 4.51926L13.8137 4.7726C15.0137 6.86593 16.987 6.86593 18.2003 4.7726L18.347 4.51926C18.9737 3.46593 20.3337 3.0926 21.387 3.71926L23.6937 5.03926C24.907 5.7326 25.3203 7.2926 24.627 8.4926C23.4137 10.5859 24.4003 12.2926 26.8137 12.2926C28.2003 12.2926 29.347 13.4259 29.347 14.8259V17.1726C29.347 18.5593 28.2137 19.7059 26.8137 19.7059C24.4003 19.7059 23.4137 21.4126 24.627 23.5059C25.3203 24.7193 24.907 26.2659 23.6937 26.9593L21.387 28.2793C20.3337 28.9059 18.9737 28.5326 18.347 27.4793L18.2003 27.2259C17.0003 25.1326 15.027 25.1326 13.8137 27.2259L13.667 27.4793C13.0403 28.5326 11.6803 28.9059 10.627 28.2793L8.32033 26.9593C7.10699 26.2659 6.69366 24.7059 7.38699 23.5059C8.60033 21.4126 7.61366 19.7059 5.20033 19.7059C3.80033 19.7059 2.66699 18.5593 2.66699 17.1726Z"
        stroke={fill || 'white'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
