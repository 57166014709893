import styled from 'styled-components';

type PropsTrstyled = {
  height: string;
  backgroundColor?: string;
};

interface ActionColumnButtonsProps {
  fullHeight?: boolean;
  noMargin?: boolean;
}

export const LoadingEmptyContainer = styled.table`
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    width: 8px;
    border: 1px solid #f3f3f3;
    border-radius: 2px;
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: black;
  font-style: normal;
  text-align: center;
`;

export const Thead = styled.thead`
  height: 48px;
  background: #273dab;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 1px 62px;
`;

export const Tbody = styled.tbody`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  margin-left: 40%;
`;

export const HeaderCell = styled.td`
  text-align: -webkit-center;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & span {
    font: normal 500 1.5rem/1.5 Work Sans, sans-serif;
    color: #4f5882;
  }
`;

export const Trstyled = styled.tr<PropsTrstyled>`
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : '#f4f5ff')};
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  height: ${(props) => props.height};
  width: 100%;
`;

export const TrstyledOdd = styled.tr<PropsTrstyled>`
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : '#FFFFFF')};
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  height: ${(props) => props.height};
`;

export const ActionColumnButtons = styled.div<ActionColumnButtonsProps>`
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin: ${({ noMargin }) => (noMargin ? 'none' : '0.8rem 0')};
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  align-items: center;
`;

export const ActionColumnButton = styled.div`
  padding: 0.8rem;
  border-radius: 0.8rem;
  border: 2px solid #2f4cdd;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;