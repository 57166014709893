import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  LabelList,
  BarChart,
  Bar,
} from 'recharts';

import { StackedBarChart } from '../StackedBarChart';

import './styles.css';
import * as S from './styles';

interface LineChartProps {
  data: {
    week: string;
    hours: number;
    averageTime?: number;
  }[];
  className?: string;
  style?: React.CSSProperties;
  unit?: string;
  tickFormatter?: (value: any, index: number) => string;
  showLineAverageValue?: boolean;
  width?: number;
  height?: number;
}

interface BarChartProps {
  data: {
    name: string;
    hours: number;
    endLabel?: string;
    color?: string;
  }[];

  width?: number;
  className?: string;
  barRightPadding?: number;
  style?: React.CSSProperties;
  tooltip?: boolean;
  hideXAxis?: boolean;
  barSize?: number;
  height?: number;
  boldLabel?: boolean;
}

interface TimelineChartProps {
  data: {
    count: string;
    time: string;
  }[];
}

interface StackedBarProps {
  style?: React.CSSProperties;
}

const lineTicks = (data: any[]): number[] => {
  if(data.length > 0 && data[0].averageTime)
    return [...data.map((v) => v.hours), data[0].averageTime || 0];

  let ticks: number[] = [];
  const hours: number[] = data.map((item) => item.hours);
  const hoursSorted: number[] = hours.sort((a: number, b: number) => a - b);

  const firstValue = hoursSorted[0] > 0 ? hoursSorted[0]  : 0.00;
  const lastValue = hoursSorted[data.length - 1] ? hoursSorted[data.length - 1] : 0.00;

  let gap = 10;

  if (lastValue - firstValue <= 15) gap = 2.5;

  if (lastValue - firstValue > 15 && lastValue - firstValue <= 40) gap = 5;

  if (lastValue - firstValue > 200) gap = 15;

  ticks.push(Number.parseFloat(firstValue.toFixed(2)));
  let currentValue = firstValue + gap;

  while (currentValue <= lastValue) {
    ticks.push(Number.parseFloat(currentValue.toFixed(2)));
    currentValue = currentValue + gap;
  }

  if (lastValue - currentValue > gap) ticks.push(Number.parseFloat(lastValue.toFixed(2)));
  else ticks.push(Number.parseFloat(currentValue.toFixed(2)));

  return ticks;
};

export const ExampleLineChart: React.FC<LineChartProps> = ({data,
                                                            className,
                                                            style,
                                                            unit,
                                                            tickFormatter,
                                                            showLineAverageValue,
                                                            width,
                                                            height,
                                                            }) => {
  return (
    <div style={style}>
      <LineChart
        defaultShowTooltip
        className={className}
        width={width || 700}
        height={height || 620}
        //height={data.length * 5}
        data={data}
        margin={{
          top: 5,
          right: 40,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="week" allowDataOverflow minTickGap={15} />
        <YAxis
          interval={0}
          minTickGap={10}
          unit={unit ?? 'hrs'}
          //allowDataOverflow
          dataKey={'hours'}
          //tickCount={data.length}
          ticks={lineTicks(data)}
          tickFormatter={tickFormatter}
          //ticks={data.map((v) => v.hours)}
          //domain={[(dataMin: number) => (dataMin - 5), (dataMax: number) => (dataMax + 5)]}
          tickCount={showLineAverageValue ? data.length + 1 : 5}
          domain={['dataMin', 'dataMax']}
        />
        <Line type="monotone" dataKey="hours" name="Horas" stroke="#000000" activeDot={{ r: 8 }} />
      </LineChart>
    </div>
  );
};

export const ExampleBarChart: React.FC<BarChartProps> = ({ data, 
  className, 
  style,
  width,
  barRightPadding,
  tooltip = true,
  hideXAxis = false 
 }) => {
  return (
    <div style={style}>
      <BarChart
        className={className}
        width={width || 700}
        height={data.length * 50 + 30.1}
        data={data}
        layout="vertical"
        barSize={30}
        margin={{ left: 40 }}
      >
        <CartesianGrid horizontal={false} vertical={false} />
        <YAxis dataKey="name" type="category" tick={{ fontSize: 10, fontWeight: 'bold' }} />
        <XAxis type="number" 
               unit="hrs" 
               padding={{ right: barRightPadding || 100 }}
               tick={{ fontSize: 10 }}
               hide={hideXAxis} />
        {tooltip && <Tooltip />}
        <Bar dataKey="hours" fill="#008AE5" name="Horas" label="Horas" spacing={0}>
          <LabelList
            dataKey="endLabel"
            position="right"
            content={(props) => (
              <g width={props.width} height={props.height} x={props.x} y={props.y}>
                <text
                  x={Number(props.width) + 145}
                  y={(props.y as number) + 15}
                  //y={(props.y as number) + 25}
                  fill="#808080"
                  //fontWeight={400}
                  fontWeight={200}
                  fontSize={12}
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  {props.value}
                </text>
              </g>
            )}
          />
          {data.map((v, index) => {
            return (<Cell key={`${v.hours}-${index}`} fill={v.color || '#008ae5'} radius={4} />);
          })}
        </Bar>
      </BarChart>
    </div>
  );
};

export const ExampleTimelineChart: React.FC<TimelineChartProps> = ({ data }) => {
  return (
    <S.TimelineContainer>
      {data.map(({ count, time }, index) => (
        <S.TimelineData key={`${count}-${index}`}>
          <S.TimelineCount>{count && <span>{parseInt(count) < 10 ? `0${count}` : count}</span>}</S.TimelineCount>
          <div>
            <S.TimelinePoint count={parseInt(count)} />
            <S.TimelineLine />
          </div>
          <S.TimelineHours>{time}</S.TimelineHours>
        </S.TimelineData>
      ))}
    </S.TimelineContainer>
  );
};

export const StackedBar: React.FC<StackedBarProps> = ({ style }) => {
  const data = [
    {
      label: 'Tempo em áreas produtivas',
      value: 8.3,
      color: '#2F4CDD',
    },
    {
      label: 'Tempo em áreas improdutivas',
      value: 0.29,
      color: '#00E0B4',
    },
    {
      label: 'Deslocamento',
      value: 0.47,
      color: '#FBBC00',
    },
  ];
  return <StackedBarChart data={data} style={style} labelTotal="Tempo médio total por recurso(hh:mm)" />;
};
