import React from 'react';
import { DefaultIconProps } from './types';
export const ArrowUpwardIcon: React.FC<DefaultIconProps> = ({ fill, width, height, style, onClick }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path d="M7 16V3.825L1.4 9.425L0 8L8 0L16 8L14.6 9.425L9 3.825V16H7Z" fill={fill || '#2B9E30'} />
    </svg>
  );
}