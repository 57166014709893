import React from 'react';

import { IconLoading } from '../IconLoading';

import * as S from './styles';

export const LoadingContainer: React.FC = () => {
  return (
    <S.Tbody>
      <tr>
        <td width="100%" height="100%" colSpan={100}>
          <IconLoading />
        </td>
      </tr>
    </S.Tbody>
  );
};