import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { CardHeader } from '../../CardHeader';
import { HomeComponentList } from '../types';
import * as S from './styles';
import { useHomeAdminContext } from '../context/HomeContext';
import { TrendUpIcon } from '../../../../../Icons/TrendUp';
import { MapTracking } from '../../../../../Icons/MapTracking';
import { monitoredAreas, MonitoredAreasData } from '../mock';
import { ArrowRightVuesax } from '../../../../../Icons/ArrowRight-Vuesax';
import { IconCloseVuesax } from '../../../../../Icons/Close-Vuesax';
import { capitalize } from '../../../../../utils/Capitalize';
import { DangerIcon } from '../../../../../Icons/Danger';
import { IconAddVuesax } from '../../../../../Icons/Add-Vuesax';
import { allQuestions, filters } from './QuestionsAndAnswers';
import { TrendDownIcon } from '../../../../../Icons/TrendDown';
import { useAuthContext } from '../../../../../stores';
import { MenuPermission } from '../../../../../types';
import { pagesIcons } from './pages';
import { PagesCarousel } from './Carousel';

interface Props {
  component: HomeComponentList;
  icon: string;
  info?: string;
  title?: string;
  subtitle?: string;
}

const MonitoredPreviewCard: React.FC = () => {
  const [activeArea, setActiveArea] = useState<MonitoredAreasData>();

  const showCollaborators = (activeArea: MonitoredAreasData) => {
    return activeArea.data.map((item, index) => (
      <S.MonitoredCollaborator key={`${item.occupation}-${index}`}>
        <p>{capitalize(item.occupation)}</p>
      </S.MonitoredCollaborator>
    ));
  };

  const toggleActiveArea = (area: MonitoredAreasData) => {
    return setActiveArea(area);
  };
  return (
    <>
      <S.MonitoredDataSection>
        <div>
          <span>HH Monitorado</span>
          <h1>
            848h <TrendUpIcon />
          </h1>
        </div>

        <div>
          <span>Colaboradores hoje</span>
          <h1>
            41
            <TrendUpIcon />
          </h1>
        </div>
        <div>
          <span>Colaboradores Ativos</span>
          <h1>
            22
            <TrendUpIcon />
          </h1>
        </div>
      </S.MonitoredDataSection>
      <S.MonitoredAreasSection>
        <S.MonitoredAreasNames>
          <S.MonitoredAreasHeader>
            <MapTracking />
            <p>Áreas monitoradas</p>
          </S.MonitoredAreasHeader>
          {monitoredAreas.map((item, index) => {
            const isActive = activeArea?.nome === item.nome && activeArea.total === item.total;
            return (
              <S.MonitoredAreasItem
                active={isActive}
                key={`${item.nome}-${index}`}
                onClick={() => toggleActiveArea(item)}
              >
                <span>{item.total}</span>
                <p>{item.nome}</p>
                {isActive ? <></> : <ArrowRightVuesax />}
              </S.MonitoredAreasItem>
            );
          })}
        </S.MonitoredAreasNames>
        <S.MonitoredAreasCollaborators>
          {activeArea && activeArea.nome ? (
            <IconCloseVuesax
              style={{ cursor: 'pointer', marginBottom: '-1.6rem' }}
              onClick={() => setActiveArea(undefined)}
            />
          ) : (
            <></>
          )}
          {activeArea && showCollaborators(activeArea)}
        </S.MonitoredAreasCollaborators>
      </S.MonitoredAreasSection>
    </>
  );
};

const EmergencyPreviewCard: React.FC = () => {
  const notifications = ['', ''];
  return (
    <>
      <DangerIcon width={59} height={56} fill="#ED3A3A" />
      <S.MainMessage type="error">
        {notifications.length >= 2
          ? 'Algumas emergências estão ocorrendo agora!'
          : 'Uma emergência está ocorrendo agora!'}
        <br />
        Vá para a{' '}
        <b>
          {/*<Link to="/emergencia">página de emergências</Link-->*/}
          <a href="/emergencia" onClick={() => {}}>
            página de emergências
          </a>
        </b>{' '}
        para verificar o ocorrido
      </S.MainMessage>
    </>
  );
};

const HelpPreviewCard: React.FC = () => {
  const [subjectFilter, setSubjectFilter] = useState('');
  const questions = useMemo(
    () => allQuestions.filter((item) => item.filter.some((filter) => filter === subjectFilter)),
    [subjectFilter]
  );
  return (
    <>
      <S.ScrollChipsContainer>
        {filters.map((filter, index) => (
          <S.Chip key={`${filter}-${index}`} onClick={() => setSubjectFilter(filter)}>
            {filter}
          </S.Chip>
        ))}
      </S.ScrollChipsContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginBottom: questions.length <= 3 ? '15%' : '',
        }}
      >
        {questions.map((item, index) => (
          <S.QuestionItem key={`${item.question}-${index}`}>
            <IconAddVuesax />
            {item.question}
          </S.QuestionItem>
        ))}
      </div>
    </>
  );
};

const InOutPreviewCard: React.FC = () => {
  return (
    <S.InOutDataSection>
      <div>
        <span>Chegada mais tarde</span>
        <h1>
          08:43
          <TrendDownIcon fill="#33B469" />
        </h1>
        <p>13/04/2024</p>
      </div>
      <div>
        <span>Média(Chegada/saída)</span>
        <h1>07:58/17:43</h1>
      </div>
      <div>
        <span>Saída mais cedo</span>
        <h1>
          17:16
          <TrendDownIcon />
        </h1>
        <p>19/04/2024</p>
      </div>
    </S.InOutDataSection>
  );
};

const PTWithdrawalPreviewCard: React.FC = () => {
  return (
    <S.InOutDataSection>
      <div>
        <span>Menor tempo</span>
        <h1>
          13min
          <TrendDownIcon fill="#33B469" />
        </h1>
      </div>
      <div>
        <span>Média</span>
        <h1>
          29min
          <TrendUpIcon fill="#ED3A3A" />
        </h1>
      </div>
      <div>
        <span>Maior tempo</span>
        <h1>
          103min
          <TrendUpIcon fill="#ED3A3A" />
        </h1>
      </div>
    </S.InOutDataSection>
  );
};

const PagesPreviewCard: React.FC = () => {
  const [currentPageHovered, setCurrentPageHovered] = useState('');
  const { authData } = useAuthContext();
  const allPages = authData?.menu || undefined;
  if (!allPages) return <></>;
  return (
    <S.PagesContainer>
      {allPages.length <= 4 ? (
        allPages
          .filter((page: MenuPermission) => page.key != 'telahome')
          .map((page, index, arr) => {
            const isCardHovered = currentPageHovered === page.key;
            return (
              <S.CardContainer
                style={{ width: `${100 / arr.length}%`, color: isCardHovered ? '#2f4cdd' : '#666666' }}
                key={page.key}
              >
                <S.PageCard
                  onMouseEnter={() => setCurrentPageHovered(page.key)}
                  onMouseLeave={() => setCurrentPageHovered('')}
                >
                  {pagesIcons[page.icon]({
                    fill: isCardHovered ? '#ffffff' : '#666666',
                    style: {
                      width: '75%',
                      minWidth: '6.3rem',
                      minHeight: '7.2rem',
                    },
                  })}
                </S.PageCard>
                {page.description}
              </S.CardContainer>
            );
          })
      ) : (
        <PagesCarousel pages={allPages.filter((page: MenuPermission) => page.key != 'telahome')} />
      )}
    </S.PagesContainer>
  );
};

export const PreviewCardHome: React.FC<Props> = (props) => {
  const components: ReactNode[] = [];
  switch (props.component) {
    case 'Monitoramento':
      components.push(<MonitoredPreviewCard key={`MonitoramentoPreview`} />);
      break;
    case 'Emergência (tempo real)':
      components.push(<EmergencyPreviewCard key={`EmergenciaPreview`} />);
      break;
    case 'Ajuda':
      components.push(<HelpPreviewCard key={`AjudaPreview`} />);
      break;
    case 'Chegadas e saídas':
      components.push(<InOutPreviewCard key={`ChegadasESaidasPreview`} />);
      break;
    case 'Retirada de PT':
      components.push(<PTWithdrawalPreviewCard key={`RetiradadePTPreview`} />);
      break;
    case 'Páginas':
      components.push(<PagesPreviewCard key={`PaginasPreview`} />);
      break;
    default:
      break;
  }

  return (
    <S.Container fullWidth>
      <CardHeader
        icon={props.icon}
        title={props.title || props.component}
        info={props.info}
        subtitle={props.subtitle}
        style={
          props.component === 'Ajuda'
            ? {
                marginBottom: '9.6rem',
              }
            : {}
        }
      />
      {components}
    </S.Container>
  );
};
