import React from 'react';
import { DefaultIconProps } from '../types';

export const HomeMenuIcon: React.FC<Omit<DefaultIconProps, 'fill'>> = ({ width, height, style, onClick }) => {
  return (
    <svg
      width={width || '40'}
      height={height || '40'}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M15.033 4.73035L6.04967 11.7303C4.54967 12.897 3.33301 15.3803 3.33301 17.2637V29.6137C3.33301 33.4803 6.48301 36.647 10.3497 36.647H29.6497C33.5163 36.647 36.6663 33.4803 36.6663 29.6303V17.497C36.6663 15.4803 35.3163 12.897 33.6663 11.747L23.3663 4.53035C21.033 2.89702 17.283 2.98035 15.033 4.73035Z"
        stroke={'white'}
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 29.9844V24.9844"
        stroke={'white'}
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};