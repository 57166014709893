import { AxiosResponse, CancelToken } from 'axios';

import {
  AddNewCollaborator,
  DeleteCollaborator,
  GetClients,
  GetCompanies,
  GetHeatMapData,
  GetHHIndicators,
  GetMachinesSummary,
  GetMapCoordinates,
  GetMonitoredResources,
  GetProfessions,
  GetTimelineData,
  TimelineData,
  SolveIncident,
  CreateNewClient,
  CreateNewCompany,
  GetSiteCoordinates,
  CreateNewArea,
  GetGeneralDataToExport,
  defaultApiResponse,
  GetBulletinReportInfos,
  ChangePageAccess,
  ChangeComponents } from '.';
import {
  AddCollaborators,
  DeleteCollaborators,
  LinkCollaboratos } from '../../../containers/linkCollaborator/types/linkCollaborators';
import {
  CreateArea,
  CreatedClients,
  CreatedCompany } from '../../../containers/clientCompanyRegistration/types/ClientCompany';
import { DashboardInOutData, 
         DashboardWorkingPTData, 
         MonitoredAreasInEmergencyData, 
         RealTimeLocations } from '../../../types/MonitoredData';
import { IHeatmapArea } from '../../../containers/moviment/HeatMap/types/HeatMapData';
import { Feedbacks } from '../../../containers/insights/types/Insights';
import { Incidents, SolveIncidents } from '../../../containers/incidents/types/Incidents';
import { ScannertStatus, TagStatus } from '../../../containers/scannerStatus/types';
import { Burndown, AreaTimeline, CollaboratorsCount } from '../../../containers/indicators/types/Indicators';
import { TextInsightsMessages } from '../../../containers/textInsights/types/TextInsights';

export type StatusData = {
  local: string;
  scanner: string;
  status: boolean;
  lastCheck: string;
}

export const dateTime = (date: Date): string => {
  return new Intl.DateTimeFormat('pt-br', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
}

const getEmptyArray = (): any => ([]);

const mockedData = [
  { occupation: 'Almoxarife', quantity: 1 },
  { occupation: 'Aux Montagem de Andaime', quantity: 2 },
  { occupation: 'Caldereiro', quantity: 8 },
  { occupation: 'Eletricista', quantity: 4 },
  { occupation: 'Encarregado de Civil', quantity: 1 },
  { occupation: 'Engenheiro Civil', quantity: 1 },
  { occupation: 'Funileiro', quantity: 1 },
  { occupation: 'Gerente de Produção', quantity: 2 },
  { occupation: 'Instrumentista', quantity: 4 },
  { occupation: 'Isolador Termico', quantity: 1 },
  { occupation: 'Mecânico', quantity: 3 },
  { occupation: 'Meio Oficial Refrigeração', quantity: 1 },
  { occupation: 'Montador de Andaime', quantity: 8 },
  { occupation: 'Pedreiro', quantity: 2 },
  { occupation: 'Pintor', quantity: 4 },
  { occupation: 'Servente', quantity: 1 },
  { occupation: 'Soldador', quantity: 1 },
  { occupation: 'Supervisor de Projetos', quantity: 1 },
  { occupation: 'Tec Seg do Trabalho', quantity: 1 },
  { occupation: 'Técnico Refrigeração', quantity: 1 },
];

const companies = [
  { id: 1, name: 'Limite Industria', cnpj: '12345678912345', razao_social: 'Limite Industria' },
  { id: 2, name: 'Industria Rack', cnpj: '12345678912345', razao_social: 'Industria Rack' },
  { id: 3, name: 'Bônus Industria', cnpj: '12345678912345', razao_social: 'Bônus Industria' },
  { id: 4, name: 'Jubileu Industria', cnpj: '12345678912345', razao_social: 'Jubileu Industria' },
  { id: 5, name: 'Industria Fado', cnpj: '12345678912345', razao_social: 'Industria Fado' },
  { id: 6, name: 'Industria Energia', cnpj: '12345678912345', razao_social: 'Industria Energia' },
  { id: 7, name: 'Resoluto Energia', cnpj: '12345678912345', razao_social: 'Resoluto Energia' },
  { id: 8, name: 'Nobre Lamina', cnpj: '12345678912345', razao_social: 'Nobre Lamina' },
  { id: 9, name: 'Península Cimento', cnpj: '12345678912345', razao_social: 'Península Cimento' },
];

export const getTimelineData: GetTimelineData = async () => [
  {
    first: true,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '00:00',
    count: '5',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '01:00',
    count: '3',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '02:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '03:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '04:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '05:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '06:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '07:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '08:00',
    count: '34',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '09:00',
    count: '18',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '10:00',
    count: '21',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '11:00',
    count: '7',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '12:00',
    count: '8',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '13:00',
    count: '15',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '14:00',
    count: '14',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '15:00',
    count: '22',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '16:00',
    count: '23',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '17:00',
    count: '9',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '18:00',
    count: '2',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '19:00',
    count: '5',
    data: mockedData,
  },
  {
    first: false,
    blink: true,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '20:00',
    count: '3',
    data: mockedData,
  },
  {
    first: false,
    date: '14/12/2021',
    time: '21:00',
    count: '0',
    background: '#c4c4c4',
    color: '#ffffff',
    blink: false,
    data: mockedData,
  },
  {
    first: false,
    date: '14/12/2021',
    time: '22:00',
    count: '0',
    background: '#c4c4c4',
    color: '#ffffff',
    blink: false,
    data: mockedData,
  },
  {
    first: false,
    date: '14/12/2021',
    time: '23:00',
    count: '0',
    background: '#c4c4c4',
    color: '#ffffff',
    blink: false,
    data: mockedData,
  },
];

export const getAreas = async (
  cancelToken: CancelToken,
  excludeImproductZone?: boolean
): Promise<AxiosResponse<any>> => {
  console.log(cancelToken);
  console.log(excludeImproductZone);
  return getEmptyArray();
}

export const getCompanies: GetCompanies = async (operational: boolean, cancelToken?: CancelToken) => {
  console.log(cancelToken);
  console.log(operational);
  return getEmptyArray();
}

export const getMachinesSummary: GetMachinesSummary = async (_cancelToken?: CancelToken) => ({
  data: [
    {
      partnumber: '1',
      alias: 'Empilhadeira 1',
      location: 'Pia úmido',
      timestamp: '02/05/2022 10:00:00',
      status: '$4',
    },
    {
      partnumber: '2',
      alias: 'Empilhadeira 2',
      location: 'Pia Seco',
      timestamp: '06/05/2022 10:30:00',
      status: '$4',
    },
    {
      partnumber: '3',
      alias: 'Transpaleteira 1',
      location: 'Almoxarifado',
      timestamp: '04/05/2022 09:00:00',
      status: '$4',
    },
    {
      partnumber: '4',
      alias: 'Transpeleteira 2',
      location: 'Dsida',
      timestamp: '23/05/2022 10:30:00',
      status: '$4',
    },
    {
      partnumber: '5',
      alias: 'Transpaleteira 3',
      location: 'Oficina',
      timestamp: '01/05/2022 10:30:00',
      status: '$4',
    },
    {
      partnumber: '6',
      alias: 'Transpaleteira patolada 1',
      location: 'Pcl3',
      timestamp: '15/05/2022 10:30:00',
      status: '$4',
    },
  ],
  idValues: ['1', '2', '3', '4', '5', '6'],
  statusValues: ['em espera', 'em manutenção', 'em uso', 'em espera', 'em manutenção', 'em uso'],
});

export const putMachineStatus: any = async (
  status: string,
  id: string,
  cancelToken?: CancelToken
): Promise<any> => {
  console.log(id + ' / ' + status + ' / ' + cancelToken);
}

export const getHeatMapData: GetHeatMapData = async (
  clientId: number,
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<IHeatmapArea[]> => {
  console.log(clientId);
  console.log(companyId);
  console.log(professionId);
  console.log(initialDate);
  console.log(finalDate);
  console.log(cancelToken);

  return [
    {
      value: 2.78,
      name: 'AREA 1',
      center: [-12.654476030737978, -38.30130636692047],
      radius: 20,
      color: 'orange',
      fillColor: 'orange',
      opacity: 1.0,
    },
    {
      value: 0.1,
      name: 'AREA 2',
      center: [-12.656067195364894, -38.300646543502815],
      radius: 15,
      color: 'yellow',
      fillColor: 'yellow',
      opacity: 0.6,
    },
    {
      value: 0.33,
      name: 'AREA 3',
      center: [-12.654533605946835, -38.30104351043702],
      radius: 15,
      color: 'yellow',
      fillColor: 'yellow',
      opacity: 0.6,
    },
  ];
}

export const getHHIndicators: GetHHIndicators = async (
  clientId: number,
  companyId: string,
  cancelToken?: CancelToken
) => {
  console.log(clientId);
  console.log(companyId);
  console.log(cancelToken);
  return '695' as any;
}

export const getMapCoordinates: GetMapCoordinates = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return {
    id: 1,
    hasMap: true,
    latitude: '-13.01013079271207',
    longitude: '-38.53278838137427',
    zoom: '16.5',
  }
}

export const getAverageTimeChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  areas: string,
  collaboratorId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(clientId);
  console.log(companies);
  console.log(professions);
  console.log(areas);
  console.log(collaboratorId);
  console.log(initialDate);
  console.log(finalDate);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getMonitoredResources: GetMonitoredResources = async (
  clientId: number,
  companyId: string,
  professionId: string,
  cancelToken?: CancelToken
) => {
  console.log(clientId);
  console.log(companyId);
  console.log(professionId);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getProfessions: GetProfessions = async (cancelToken?: CancelToken) => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const getPeopleInAreasChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  return getRankChartData(clientId, companies, professions, initialDate, finalDate, cancelToken);
}

export const getProductivityByAreaData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  return getRankChartData(clientId, companies, professions, initialDate, finalDate, cancelToken);
}

export const getRankChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(clientId);
  console.log(companies);
  console.log(professions);
  console.log(initialDate);
  console.log(finalDate);
  console.log(cancelToken);
  return getEmptyArray();;
}

export const getMonitoredAreasInEmergency = async (clientId: number, cancelToken: CancelToken) => {
  console.log(clientId);
  console.log(cancelToken);
  return [
    {
      nome: 'Area 1',
      lat: -38.214832762964164,
      lng: -12.802470628248315,
      total: 10,
      data: [
        {
          occupation: 'occupation 1',
          quantity: 5,
        },
        {
          occupation: 'occupation 2',
          quantity: 5,
        },
      ],
    },
    {
      nome: 'Area 2',
      lat: -38.214832762964164,
      lng: -12.802470628248315,
      total: 5,
      data: [
        {
          occupation: 'occupation 3',
          quantity: 3,
        },
        {
          occupation: 'occupation 4',
          quantity: 2,
        },
      ],
    },
  ] as MonitoredAreasInEmergencyData[];
}

export const getMonitoredFloor = async (
  clientId: number,
  cancelToken: CancelToken,
  areaId: number,
  companyId: number
) => {
  console.log(clientId);
  console.log(cancelToken);
  console.log(areaId);
  console.log(companyId);
  return {
    nome: 'Floor 1',
    lat: -38.214832762964164,
    lng: -12.802470628248315,
    total: 10,
    data: [
      {
        occupation: 'occupation 1',
        quantity: 5,
      },
      {
        occupation: 'occupation 2',
        quantity: 5,
      },
    ],
  } as MonitoredAreasInEmergencyData;
}

export const getRetiradaPTChartData = async (
  companyId: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(companyId);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getLunchChartData = async (
  companyId: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(companyId);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getAverageTimeInsightsChartData = async (
  companyId: number,
  areaId: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(companyId);
  console.log(areaId);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getChegadaSaidaChartData = async (
  company: number,
  areaId: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(company);
  console.log(areaId);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getFeedbacksData = async (company: number, cancelToken: CancelToken): Promise<Feedbacks[]> => {
  console.log(company);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getEmergencyNotifications = async (clientId: number, cancelToken: CancelToken): Promise<any[]> => {
  console.log(clientId);
  console.log(cancelToken);
  return {
    data: [
      {
        timestamp: '15/05/2023 16:39',
        event: 'Queda identificada',
        area: 'AREA 1',
        alias: 'colaborador1',
        tag: 'ac:23:3f:aa:bb:cc',
      },
      {
        timestamp: '15/05/2023 16:32',
        event: 'Botão de pânico disparado',
        area: 'AREA 2',
        alias: 'colaborador2',
        tag: 'ac:23:3f:dd:ee:11',
      },
      {
        timestamp: '15/05/2023 16:26',
        event: 'Queda identificada',
        area: 'AREA 1',
        alias: 'colaborador1',
        tag: 'ac:23:3f:aa:bb:cc',
      },
      {
        timestamp: '15/05/2023 16:24',
        event: 'Botão de pânico disparado',
        area: 'AREA 3',
        alias: 'colaborador3',
        tag: 'ac:23:3f:de:fg:66',
      },
      {
        timestamp: '15/05/2023 16:20',
        event: 'Botão de pânico disparado',
        area: 'AREA 1',
        alias: 'colaborador1',
        tag: 'ac:23:3f:aa:bb:cc',
      },
      {
        timestamp: '15/05/2023 16:20',
        event: 'Queda identificada',
        area: 'AREA 2',
        alias: 'colaborador2',
        tag: 'ac:23:3f:dd:ee:11',
      },
    ],
  } as any;
}

export const getAgileanData = async (
  companyId: number,
  professionId: number,
  areaId: number,
  activityId: string,
  initialDate: number,
  finalDate: number,
  hours: number,
  cancelToken: CancelToken
): Promise<Burndown> => {
  console.log(companyId);
  console.log(professionId);
  console.log(areaId);
  console.log(activityId);
  console.log(initialDate);
  console.log(finalDate);
  console.log(hours);
  console.log(cancelToken);
  return { series: [], benchmark: [] } as Burndown;
}

export const getAgileanCurves = async (
  companyId: number,
  professionId: number,
  areaId: number,
  activityId: string,
  initialDate: number,
  finalDate: number,
  hours: number,
  plan: number,
  cancelToken: CancelToken
): Promise<Burndown> => {
  console.log(companyId);
  console.log(professionId);
  console.log(areaId);
  console.log(activityId);
  console.log(initialDate);
  console.log(finalDate);
  console.log(hours);
  console.log(cancelToken);
  return { series: [], benchmark: [] } as Burndown;
}

export const getActivities = async (areaId: number, cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  console.log(areaId);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getWorkingPermitChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  monthValues: boolean,
  yearValues: boolean,
  weekValues: boolean,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(clientId);
  console.log(companies);
  console.log(professions);
  console.log(initialDate);
  console.log(finalDate);
  console.log(monthValues);
  console.log(yearValues);
  console.log(weekValues);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getInOutChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  areas: string,
  monthValues: boolean,
  yearValues: boolean,
  weekValues: boolean,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(clientId);
  console.log(companies);
  console.log(professions);
  console.log(initialDate);
  console.log(finalDate);
  console.log(areas);
  console.log(monthValues);
  console.log(yearValues);
  console.log(weekValues);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getCollaboratorsCount = async (
  clientId: number,
  companyId: string,
  currentDate: string,
  cancelToken: CancelToken
): Promise<CollaboratorsCount[]> => {
  console.log(clientId);
  console.log(companyId);
  console.log(currentDate);
  console.log(cancelToken);
  return [];
}

export const getPlanningCollaboratorsCount = async (
  companyId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<CollaboratorsCount[]> => {
  console.log(companyId);
  console.log(initialDate);
  console.log(finalDate);
  console.log(cancelToken);
  return [];
}

export const getTextInsightsMessages = async (
  companyId: number,
  professionId: number,
  areaId: number,
  collaboratorId: number,
  clientId: number,
  cancelToken: CancelToken
): Promise<TextInsightsMessages[]> => {
  console.log(companyId);
  console.log(professionId);
  console.log(areaId);
  console.log(collaboratorId);
  console.log(clientId);
  console.log(cancelToken);
  return [];
}

export const getClients: GetClients = async (cancelToken?: CancelToken, omitCivil?: boolean) => {
  console.log(cancelToken);
  console.log(omitCivil);
  return getEmptyArray();
}

export const getLinkedTags = async (
  companyId: number,
  professionId: number,
  tagAlias: string,
  cancelToken: CancelToken
): Promise<LinkCollaboratos[]> => {
  console.log(companyId);
  console.log(professionId);
  console.log(cancelToken);
  return [];
}

export const getTagsToLink = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const addNewCollaborator: AddNewCollaborator = async (
  addCollaboratorsRequest: AddCollaborators,
  cancelToken: CancelToken
) => {
  console.log(cancelToken);
  console.log(addCollaboratorsRequest);

  return {
    id: '1',
    error: '',
  }
}

export const deleteCollaborator: DeleteCollaborator = async (
  deleteCollaboratorsRequest: DeleteCollaborators,
  cancelToken: CancelToken
) => {
  console.log(cancelToken);
  console.log(deleteCollaboratorsRequest);

  return {
    id: '1',
    error: '',
  }
}

export const getNewCollaboratorAlias = async (professionId: number, cancelToken: CancelToken): Promise<string> => {
  console.log(professionId);
  console.log(cancelToken);
  return '';
}

export const getProductivityVSDisplacement = async (
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  areaId: number,
  monthValues: boolean,
  yearValues: boolean,
  weekValues: boolean,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(companyId);
  console.log(professionId);
  console.log(initialDate);
  console.log(finalDate);
  console.log(areaId);
  console.log(monthValues);
  console.log(yearValues);
  console.log(weekValues);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getDisplacement = async (
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(companyId);
  console.log(professionId);
  console.log(initialDate);
  console.log(finalDate);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getAverageSpeedInsight = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const getMovingOnFootInsight = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const getMovingByCarInsight = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const getOptimizationsInsight = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const getDailySavingInsight = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const getRoiInsight = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const getSavingsInsight = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const getLocations = async (cancelToken: CancelToken): Promise<RealTimeLocations[]> => {
  console.log(cancelToken);
  return [
    {
      tag: 'aa:bb:cc:11:22:33',
      area: 'Area 1',
      color: '#0000FF',
    },
    {
      tag: 'xx:yy:zz:11:22:33',
      area: 'Area 2',
      color: '#00FF00',
    },
  ];
}

export const getAbsenteeism = async (
  clientId: number,
  companyId: string,
  currentDate: string,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(clientId);
  console.log(companyId);
  console.log(currentDate);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getIncidents = async (
  clientId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  areaId: number,
  cancelToken: CancelToken
): Promise<Incidents[]> => {
  console.log(clientId);
  console.log(professionId);
  console.log(initialDate);
  console.log(finalDate);
  console.log(areaId);
  console.log(cancelToken);
  return getEmptyArray();
}

export const solveIncident: SolveIncident = async (
  clientId: number,
  solveIncidentRequest: SolveIncidents,
  cancelToken: CancelToken
) => {
  console.log(clientId);
  console.log(cancelToken);
  console.log(solveIncidentRequest);

  return {
    id: '1',
    error: '',
  }
}

export const getScannersStatus = async (clientId: number, cancelToken: CancelToken): Promise<ScannertStatus[]> => {
  return [
    {
      local: 'Area 1',
      scanner: 'trackdemo01',
      status: false,
      lastCheck: dateTime(new Date(2024, 2, 20, 13, 44)),
      lastReading: dateTime(new Date(2024, 2, 20, 13, 44)),
    },
    {
      local: 'Area 2',
      scanner: 'trackdemo02',
      status: false,
      lastCheck: dateTime(new Date(2024, 2, 20, 13, 42)),
      lastReading: dateTime(new Date(2024, 2, 20, 13, 44)),
    },
    {
      local: 'Area 3',
      scanner: 'trackdemo03',
      status: false,
      lastCheck: dateTime(new Date(2024, 2, 20, 18, 10)),
      lastReading: dateTime(new Date(2024, 2, 20, 13, 44)),
    },
    {
      local: 'Area 4',
      scanner: 'trackdemo04',
      status: false,
      lastCheck: dateTime(new Date(2024, 2, 20, 15, 15)),
      lastReading: dateTime(new Date(2024, 2, 20, 13, 44)),
    },
  ];
}

export const getAreaTimelineData = async (clientId: number, data: TimelineData): Promise<AreaTimeline[]> => {
  console.log(clientId);
  console.log(data);
  return getEmptyArray();
}

export const getAreasTimeDivision = async (
  clientId: number,
  companies: string,
  professions: string,
  collaborators: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(clientId);
  console.log(companies);
  console.log(professions);
  console.log(collaborators);
  console.log(initialDate);
  console.log(finalDate);
  console.log(cancelToken);
  return getEmptyArray();
}

export const getDisplacementsAtoB = async (
  companies: string,
  professions: string,
  collaborators: string,
  initialDate: number,
  finalDate: number,
  initialArea: number,
  finalArea: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(companies);
  console.log(professions);
  console.log(collaborators);
  console.log(initialDate);
  console.log(finalDate);
  console.log(initialArea);
  console.log(finalArea)
  console.log(cancelToken);
  return getEmptyArray();
}

export const getCreatedClients = async (cancelToken: CancelToken): Promise<CreatedClients[]> => {
  console.log(cancelToken);
  return getEmptyArray();
}

export const createNewClient: CreateNewClient = async (
  createNewClientRequest: CreatedClients,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(cancelToken);
  console.log(createNewClientRequest);

  return getEmptyArray();
}

export const getCreatedCompany = async (cancelToken: CancelToken, clientId: number): Promise<CreatedCompany[]> => {
  console.log(cancelToken);
  console.log(clientId);
  return getEmptyArray();
}

export const createNewCompany: CreateNewCompany = async (
  createNewCompanyRequest: CreatedCompany,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(cancelToken);
  console.log(createNewCompanyRequest);

  return getEmptyArray();
}

export const getSiteCoordinates: GetSiteCoordinates = async (clientId: number, cancelToken: CancelToken) => {
  console.log(cancelToken);
  console.log(clientId);
  
  return {
    id: 1,
    hasMap: true,
    latitude: '-13.01013079271207',
    longitude: '-38.53278838137427',
    zoom: '16.5',
  }
}

export const createNewArea: CreateNewArea = async (
  createNewAreaRequest: CreateArea,
  cancelToken: CancelToken
): Promise<AxiosResponse<any>> => {
  console.log(cancelToken);
  console.log(createNewAreaRequest);

  return getEmptyArray();
}

export const getDashboardInOutData = async (companies: string,
                                            professions: string,
                                            collaborators: string,
                                            areas: string,
                                            initialDate: number,
                                            finalDate: number,
                                            week: boolean,
                                            month: boolean,
                                            quarter: boolean,
                                            cancelToken: CancelToken): Promise<DashboardInOutData> => {
  console.log(companies);
  console.log(professions);
  console.log(collaborators);
  console.log(areas);
  console.log(initialDate);
  console.log(finalDate);
  console.log(week);
  console.log(month);
  console.log(quarter);
  console.log(cancelToken);

  return {
    arrivalAndDepartureAverage: '06:52/17:20',
    arrivalImproved: true,
    departureImproved: true,
    earliestDeparture: '17:43',
    earliestDepartureDate: '01/06/2024',
    latestArrival: '07:28',
    latestArrivalDate: '18/05/2024',
  }
}

export const getDashboardWorkingPermit = async (companies: string,
  professions: string,
  currentDate: number,
  initialDate: number,
  finalDate: number,
  week: boolean,
  month: boolean,
  quarter: boolean,
  cancelToken: CancelToken): Promise<DashboardWorkingPTData> => {
  console.log(companies);
  console.log(professions);
  console.log(currentDate);
  console.log(initialDate);
  console.log(finalDate);
  console.log(week);
  console.log(month);
  console.log(quarter);
  console.log(cancelToken);

  return {
    min: '43min',
    minImproved: true,
    average: '253min',
    averageImproved: true,
    max: '810min',
    maxImproved: false,
  }
}

export const getGeneralDataToExport: GetGeneralDataToExport = async () => {
  return {
    config: {},
    data: [
      {
        profession: 'Pintor',
        collaborator: 'pintor 2',
        tag: 'abcdef123456',
        area: 'ADMINISTRAÇÃO',
        company: 'Terceirizada',
        start: '2024-01-14T03:00:00.000Z',
        end: '2024-01-14T03:00:59.000Z',
      },
      {
        profession: 'Eletricista',
        collaborator: 'eletricista 3',
        tag: 'abcdef123457',
        area: 'GALPÃO DE PRODUÇÃO',
        company: 'Trackfy',
        start: '2024-01-14T03:00:00.000Z',
        end: '2024-01-14T03:00:59.000Z',
      },
      {
        profession: 'Eletricista',
        collaborator: 'eletricista 4',
        tag: 'abcdef123458',
        area: 'ADMINISTRAÇÃO',
        company: 'Terceirizada',
        start: '2024-01-14T03:00:00.000Z',
        end: '2024-01-14T03:10:59.000Z',
      },
    ],
    headers: {},
    status: 200,
    statusText: '',
  }
}

export const getTagsStatus = async (cancelToken: CancelToken): Promise<TagStatus[]> => {
  console.log(cancelToken);

  return [
    {
      mac: 'ac:23:3f:ad:70:51',
      collaborator: 'Eletricista 2',
      lastCheck: '10/09/2024 - 10:17',
      lastArea: 'Almoxarifado 1',
      battery: '100%',
    },
    {
      mac: 'ac:23:3f:ad:70:57',
      collaborator: 'Eletricista 3',
      lastCheck: '09/09/2024 - 09:42',
      lastArea: 'Canteiro',
      battery: '68%',
    },
    {
      mac: 'ac:23:3f:ad:70:48',
      collaborator: 'Eletricista 5',
      lastCheck: '10/09/2024 - 10:38',
      lastArea: 'Oficina',
      battery: '29%',
    },
    {
      mac: 'ac:23:3f:ad:70:77',
      collaborator: 'Engenheiro químico 1',
      lastCheck: '10/09/2024 - 10:19',
      lastArea: 'Ponto de Encontro HDT',
      battery: '0%',
    },
    {
      mac: 'ac:23:3f:ad:70:15',
      collaborator: 'Mecânico 3',
      lastCheck: '10/09/2024 - 10:21',
      lastArea: 'Vivência',
      battery: '92%',
    },
    {
      mac: 'ac:23:3f:ad:70:53',
      collaborator: 'Engenheiro químico 2',
      lastCheck: '10/09/2024 - 10:32',
      lastArea: 'Ponto de Encontro HDT',
      battery: '97%',
    },
    {
      mac: 'ac:23:3f:ad:70:71',
      collaborator: 'Pedreiro 2',
      lastCheck: '10/09/2024 - 10:34',
      lastArea: 'Canteiro',
      battery: '84%',
    },
    {
      mac: 'ac:23:3f:ad:70:55',
      collaborator: 'Ajudante de pedreiro 4',
      lastCheck: '10/09/2024 - 10:55',
      lastArea: 'Porão de Cabos',
      battery: '52%',
    },
    {
      mac: 'ac:23:3f:ad:70:49',
      collaborator: 'Técnico de segurança 1',
      lastCheck: '10/09/2024 - 10:57',
      lastArea: 'Porão de Cabos',
      battery: '31%',
    },
    {
      mac: 'ac:23:3f:ad:70:37',
      collaborator: 'Técnico de segurança 2',
      lastCheck: '08/09/2024 - 12:48',
      lastArea: 'Oficina',
      battery: '72%',
    },
  ];
}

export const getBulletinReportInfos: GetBulletinReportInfos = async (cancelToken: CancelToken) => {
  console.log(cancelToken);
  return defaultApiResponse();
}

export const changePageAccess: ChangePageAccess = async (pages, icons, groupId, cancelToken) => {
  console.log(pages);
  console.log(icons);
  console.log(groupId);
  console.log(cancelToken);
  return defaultApiResponse();
}

export const changeComponents: ChangeComponents = async (components, icons, groupId, cancelToken) => {
  console.log(components);
  console.log(icons);
  console.log(groupId);
  console.log(cancelToken);
  return defaultApiResponse();
}