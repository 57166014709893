import React, { useEffect, useState } from 'react';

import { useDebounce } from '../../hooks';
import { removeAccents } from '../../utils';
import { ResultListItem, SearchContent, SearchInput } from './components';
import './styles.css';

export type SearchProps = {
  value?: string;
  placeholder?: string;
  isActive?: boolean;
  customIcon?: React.ReactNode;
  data?: ResultListItem[];
  selectedItems?: ResultListItem | ResultListItem[] | null;
  multiselect?: boolean;
  onChangeValue?: (value: string) => void;
  onSelectValue?: (value: ResultListItem | null) => void;
  onToggle?: () => void;
};

export const Search: React.FC<SearchProps> = (props: SearchProps) => {
  const {
    value = '',
    placeholder = '',
    isActive = false,
    customIcon,
    data,
    selectedItems,
    onChangeValue,
    onSelectValue,
    onToggle,
  } = props;
  const [search, setSearch] = useState(value);
  const [searchResult, setSearchResult] = useState(data);

  const debouncedChange = useDebounce(onChangeValue, 5000);

  const handleSearchValue = (searchValue: string) => {
    setSearch(searchValue);

    if (!value) {
      if (onChangeValue) onChangeValue('');
      return;
    }

    debouncedChange(value);
  };

  useEffect(() => {
    if (!search) {
      setSearchResult(data);
      return;
    }

    const filteredData = data?.filter((item: ResultListItem) =>
      removeAccents(item.label).toUpperCase().includes(removeAccents(search.toUpperCase()))
    );

    setSearchResult(filteredData);
  }, [search, data]);

  return (
    <div className={isActive ? 'search-container-component active' : 'search-container-component'}>
      <SearchInput value={search}
        selectedItem={ selectedItems instanceof Array ? undefined : (selectedItems as ResultListItem) }
        placeholder={placeholder}
        active={isActive}
        customIcon={customIcon}
        onClick={onToggle}
        onClickSelectedItem={() => onSelectValue && onSelectValue(null)}
        onChange={handleSearchValue} />
      <SearchContent data={searchResult}
          active={isActive}
          onSelect={onSelectValue}
          selectedItems={selectedItems} />
    </div>
  );
};
