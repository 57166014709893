import React from 'react';
import { AdminContainer } from '../../containers/admin';
import { AdminContextProvider } from '../../containers/admin/context/AdminContext';
import { AdminPagesContextProvider } from '../../containers/admin/context/AdminPagesContext';

export const AdminPage: React.FC = () => (
  <AdminContextProvider>
    <AdminPagesContextProvider>
      <AdminContainer />
    </AdminPagesContextProvider>
  </AdminContextProvider>
);
