import React from 'react';

import { CheckedIcon } from '../../Icons/Checked';

import * as S from './styles';

interface Props {
  header?: boolean;
  selected: boolean;
  style?: React.CSSProperties;
  onSelect: (event: React.MouseEvent<HTMLDivElement>) => void;
  customIconSelected?: JSX.Element;
}

const checkedIcon = (customIconSelected?: JSX.Element, header?: boolean) => {
    if(customIconSelected || header) 
        return <S.LineSelectedHeader />;

    return <CheckedIcon style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />;
}

export const TableSelect: React.FC<Props> = ({ selected, header, style, onSelect, customIconSelected }) => {
  return (
    <S.SelectContainer header={header} selected={selected} style={style} onClick={onSelect}>
      {selected ? checkedIcon(customIconSelected, header) : (<></>)}
    </S.SelectContainer>
  );
}