import { DangerIcon } from '../../../../Icons/Danger';
import { IconFaQ } from '../../../../Icons/Faq';
import { GraphIcon } from '../../../../Icons/Graph';
import React from 'react';
import { UsersIconVuesax } from '../../../../Icons/Users-Vuesax';
import IconClock from '../../../../Icons/Clock';
import { DocumentIcon } from '../../../../Icons/Document';
import { HomeComponentList } from './types';
import { OccupationData } from '../../../home/components/MonitoringCard/mock';
import { RowItem } from '../Config/types';

export const homeRowsMock: RowItem<HomeComponentList>[] = [
  {
    name: 'Monitoramento',
    icon: ` <svg
      width='20'
      height='20'
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"

    >
      <path
        d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z"
        stroke=#808080
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6662 12.2521C15.8912 16.1104 12.1912 18.9104 7.98287 18.2271C4.82453 17.7188 2.28287 15.1771 1.7662 12.0187C1.0912 7.82708 3.87454 4.12708 7.7162 3.34375"
        stroke=#808080
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>`,
    selected: true,
    info: 'Esse atalho mostra uma lista das áreas com as pessoas dentro delas e um atalho do resumo!',
  },
  {
    name: 'Emergência (tempo real)',
    icon: `<svg
      width='20'
      height='20'
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"

    >
      <path
        d="M10 7.5V11.6667"
        stroke=#666666
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99986 17.8414H4.94986C2.0582 17.8414 0.849863 15.7747 2.24986 13.2497L4.84986 8.56641L7.29986 4.16641C8.7832 1.49141 11.2165 1.49141 12.6999 4.16641L15.1499 8.57474L17.7499 13.2581C19.1499 15.7831 17.9332 17.8497 15.0499 17.8497H9.99986V17.8414Z"
        stroke=#666666
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99512 14.166H10.0026"
        stroke=#666666
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>`,
    selected: true,
  },
  {
    name: 'Ajuda',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.167 15.3568H10.8337L7.12532 17.8235C6.57532 18.1901 5.83366 17.7985 5.83366 17.1318V15.3568C3.33366 15.3568 1.66699 13.6902 1.66699 11.1902V6.1901C1.66699 3.6901 3.33366 2.02344 5.83366 2.02344H14.167C16.667 2.02344 18.3337 3.6901 18.3337 6.1901V11.1902C18.3337 13.6902 16.667 15.3568 14.167 15.3568Z"
      stroke="#808080"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0462 9.93091C15.1731 10.0168 15.3117 10.1008 15.4621 10.1827C15.6124 10.2628 15.7119 10.3272 15.7607 10.376C15.8096 10.4229 15.834 10.4902 15.834 10.5781C15.834 10.6405 15.8047 10.703 15.7461 10.7655C15.6895 10.828 15.6202 10.8592 15.5382 10.8592C15.4718 10.8592 15.3908 10.8377 15.2951 10.7948C15.2014 10.7518 15.0901 10.6894 14.9613 10.6074C14.8344 10.5254 14.6948 10.4297 14.5425 10.3204C14.2595 10.4648 13.912 10.5371 13.5 10.5371C13.1662 10.5371 12.8665 10.4844 12.601 10.3789C12.3375 10.2716 12.1159 10.1183 11.9363 9.91919C11.7567 9.71811 11.621 9.47994 11.5292 9.20467C11.4394 8.9294 11.3945 8.62973 11.3945 8.30565C11.3945 7.97572 11.4414 7.67312 11.5351 7.39786C11.6308 7.12259 11.7684 6.88832 11.948 6.69505C12.1276 6.50177 12.3463 6.35438 12.604 6.25286C12.8617 6.14939 13.1545 6.09766 13.4825 6.09766C13.9276 6.09766 14.3092 6.18844 14.6275 6.37C14.9476 6.5496 15.1897 6.80632 15.3537 7.14016C15.5177 7.47204 15.5997 7.86249 15.5997 8.31151C15.5997 8.99285 15.4152 9.53265 15.0462 9.93091ZM14.3639 9.45651C14.4849 9.3179 14.5738 9.15391 14.6304 8.96454C14.689 8.77517 14.7182 8.55554 14.7182 8.30565C14.7182 7.99134 14.6675 7.719 14.566 7.48864C14.4645 7.25827 14.319 7.08452 14.1296 6.96739C13.9422 6.8483 13.7265 6.78875 13.4825 6.78875C13.3087 6.78875 13.1477 6.82194 12.9993 6.88832C12.8529 6.95274 12.726 7.04743 12.6186 7.17237C12.5132 7.29732 12.4292 7.4574 12.3668 7.65263C12.3062 7.8459 12.276 8.06358 12.276 8.30565C12.276 8.79957 12.3912 9.18026 12.6215 9.44772C12.8519 9.71323 13.1428 9.84598 13.4942 9.84598C13.6386 9.84598 13.787 9.81572 13.9393 9.7552C13.8475 9.68687 13.7324 9.61855 13.5937 9.55022C13.4571 9.48189 13.3634 9.42918 13.3126 9.39208C13.2619 9.35499 13.2365 9.30228 13.2365 9.23395C13.2365 9.17538 13.2609 9.12365 13.3097 9.07875C13.3585 9.03385 13.4122 9.01139 13.4708 9.01139C13.6484 9.01139 13.9461 9.15977 14.3639 9.45651Z"
      fill="#808080"
    />
    <path
      d="M10.1061 9.97776L9.90107 9.43894H8.15576L7.95077 9.98947C7.87073 10.2042 7.8024 10.3497 7.74578 10.4258C7.68917 10.5 7.59644 10.5371 7.46759 10.5371C7.35826 10.5371 7.26163 10.4971 7.17768 10.417C7.09373 10.337 7.05176 10.2462 7.05176 10.1447C7.05176 10.0861 7.06152 10.0256 7.08104 9.96312C7.10056 9.90065 7.13278 9.81377 7.17768 9.70249L8.27582 6.91467C8.30706 6.83463 8.34415 6.73897 8.3871 6.62769C8.432 6.51446 8.47886 6.42075 8.52766 6.34657C8.57842 6.27238 8.64382 6.21284 8.72386 6.16794C8.80586 6.12108 8.9064 6.09766 9.02549 6.09766C9.14653 6.09766 9.24707 6.12108 9.32711 6.16794C9.4091 6.21284 9.4745 6.27141 9.52331 6.34364C9.57407 6.41587 9.61604 6.49396 9.64923 6.57791C9.68437 6.65991 9.7283 6.77021 9.78101 6.90882L10.9026 9.67907C10.9904 9.88991 11.0344 10.0432 11.0344 10.1388C11.0344 10.2384 10.9924 10.3301 10.9084 10.4141C10.8264 10.4961 10.7269 10.5371 10.6097 10.5371C10.5414 10.5371 10.4828 10.5244 10.434 10.499C10.3852 10.4756 10.3442 10.4434 10.311 10.4024C10.2779 10.3594 10.2417 10.295 10.2027 10.2091C10.1656 10.1213 10.1334 10.0441 10.1061 9.97776ZM8.38417 8.78591H9.6668L9.01963 7.01424L8.38417 8.78591Z"
      fill="#808080"
    />
    <path
      d="M6.74982 6.84247H5.03672V7.94062H6.4687C6.60145 7.94062 6.70004 7.97088 6.76447 8.0314C6.83084 8.08996 6.86403 8.16903 6.86403 8.2686C6.86403 8.36816 6.83084 8.44723 6.76447 8.50579C6.69809 8.56436 6.5995 8.59365 6.4687 8.59365H5.03672V10.0081C5.03672 10.1877 4.99572 10.3214 4.91373 10.4092C4.83369 10.4951 4.73022 10.5381 4.60332 10.5381C4.47447 10.5381 4.36905 10.4942 4.28706 10.4063C4.20701 10.3185 4.16699 10.1857 4.16699 10.0081V6.70484C4.16699 6.5799 4.18554 6.47838 4.22263 6.40029C4.25972 6.32025 4.31732 6.26265 4.39541 6.22751C4.47545 6.19042 4.57697 6.17188 4.69996 6.17188H6.74982C6.88843 6.17188 6.99093 6.20311 7.0573 6.26558C7.12563 6.3261 7.1598 6.40615 7.1598 6.50571C7.1598 6.60723 7.12563 6.68922 7.0573 6.75169C6.99093 6.81221 6.88843 6.84247 6.74982 6.84247Z"
      fill="#808080"
    />
  </svg>`,
    selected: true,
  },
  {
    name: 'Chegadas e saídas',
    icon: `
        <svg
      width='20'
      height='20'
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.63314 9.05964C7.5498 9.0513 7.4498 9.0513 7.35814 9.05964C5.3748 8.99297 3.7998 7.36797 3.7998 5.36797C3.7998 3.3263 5.4498 1.66797 7.4998 1.66797C9.54147 1.66797 11.1998 3.3263 11.1998 5.36797C11.1915 7.36797 9.61647 8.99297 7.63314 9.05964Z"
        stroke=#666666
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6747 3.33203C15.2914 3.33203 16.5914 4.64036 16.5914 6.2487C16.5914 7.8237 15.3414 9.10703 13.7831 9.16537C13.7164 9.15703 13.6414 9.15703 13.5664 9.16537"
        stroke=#666666
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.4666 12.132C1.44993 13.482 1.44993 15.682 3.4666 17.0237C5.75827 18.557 9.5166 18.557 11.8083 17.0237C13.8249 15.6737 13.8249 13.4737 11.8083 12.132C9.52494 10.607 5.7666 10.607 3.4666 12.132Z"
        stroke=#666666
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2832 16.668C15.8832 16.543 16.4499 16.3013 16.9165 15.943C18.2165 14.968 18.2165 13.3596 16.9165 12.3846C16.4582 12.0346 15.8999 11.8013 15.3082 11.668"
        stroke=#666666
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    `,
    selected: true,
  },
  {
    name: 'Retirada de PT',
    icon: `
        <svg
    fill="none"
    width='20'
    height='20'
    stroke=#666666
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" />
    <polyline points="12 6 12 12 15 15" />
  </svg>
    `,
    selected: true,
  },
  {
    name: 'Páginas',
    icon: ` <svg
      width='20'
      height='20'
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"

    >
      <path
        d="M18.3337 8.33464V12.5013C18.3337 16.668 16.667 18.3346 12.5003 18.3346H7.50033C3.33366 18.3346 1.66699 16.668 1.66699 12.5013V7.5013C1.66699 3.33464 3.33366 1.66797 7.50033 1.66797H11.667"
        stroke=#808080
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3337 8.33464H15.0003C12.5003 8.33464 11.667 7.5013 11.667 5.0013V1.66797L18.3337 8.33464Z"
        stroke=#808080
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83301 10.832H10.833"
        stroke=#808080
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83301 14.168H9.16634"
        stroke=#808080
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>`,
    selected: true,
  },
];

export type MonitoredAreasData = {
  nome: string;
  total: number;
  cor: string;
  data: OccupationData[];
};

export const monitoredAreas: MonitoredAreasData[] = [
  {
    nome: 'Almoxarifado',
    total: 2,
    cor: '#2F4CDD',
    data: [
      {
        occupation: 'Função',
        quantity: 1,
      },
      {
        occupation: 'Função',
        quantity: 1,
      },
    ],
  },
  {
    nome: 'Nome da área',
    total: 9,
    cor: '#FF5050',
    data: [
      {
        occupation: 'Eletricista',
        quantity: 3,
      },
      {
        occupation: 'Engenheiro',
        quantity: 2,
      },
      {
        occupation: 'Função',
        quantity: 4,
      },
    ],
  },
  {
    nome: 'Cantina',
    total: 2,
    cor: '#E2A900',
    data: [
      {
        occupation: 'Função',
        quantity: 2,
      },
    ],
  },
  {
    nome: 'Laboratório',
    total: 4,
    cor: '#00caa2',
    data: [
      {
        occupation: 'Função',
        quantity: 2,
      },
      {
        occupation: 'Função',
        quantity: 2,
      },
    ],
  },
  {
    nome: 'Sala de energia',
    total: 1,
    cor: '#00caa2',
    data: [
      {
        occupation: 'Função',
        quantity: 1,
      },
    ],
  },
  {
    nome: 'Sala de energia',
    total: 6,
    cor: '#00caa2',
    data: [
      {
        occupation: 'Função',
        quantity: 6,
      },
    ],
  },
  {
    nome: 'Sala de energia',
    total: 8,
    cor: '#00caa2',
    data: [
      {
        occupation: 'Função',
        quantity: 8,
      },
    ],
  },
];
