import React from 'react';
import { DefaultIconProps } from './types';

export const TrackfyIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '22'}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M3.73246 1.40625H15.6257C16.5609 1.40625 17.3172 2.16215 17.3172 3.09244V18.8975C17.3172 19.8278 16.5609 20.5836 15.6257 20.5836H3.73246C2.79734 20.5836 2.04102 19.8278 2.04102 18.8975V3.09244C2.04102 2.16215 2.79734 1.40625 3.73246 1.40625Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
      />
      <path
        d="M14.8761 5.63444V5.63284C14.8766 5.40523 14.832 5.1797 14.7448 4.9692L14.7448 4.96912C14.6577 4.75867 14.5297 4.5673 14.3681 4.40606L14.8761 5.63444ZM14.8761 5.63444C14.8761 5.97736 14.7742 6.31274 14.583 6.59815L14.5829 6.59819M14.8761 5.63444L14.5829 6.59819M14.5829 6.59819C14.3917 6.88363 14.1198 7.1064 13.8013 7.23802L14.5829 6.59819ZM12.7936 7.33698L12.7937 7.337C13.1319 7.40413 13.4825 7.36967 13.8012 7.23805L12.7936 7.33698ZM12.7936 7.33698C12.4552 7.26985 12.1447 7.10422 11.9012 6.86133C11.6577 6.61841 11.4921 6.30914 11.425 5.97275L11.425 5.97274M12.7936 7.33698L11.425 5.97274M11.425 5.97274C11.3579 5.63639 11.3923 5.28769 11.524 4.97075L11.524 4.97069M11.425 5.97274L11.524 4.97069M11.524 4.97069C11.6556 4.65374 11.8786 4.38249 12.1652 4.19149L11.524 4.97069ZM13.1354 3.89844L13.1339 3.89844C12.7888 3.89844 12.4518 4.0005 12.1652 4.19148L13.1354 3.89844ZM13.1354 3.89844C13.3644 3.89796 13.5912 3.94259 13.8027 4.02973L13.1354 3.89844ZM13.8028 4.02974C14.0143 4.11688 14.2063 4.24478 14.368 4.40602L13.8028 4.02974Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
      />
      <path
        d="M11.6924 11.0391H14.3054C14.4728 11.0391 14.6052 11.1739 14.6052 11.3362V13.1646C14.6052 13.3269 14.4727 13.4618 14.3054 13.4618H11.6924C11.525 13.4618 11.3926 13.3269 11.3926 13.1646V11.3362C11.3926 11.1739 11.525 11.0391 11.6924 11.0391Z"
        fill="white"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
      />
      <path
        d="M4.81641 3.89844H7.42938C7.59672 3.89844 7.72917 4.03325 7.72917 4.19561V17.8529C7.72917 18.0152 7.59675 18.15 7.42938 18.15H4.81641C4.64903 18.15 4.5166 18.0152 4.5166 17.8529V4.19561C4.5166 4.03326 4.64905 3.89844 4.81641 3.89844Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
      />
    </svg>
  );
};
