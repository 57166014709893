import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MapAreasData, MonitoredAreasData, RealTimeLocations } from '../../../types/MonitoredData';
import { MonitoringDisplayMode } from '../components/Infleet/types';

type currentColabCountIndicator = {
  loading: boolean;
  value: string;
};

type AreasContextData = {
  monitoredAreas: MonitoredAreasData[];
  mapAreas: MapAreasData[];
  areaIcons: RealTimeLocations[];
  areaIconsByType: RealTimeLocations[];
  indicator: currentColabCountIndicator;
  projectSelected: number;
  updateMonitoredAreas: (areas: MonitoredAreasData[]) => void;
  updateMapAreas: (areas: MapAreasData[]) => void;
  updateAreaIcons: (areas: RealTimeLocations[]) => void;
  updateAreaIconsByType: (areas: RealTimeLocations[]) => void;
  setIndicator: (indicator: currentColabCountIndicator) => void;
  setProjectSelected: (value: number) => void;

  displayMode: MonitoringDisplayMode;
  toggleDisplayMode: () => void;
};

const AreasContext = createContext({} as AreasContextData);

type AreasProviderProps = {
  children: ReactNode;
};

export function AreasContextProvider({ children }: AreasProviderProps) {
  const mounted = useRef(false);
  const [mapAreas, setMapAreas] = useState<MapAreasData[]>([]);
  const [monitoredAreas, setMonitoredAreas] = useState<MonitoredAreasData[]>([]);
  const [areaIcons, setAreaIcons] = useState<RealTimeLocations[]>([]);
  const [areaIconsByType, setAreaIconsByType] = useState<RealTimeLocations[]>([]);
  const [projectSelected, setProjectSelected] = useState<number>(0);
  const [indicator, setIndicator] = useState<currentColabCountIndicator>({ loading: true, value: 'Carregando...' });
  const [displayMode, setDisplayMode] = useState<MonitoringDisplayMode>('collaborators');

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const updateMonitoredAreas = useCallback(
    (areas: MonitoredAreasData[]) => {
      if (mounted.current) {
        setIndicator({ loading: false, value: "0" });
        setMonitoredAreas(areas);
      }
    },
    [setMonitoredAreas]);

  const updateMapAreas = useCallback(
    (areas: MapAreasData[]) => {
      if (mounted.current) setMapAreas(areas);
    },
    [setMonitoredAreas]);

  const updateAreaIcons = useCallback(
    (areas: RealTimeLocations[]) => {
      if (mounted.current) {
        setAreaIcons(areas);
      }
    },
    [setAreaIcons]);

  const updateAreaIconsByType = useCallback(
    (areas: RealTimeLocations[]) => {
      if (mounted.current) {
        setAreaIconsByType(areas);
      }
    },
    [setAreaIconsByType]);

  const toggleDisplayMode = () =>
    setDisplayMode((prev) => (prev === 'collaborators' ? 'vehicles' : 'collaborators'));

  const value = {
    monitoredAreas,
    mapAreas,
    areaIcons,
    areaIconsByType,
    updateMonitoredAreas,
    updateMapAreas,
    updateAreaIcons,
    updateAreaIconsByType,
    indicator,
    setIndicator,
    projectSelected,
    setProjectSelected,
    displayMode,
    toggleDisplayMode,
  };

  return <AreasContext.Provider value={value}>{children}</AreasContext.Provider>;
}

export function useAreasContext() {
  const context = useContext(AreasContext);

  if (typeof context === 'undefined') {
    throw new Error('AreasContext must be used within an useAreasContext');
  }

  return context;
}
