import React from 'react';
import * as S from './styles';

interface Props {
  columns?: number;
  style?: React.CSSProperties;
}

const GridDisplay: React.FC<Props> = ({ columns = 12, style, children }) => {
  return (
    <S.Container columns={columns} style={style}>
      {children}
    </S.Container>
  );
};

interface CellProps {
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}

const GridCell: React.FC<CellProps> = ({ size = 6, style, children, className }) => {
    return (
        <S.Content size={size} style={style} className={className}>
            {children}
        </S.Content>
    );
}
  
export const Grid = {
    Display: GridDisplay,
    Cell: GridCell,
}