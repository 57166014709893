import React from 'react';

import { Form } from '../ReportContent';
import { PreviewMonitoredHH } from './PreviewMonitoredHH';
import { PreviewQuantityByCollaborator } from './PreviewQuantityByCollaborator';
import { PreviewInOut } from './PreviewInOut';
import { PreviewBulletin } from './PreviewBulletin';

import * as S from './styles';

interface Props {
  form: Form;
  profession: string;
}

export const PreviewContainer: React.FC<Props> = ({ form, profession }) => {
  const { report } = form;

  switch (report) {
    case 1:
      return (
        <S.PreviewContainer>
          <PreviewMonitoredHH form={form} profession={profession} />
        </S.PreviewContainer>
      );

    case 2:
      return (
        <S.PreviewContainer>
          <PreviewQuantityByCollaborator form={form} />
        </S.PreviewContainer>
      );

    case 4:
      return (
        <S.PreviewContainer>
          <PreviewInOut form={form} />
        </S.PreviewContainer>
      );

    case 5:
      return (
        <S.PreviewContainer>
          <PreviewBulletin form={form} />
        </S.PreviewContainer>
      );
      
    default:
      return <S.PreviewContainer></S.PreviewContainer>;
  }
};
