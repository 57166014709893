import React, { useMemo } from 'react';

import { LoadingContainer } from './LoadingContainer';
import { EmptyContainer } from './EmptyContainer';
import { ActionColumn, NewTableProps } from './types';
import { TableRow } from './TableRow';
import { TableSelect } from '../TableSelect';

import * as S from './styles';

export const TableWithActionColumn = <T extends unknown>({
  headers,
  rows,
  leftActionColumn,
  rightActionColumn,
  loading,
  emptyMessage,
  backgroundColors,
}: NewTableProps<T>) => {

  const isEmpty = useMemo(() => !rows.length, [rows]);

  const getLeftActionCollumn = (leftActionColumn: ActionColumn) => {
    if (leftActionColumn.selectAll)
      return <S.HeaderCell key={`select-all`}>
                <TableSelect
                  header
                  onSelect={(event) => leftActionColumn.selectAll?.onEvent(rows, event)}
                  selected={leftActionColumn.selectAll.selected}
                />
              </S.HeaderCell>;

    return <S.HeaderCell key={`not-select-all`}></S.HeaderCell>;
  }

  const getRightActionCollumn = (rightActionColumn: ActionColumn) => {
    if(rightActionColumn.selectAll)
      return <S.HeaderCell key={`select-all`}>
              <TableSelect header selected={rightActionColumn.selectAll.selected} 
                          onSelect={(event) => rightActionColumn.selectAll?.onEvent(rows, event)} />
            </S.HeaderCell>;

      return <S.HeaderCell key={`not-select-all`}></S.HeaderCell>;
  }
  
  if (loading)
    return <S.LoadingEmptyContainer>
            <LoadingContainer />
           </S.LoadingEmptyContainer>;

  if (isEmpty)
    return <S.LoadingEmptyContainer>  
            <EmptyContainer emptyMessage={emptyMessage} />
           </S.LoadingEmptyContainer>;

  return (
    <S.TableContainer>
      <S.Thead>
        <tr>
          {
            leftActionColumn ? (getLeftActionCollumn(leftActionColumn)) 
              : (<React.Fragment key={'select-all-left-fragment'} />) 
          }

          {headers.map((item, index: number) => (
            <React.Fragment key={`select-all-fragment-${index}-${item.title}`}>
              <S.HeaderCell key={`${index}-${item.title}`}>
                {item.title} {item.icon}
              </S.HeaderCell>
            </React.Fragment>
          ))}

          { 
            rightActionColumn ? (getRightActionCollumn(rightActionColumn)) 
              : (<React.Fragment key={'select-all-right-fragment'} />) 
          }
        </tr>
      </S.Thead>

      <S.Tbody>
        {rows.map((row, index) => {
          const backgroundColor = backgroundColors ? backgroundColors[index] : '';
          const isOdd = Boolean(index % 2);
          
          return (
            <TableRow {...row}
                      isOdd={isOdd}
                      background={backgroundColor}
                      leftActionColumn={leftActionColumn}
                      rightActionColumn={rightActionColumn}
                      key={'TableRow_' + row.id + '_' + index} 
            />
          );
        })}
      </S.Tbody>
    </S.TableContainer>
  );
}