import React from 'react';

import { Info } from '../../../../components/Info';
import { PageOpenIcon } from '../../../../Icons/PageOpen';

import * as S from './styles';

interface Props {
  title: string;
  icon: string;
  subtitle?: string;
  style?: React.CSSProperties;
  info?: string;
}
export const CardHeader: React.FC<Props> = ({ title, icon, subtitle, style, info }) => {
  return (
    <S.Header style={style}>
      <div
        dangerouslySetInnerHTML={{ __html: icon }}
        style={{ height: '100%', alignItems: 'center', display: 'flex' }}
      />
      <p>
        {title} {subtitle ? <span>{subtitle}</span> : <></>}
      </p>
      <Info eventName="" screen="" burndown={false} content={[{ content: info || '' }]} position="left" />
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <PageOpenIcon />
      </div>
    </S.Header>
  );
};
