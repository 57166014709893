import { SignIn, IAuthRequest, IAuthResponse, GetRefreshToken } from '.';
import api from '../../api';
import { getEndpoint } from '../TrackfyService/endpoints';

export const changePassAndSignIn: SignIn = async (request: IAuthRequest): Promise<IAuthResponse> => {
  const { user, password, recaptchaToken } = request;
  const body = { user, password, token: recaptchaToken };

  const endpoint = getEndpoint('register', false);
  const response = await api.post<IAuthResponse>(endpoint, body);
  return response.data;
}

export const signIn: SignIn = async (request: IAuthRequest): Promise<IAuthResponse> => {
  const { user, password, recaptchaToken } = request;
  const body = { user, password, token: recaptchaToken };

  const endpoint = getEndpoint('login', false);
  const response = await api.post<IAuthResponse>(endpoint, body);
  return response.data;
}

export const getRefreshToken: GetRefreshToken = async (refreshToken: string): Promise<string> => {
  const endpoint = getEndpoint('refreshToken', false);
  const response = await api.post(endpoint, { refreshToken });
  const { accessToken } = response.data;
  return accessToken;
}