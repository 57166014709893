import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { adminRowsMock } from '../mock';
import { AdminComponentList, AdminModalForm, adminModalFormBlankValues } from '../types';
import { RowItem } from '../components/Config/types';

type Props = {
  children: ReactNode;
};

type AdminContextProps = {
  cards: RowItem<AdminComponentList>[];
  setCards: Dispatch<SetStateAction<RowItem<AdminComponentList>[]>>;
  closeModal: () => void;
  modal: boolean;
  modalForm: AdminModalForm;
  setModalForm: Dispatch<SetStateAction<AdminModalForm>>;
  openModal: (name: string) => void;
  activeRow: RowItem<AdminComponentList> | undefined;
  setActiveRow: Dispatch<SetStateAction<RowItem<AdminComponentList> | undefined>>;
  onModalConfirm: () => void;
  isChanged: boolean;
  resetRows: () => void;
  saveRows: () => void;
};

const AdminContext = createContext<AdminContextProps>({} as AdminContextProps);

export function AdminContextProvider(props: Props) {
  const [cards, setCards] = useState<RowItem<AdminComponentList>[]>([]);
  const [modal, setModal] = useState(false);
  const [modalForm, setModalForm] = useState<AdminModalForm>(adminModalFormBlankValues);
  const [activeRow, setActiveRow] = useState<RowItem<AdminComponentList>>();
  const cardsRef = useRef<RowItem<AdminComponentList>[]>();
  const { children } = props;

  useEffect(() => {
    setCards(adminRowsMock);
    cardsRef.current = adminRowsMock;
  }, []);

  const isChanged = useMemo(() => {
    return (
      JSON.stringify(cards.map((v) => v.selected)) !== JSON.stringify(cardsRef.current?.map((v) => v.selected))
    );
  }, [cards, cardsRef.current]);

  const closeModal = () => {
    setModalForm(adminModalFormBlankValues);
    setModal(false);
  }

  const openModal = (name: string) => {
    const row = cards.find((v) => v.name === name);
    if (!row) {
      return;
    } else {
      setActiveRow(row);
      setModalForm({
        icon: row.icon,
        info: row.info || '',
      });
    }
    setModal(true);
  }

  const onModalConfirm = () => {
    const localCards = [...cards];
    if (!activeRow) {
      return;
    }
    const index = localCards.findIndex((item) => item.name === activeRow.name);
    if (index >= 0) {
      localCards.splice(index, 1, { ...activeRow, ...modalForm });
    }
    setCards(localCards);
    setActiveRow(localCards[index]);
    closeModal();
  }

  const resetRows = () => {
    if (cardsRef.current) setCards(cardsRef.current);
  }

  const saveRows = () => {
    setCards((prev) => {
      cardsRef.current = [...prev];
      return [...prev];
    });
  }

  const value: AdminContextProps = {
    cards,
    closeModal,
    modal,
    modalForm,
    openModal,
    setCards,
    setModalForm,
    activeRow,
    setActiveRow,
    onModalConfirm,
    isChanged,
    resetRows,
    saveRows,
  };

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
}

export function useAdminContext() {
  const context = useContext(AdminContext);

  if (typeof context === 'undefined') {
    throw new Error('AdminContext must be used within an useFaqContext');
  }

  return context;
}
