import React from 'react';
import { DefaultIconProps } from './types';

export const IntegrationIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M13.9334 15.5016C14.6652 15.5016 15.2584 14.9083 15.2584 14.1766C15.2584 13.4448 14.6652 12.8516 13.9334 12.8516C13.2016 12.8516 12.6084 13.4448 12.6084 14.1766C12.6084 14.9083 13.2016 15.5016 13.9334 15.5016Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.08379 7.15781C6.81557 7.15781 7.40879 6.56459 7.40879 5.83281C7.40879 5.10104 6.81557 4.50781 6.08379 4.50781C5.35201 4.50781 4.75879 5.10104 4.75879 5.83281C4.75879 6.56459 5.35201 7.15781 6.08379 7.15781Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5166 14.5591V13.7841C9.5166 13.3257 9.8916 12.9507 10.3499 12.9507C11.1499 12.9507 11.4749 12.3841 11.0749 11.6924C10.8416 11.2924 10.9833 10.7757 11.3833 10.5507L12.1416 10.1091C12.4916 9.90072 12.9416 10.0257 13.1499 10.3757L13.1999 10.4591C13.5999 11.1507 14.2499 11.1507 14.6499 10.4591L14.6999 10.3757C14.9083 10.0257 15.3583 9.90906 15.7083 10.1091L16.4666 10.5507C16.8666 10.7841 17.0083 11.2924 16.7749 11.6924C16.3749 12.3841 16.6999 12.9507 17.4999 12.9507C17.9583 12.9507 18.3333 13.3257 18.3333 13.7841V14.5591C18.3333 15.0174 17.9583 15.3924 17.4999 15.3924C16.6999 15.3924 16.3749 15.9591 16.7749 16.6507C17.0083 17.0507 16.8666 17.5674 16.4666 17.7924L15.7083 18.2341C15.3583 18.4424 14.9083 18.3174 14.6999 17.9674L14.6499 17.8841C14.2499 17.1924 13.5999 17.1924 13.1999 17.8841L13.1499 17.9674C12.9416 18.3174 12.4916 18.4341 12.1416 18.2341L11.3833 17.7924C10.9833 17.5591 10.8416 17.0507 11.0749 16.6507C11.4749 15.9591 11.1499 15.3924 10.3499 15.3924C9.8916 15.4007 9.5166 15.0257 9.5166 14.5591Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 6.2153V5.44031C1.66699 4.98197 2.04199 4.60697 2.50033 4.60697C3.30033 4.60697 3.62533 4.04031 3.22533 3.34864C2.99199 2.94864 3.13366 2.43197 3.53366 2.20697L4.29199 1.76531C4.64199 1.55697 5.09199 1.68197 5.30033 2.03197L5.35033 2.11531C5.75033 2.80697 6.40033 2.80697 6.80033 2.11531L6.85032 2.03197C7.05866 1.68197 7.50866 1.56531 7.85866 1.76531L8.61699 2.20697C9.01699 2.44031 9.15866 2.94864 8.92533 3.34864C8.52533 4.04031 8.85033 4.60697 9.65033 4.60697C10.1087 4.60697 10.4837 4.98197 10.4837 5.44031V6.2153C10.4837 6.67364 10.1087 7.04864 9.65033 7.04864C8.85033 7.04864 8.52533 7.61531 8.92533 8.30697C9.15866 8.70697 9.01699 9.22364 8.61699 9.44864L7.85866 9.89031C7.50866 10.0986 7.05866 9.97364 6.85032 9.62364L6.80033 9.54031C6.40033 8.84864 5.75033 8.84864 5.35033 9.54031L5.30033 9.62364C5.09199 9.97364 4.64199 10.0903 4.29199 9.89031L3.53366 9.44864C3.13366 9.21531 2.99199 8.70697 3.22533 8.30697C3.62533 7.61531 3.30033 7.04864 2.50033 7.04864C2.04199 7.05697 1.66699 6.68197 1.66699 6.2153Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 1.66667C15.725 1.66667 18.3333 4.275 18.3333 7.5L16.875 6.625"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50033 18.3359C4.27533 18.3359 1.66699 15.7276 1.66699 12.5026L3.12533 13.3776"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
