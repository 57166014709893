import React, { useEffect, useState } from 'react';

import { useAuthContext } from '../../stores/AuthContext';
import { useAuth } from '../../hooks/useAuth';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { SearchInput } from '../../components/SearchInput';
import { useFaQContext } from './context/FaqContext';

import * as S from '../../styles/structure';

const FaqOptions: React.FC = () => {
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  const {searchQuestion, setSearchQuestion} = useFaQContext()
  const [firstClick, setFirstClick] = useState(false);

  useEffect(() => mixpanelTrack(monitorAgent, 'F.A.Q', userName, authData, 'paginaAjuda'), []);

  const onClick = () => {
    if(!firstClick){
      setFirstClick(true)
      mixpanelTrack(monitorAgent, 'F.A.Q - Clicou no filtro de busca', userName, authData, 'Ajuda>Search')
    }
  }
  
  return (
    <S.SimpleContainer>
      <SearchInput
        id='Search'
        placeholder='Descreva brevemente sua dúvida'
        value={searchQuestion}
        searchValue={searchQuestion}
        setSearchValue={setSearchQuestion}
        onChanging={(value) => setSearchQuestion(value)}
		onClick={() => onClick()}
      />
    </S.SimpleContainer>
  );
};

export default FaqOptions;