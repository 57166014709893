import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  border-radius: 1.6rem;
  display: grid;
`;

interface AdminCardProps {
  fullSize?: boolean;
}

export const AdminCard = styled.div<AdminCardProps>`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: ${({ fullSize }) => (fullSize ? 'span 12 / span 12' : 'span 6 / span 6')};
  height: 100%;
  padding: 2.4rem;
  border-radius: 1.6rem;
`;

export const AdminCardHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 2.4rem;
  color: #0e1742;
  font-family: Work Sans;
  font-weight: 500;
  & div {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

export const AdminCardContent = styled.div`
  overflow-y: auto;
  max-height: 65rem;
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2.4rem;
`;
