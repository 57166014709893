import React from 'react';
import { DefaultIconProps } from './types';

const IconArrowUp: React.FC<DefaultIconProps> = ({
  fill,
  fillSecondColor,
  height,
  onClick,
  stroke,
  strokeWidth,
  style,
  width,
}) => (
  <svg
    fill={fill || 'none'}
    width={width || '24'}
    height={height || '24'}
    style={{ marginBottom: '-4px', ...style }}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M12 19V5"
      stroke={stroke || 'white'}
      strokeWidth={strokeWidth || '2'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 12L12 5L19 12"
      stroke={stroke || 'white'}
      strokeWidth={strokeWidth || '2'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconArrowUp;
