import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { HomeComponentList, ModalForm, modalFormBlankValues } from '../types';
import { homeRowsMock } from '../mock';
import { RowItem } from '../../Config/types';

type Props = {
  children: ReactNode;
};

type HomeAdminContextProps = {
  modalForm: ModalForm;
  modalTitle: string;
  changeModalTitle: (title: string) => void;
  cards: RowItem<HomeComponentList>[];
  setCards: Dispatch<SetStateAction<RowItem<HomeComponentList>[]>>;
  modal: boolean;
  openModal: (name: string) => void;
  closeModal: () => void;
  setModalForm: Dispatch<SetStateAction<ModalForm>>;
  activeRow: RowItem<HomeComponentList> | undefined;
  setActiveRow: Dispatch<SetStateAction<RowItem<HomeComponentList> | undefined>>;
  onModalConfirm: () => void;
  isChanged: boolean;
  resetRows: () => void;
  initialCards: RowItem<HomeComponentList>[];
};

const HomeAdminContext = createContext<HomeAdminContextProps>({} as HomeAdminContextProps);

export function HomeAdminContextProvider(props: Props) {
  const [cards, setCards] = useState<RowItem<HomeComponentList>[]>(homeRowsMock);
  const [initialCards, setInitialCards] = useState<RowItem<HomeComponentList>[]>(homeRowsMock);
  const [modal, setModal] = useState(false);
  const [modalForm, setModalForm] = useState<ModalForm>(modalFormBlankValues);
  const [modalTitle, setModalTitle] = useState('');
  const [activeRow, setActiveRow] = useState<RowItem<HomeComponentList> | undefined>();

  const { children } = props;

  const isChanged = useMemo(() => {
    return JSON.stringify(cards.map((v) => v.selected)) !== JSON.stringify(initialCards.map((v) => v.selected));
  }, [cards]);

  const closeModal = () => {
    setModalForm(modalFormBlankValues);
    setModal(false);
  };

  const openModal = (name: string) => {
    const row = cards.find((v) => v.name === name);
    if (!row) {
      return;
    } else {
      changeModalTitle(name);
      setModalForm({
        icon: row.icon,
        info: row.info || '',
      });
    }
    setModal(true);
  };

  const changeModalTitle = (value: string) => {
    setModalTitle(value);
  };

  const onModalConfirm = () => {
    const localCards = [...cards];
    if (!activeRow) {
      return;
    }
    const index = localCards.findIndex((item) => item.name === activeRow.name);
    if (index >= 0) {
      localCards.splice(index, 1, { ...activeRow, ...modalForm });
    }
    setCards(localCards);
    setActiveRow(localCards[index]);
    closeModal();
  };

  const resetRows = () => {
    setCards(initialCards);
  };

  const value: HomeAdminContextProps = {
    cards,
    closeModal,
    modal,
    modalForm,
    modalTitle,
    openModal,
    setCards,
    changeModalTitle,
    setModalForm,
    activeRow,
    setActiveRow,
    onModalConfirm,
    isChanged,
    resetRows,
    initialCards,
  };

  return <HomeAdminContext.Provider value={value}>{children}</HomeAdminContext.Provider>;
}

export function useHomeAdminContext() {
  const context = useContext(HomeAdminContext);

  if (typeof context === 'undefined') {
    throw new Error('HomeAdminContext must be used within an useFaqContext');
  }

  return context;
}
