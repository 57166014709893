import { RouteKey } from '../enum';
import { DailySummary } from '../pages/DailySummary';
import { Indicators } from '../pages/Indicators';
import { AgileanIntegration } from '../pages/AgileanIntegration';
import { AgileanCurves } from '../pages/AgileanCurves';
import { Login } from '../pages/Login';
import { Monitoring } from '../pages/Monitoring';
import { Reports } from '../pages/Reports';
import { MachinesSummary } from '../pages/MachinesSummary';
import { Emergency } from '../pages/Emergency';
import { Insights } from '../pages/Insights';
import { TextInsights } from '../pages/TextInsights';
import { LinkCollaborators } from '../pages/LinkCollaborator';
import { Displacement } from '../pages/Displacement';
import { RealTime } from '../pages/RealTime';
import { Calibration } from '../pages/Calibration';
import { OnePage } from '../pages/OnePage';
import { Support } from '../pages/Support';
import { Incidents } from '../pages/Incidents';
import { ScannerStatus } from '../pages/ScannerStatus';
import { Users } from '../pages/Users';
import { NewPassword } from '../pages/NewPassword';
import { ClientCompanyRegistration } from '../pages/ClientCompanyRegistration';
import { HomePage } from '../pages/Home'
import { InfleetPage } from '../pages/Infleet';
import { SystemLogs } from '../pages/SystemLogs';
import { ResourcesMetricsSummary } from '../pages/ResourcesMetricsSummary';
import { GpsLocation } from '../pages/GpsLocation';
import { GpsRoute } from '../pages/GPSRoute';
import { AdminPage } from '../pages/Admin';

export const routesConfig = {
  [RouteKey.login]: Login,
  [RouteKey.monitoramento]: Monitoring,
  [RouteKey.indicadores]: Indicators,
  [RouteKey.planejamento]: AgileanIntegration,
  [RouteKey.curvas]: AgileanCurves,
  [RouteKey.analises]: Insights,
  [RouteKey.relatorios]: Reports,
  [RouteKey.sumario]: DailySummary,
  [RouteKey.sumariodemaquinas]: MachinesSummary,
  [RouteKey.emergencia]: Emergency,
  [RouteKey.informes]: TextInsights,
  [RouteKey.vinculacaodecolaboradores]: LinkCollaborators,
  [RouteKey.deslocamento]: Displacement,
  [RouteKey.temporeal]: RealTime,
  [RouteKey.calibracao]: Calibration,
  [RouteKey.onepage]: OnePage,
  [RouteKey.suporte]: Support,
  [RouteKey.incidentes]: Incidents,
  [RouteKey.scannerstatus]: ScannerStatus,
  [RouteKey.usuarios]: Users,
  [RouteKey.novasenha]: NewPassword,
  [RouteKey.cadastrocliente]: ClientCompanyRegistration,
  [RouteKey.telahome]: HomePage, 
  [RouteKey.infleet]: InfleetPage,
  [RouteKey.logs]: SystemLogs,
  [RouteKey.sumariorecursos]: ResourcesMetricsSummary,
  [RouteKey.gps]: GpsLocation,
  [RouteKey.gpsroute]: GpsRoute,
  [RouteKey.admin]: AdminPage,
}
