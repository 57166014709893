import React, { useEffect } from 'react';

import { useAuthContext } from '../../../stores/AuthContext';
import { DateRangePicker } from '../../../components';
import { getFollowingFriday } from '../../../utils/DateTime';
import { mixpanelTrack } from '../../../services/SystemMonitor';
import { useAuth } from '../../../hooks/useAuth';
import CompanyMultiSelect from '../../filters/CompanyMultiSelect';
import ProfessionMultiSelect from '../../filters/ProfessionMultiSelect';
import { FilterIconVuesax } from '../../../Icons/Filter-Vuesax';
import { useGeneralMovimentContext } from '../context/GeneralMovimentContext';
import Calendar from '../../../assets/calendar_alternative.svg';
import { generalMovimentData } from './mock';
import { XlsxExportButton } from '../../../components/Buttons/XlsxExportButton';
import { UsersIconVuesax } from '../../../Icons/Users-Vuesax';
import { AlertIconVuesax } from '../../../Icons/Alert-Vuesax';
import { useGeneralMoviment } from './hooks/useGeneralMoviment';

import * as S from './styles';

export const GeneralMovimentExport: React.FC = () => {
  const { authData, monitorAgent } = useAuthContext();
  const { exportGeneralDataToXlsx, getDataToExport } = useGeneralMoviment();
  const compIds: string = authData ? authData.grpCompanies : 't';
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  const {
    companySelected,
    professionSelected,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
    date,
    setDate,
    initialDate,
    finalDate,
  } = useGeneralMovimentContext();

  useEffect(() => mixpanelTrack(monitorAgent, 'Indicadores - Movimentação', userName, authData, "GeneralMovement"), []);

  return (
      <S.MultiOptionsCard>
        <S.OptionsCardTitle>
          <UsersIconVuesax />
          Movimentação Geral
          <AlertIconVuesax />
        </S.OptionsCardTitle>
        <S.OptionsContent>
          <FilterIconVuesax width={40} height={40} />

          <S.SimpleContainer>
            <DateRangePicker
              updatePeriod={updatePeriod}
              screen="Indicadores"
              maxDate={getFollowingFriday(new Date())}
              component="Movimentação -"
              selectRange
              showToday
              loadDate={new Date()}
              placeholder="Selecione a data (Obrigatório)"
              customIcon={Calendar}
              limitDateRange={7}
            />
          </S.SimpleContainer>
          {compIds != 'a' && !compIds.includes(',') ? (
            <></>
          ) : (
            <S.SimpleContainer>
              <CompanyMultiSelect
                itemsSelected={companySelected}
                setItemsSelected={setCompanySelected}
                screen={'Indicadores - Movimentação'}
                eventName={'indicMoviment'}
              />
            </S.SimpleContainer>
          )}
          <S.SimpleContainer>
            <ProfessionMultiSelect
              itemsSelected={professionSelected}
              setItemsSelected={setProfessionSelected}
              screen={'Indicadores - Movimentação'}
              eventName={'indicMoviment'}
            />
          </S.SimpleContainer>
          <XlsxExportButton
            disabled={!date}
            fileName="Movimentação Geral"
            exportAsync={async () => {
              const data = await getDataToExport(professionSelected, companySelected, initialDate, finalDate);
              return exportGeneralDataToXlsx(data);
            }}
            data={generalMovimentData}
            screen={'Indicadores - Movimentação - Inidcador de Movimentação'}
            eventName={'Indic>abaMovGeneralMovement'}
          />

          {/* <div><GeneratePdf /></div> */}
        </S.OptionsContent>
      </S.MultiOptionsCard>
  );
};
