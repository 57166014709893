import React, { useEffect, useMemo, useState } from 'react';

import { Form } from '../../ReportContent';
import { PreviewHeader } from './../PreviewHeader';
import { Grid } from '../../../../../components/Grid';
import { useBulletin } from '../../../hooks/useBulletin';
import { BulletinInfos } from '../../../types';
import { DelayTable } from './DelayTable';
import { DisplacementChart } from './DisplacementByFirstWeek';
import { DisplacementByLastWeek } from './DisplacementByLastWeek';
import { ResourcesAmountChart } from './ResourcesAmount';
import { IconLoading } from '../../../../../components/IconLoading';
import { ClockArrowUpIcon } from '../../../../../Icons/ClockArrowUp';
import { TriangleIcon } from '../../../../../Icons/Triangle';
import { ArrowUpwardIcon } from '../../../../../Icons/ArrowUpward';
import { UsersIconVuesax } from '../../../../../Icons/Users-Vuesax';
import { AlertIconVuesax } from '../../../../../Icons/Alert-Vuesax';
import { CalendarIconVuesax } from '../../../../../Icons/Calendar-Vuesax';
import { CalendarRepeat } from '../../../../../Icons/CalendarRepeat';
import IconClock from '../../../../../Icons/Clock';

import * as S from '../styles';
import '../styles.css';

interface Props {
  form: Form;
}

interface CurrentAreaHours {
    portariaEntrada: number;
    refeitorioEntrada: number;
    gvsEntrada: number;
    gvsSaida: number;
    portariaSaida: number;
    portariaToGV: string;
    GvToGvSaida: string;
    GvSaidaToPortaria: string;
}

const convertTimeStringToMinutes = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return Number(`${hours}.${minutes}`);
}
  
const getDisplacement = (time1: string, time2: string) => {
    const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
    const [hours2, minutes2, seconds2] = time2.split(':').map(Number);
  
    const totalMinutes1 = hours1 * 60 + minutes1 + seconds1 / 60;
    const totalMinutes2 = hours2 * 60 + minutes2 + seconds2 / 60;
  
    const diffMinutes = totalMinutes2 - totalMinutes1;
  
    const hours = Math.floor(Math.abs(diffMinutes) / 60);
    const minutes = Math.floor(Math.abs(diffMinutes) % 60);
  
    if (hours === 0)
      return `${minutes} minutos`;
  
    return `${hours} ${hours > 1 ? 'horas' : 'hora'} e ${minutes} ${minutes > 1 ? 'minutos' : 'minuto'}`;
}
  
const getDifferenceInMinutes = (timeString1: string, timeString2: string) => {
    const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
    const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
  
    const totalMinutes1 = hours1 * 60 + minutes1 + seconds1 / 60;
    const totalMinutes2 = hours2 * 60 + minutes2 + seconds2 / 60;
  
    const diffMinutes = Math.abs(totalMinutes1 - totalMinutes2);
  
    const hours = Math.floor(diffMinutes / 60);
    const minutes = Math.floor(diffMinutes % 60);
  
    if (hours === 0)
      return `${minutes.toString()}min`;
  
    return `${hours.toString().padStart(2, '0')}h e ${minutes.toString().padStart(2, '0')}min`;
}
  
const getLastSundayFormatted = () => {
    const today = new Date();
    //today.setDate(today.getDate() - 11);
    const lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() - today.getDay());
    return new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
      .format(lastSunday)
      .replaceAll('/', '-');
}
  
const calculateWeeksSincePilotStart = () => {
    const today = new Date();
    //today.setDate(today.getDate() - 11);

    const lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() - today.getDay());

    const startDate = new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .format(lastSunday);

    const [day, month, year] = startDate.split('/').map(Number);
    const referenceDate = new Date(2025, 0, 13);
    referenceDate.setDate(referenceDate.getDate() - referenceDate.getDay());

    const inputDate = new Date(year, month - 1, day);
    const diffTime = Math.abs(referenceDate.getTime() - inputDate.getTime());
    const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));
  
    return diffWeeks;
}
  
const getLastMondayFormatted = () => {
    const today = new Date();
    //today.setDate(today.getDate() - 11);
    const lastMonday = new Date(today);
    lastMonday.setDate(today.getDate() - today.getDay() - 6);

    return new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
      .format(lastMonday)
      .replaceAll('/', '-');
}

const getCurrentHoursCategoryIndex = (index:number, currentAreaHours?: CurrentAreaHours) => {
    switch(index){
        case 2:
            return currentAreaHours?.portariaToGV;
        case 3:
            return currentAreaHours?.GvToGvSaida;
        case 4:
            return currentAreaHours?.GvSaidaToPortaria;
        default:
            return '';
    }
}

export const PreviewBulletin: React.FC<Props> = ({ form }) => {
  const { period } = form;
  const [bulletinInfo, setBulletinInfo] = useState<BulletinInfos>();
  const { getBulletinInfos } = useBulletin();

  useEffect(() => {
    (async () => {
      const data = await getBulletinInfos();
      setBulletinInfo(data);
    })();
  }, []);

  const currentAreaHours = useMemo(() => {
    if (!bulletinInfo) return;

    return {
        portariaEntrada: convertTimeStringToMinutes(bulletinInfo.data.entranceMediaPortariaCurrentWeek),
        refeitorioEntrada: convertTimeStringToMinutes(bulletinInfo.data.entranceMediaRefeitoriosCurrentWeek),
        gvsEntrada: convertTimeStringToMinutes(bulletinInfo.data.entranceMediaGVsCurrentWeek),
        gvsSaida: convertTimeStringToMinutes(bulletinInfo.data.exitMediaGVsCurrentWeek),
        portariaSaida: convertTimeStringToMinutes(bulletinInfo.data.exitMediaPortariaCurrentWeek),
        portariaToGV: getDisplacement(bulletinInfo.data.entranceMediaPortariaCurrentWeek,
                                      bulletinInfo.data.entranceMediaGVsCurrentWeek),
        GvToGvSaida: getDisplacement(bulletinInfo.data.entranceMediaGVsCurrentWeek,
                                     bulletinInfo.data.exitMediaGVsCurrentWeek),
        GvSaidaToPortaria: getDisplacement(bulletinInfo.data.exitMediaGVsCurrentWeek,
                                           bulletinInfo.data.exitMediaPortariaCurrentWeek),
    }
  }, [bulletinInfo]);

  const calculateDelayVariation = useMemo(() => {
    if (!bulletinInfo) return '0%';

    const current = parseFloat(bulletinInfo.data.LateEmployessCurrentWeekPercentage.replace('%', ''));
    const past = parseFloat(bulletinInfo.data.LateEmployessLastWeekPercentage.replace('%', ''));

    const variation = current - past;
    const isPositive = variation >= 0;

    const formattedVariation = Number.isInteger(variation) ? variation.toString() : variation.toFixed(1);
    return `${isPositive ? '+' : ''}${formattedVariation}%`;
  }, [bulletinInfo]);

  const arrCurrentWeek = useMemo(() => {
    if (!bulletinInfo) return [];
    return [
      bulletinInfo.data.entranceMediaPortariaCurrentWeek,
      bulletinInfo.data.entranceMediaRefeitoriosCurrentWeek,
      bulletinInfo.data.entranceMediaGVsCurrentWeek,
      bulletinInfo.data.exitMediaGVsCurrentWeek,
      bulletinInfo.data.exitMediaPortariaCurrentWeek,
    ];
  }, [bulletinInfo]);

  const arrFirstWeek = useMemo(() => {
    if (!bulletinInfo) return [];
    return [
      bulletinInfo.data.entranceMediaPortariaFirstWeek,
      bulletinInfo.data.entranceMediaRefeitoriosFirstWeek,
      bulletinInfo.data.entranceMediaGVsFirstWeek,
      bulletinInfo.data.exitMediaGVsFirstWeek,
      bulletinInfo.data.exitMediaPortariaFirstWeek,
    ];
  }, [bulletinInfo]);

  const arrLastWeek = useMemo(() => {
    if (!bulletinInfo) return [];
    return [
      bulletinInfo.data.entranceMediaPortariaLastWeek,
      bulletinInfo.data.entranceMediaRefeitoriosLastWeek,
      bulletinInfo.data.entranceMediaGVsLastWeek,
      bulletinInfo.data.exitMediaGVsLastWeek,
      bulletinInfo.data.exitMediaPortariaLastWeek,
    ];
  }, [bulletinInfo]);

  if (!bulletinInfo || bulletinInfo.loading) return <IconLoading />;

  return (
    <>
      <PreviewHeader period={period} title="Boletim Trackfy" isBulletin />
      <Grid.Display style={{ width: '100%', gap: '1.2rem' }}>
        <Grid.Cell size={4}>
          <S.BulletinCard>
            <header>Piloto Trackfy</header>
            <main>Semana {calculateWeeksSincePilotStart()}</main>
          </S.BulletinCard>
        </Grid.Cell>
        
        <Grid.Cell size={4}>
          <S.BulletinCard>
            <header>Início</header>
            <main>{getLastMondayFormatted()}</main>
          </S.BulletinCard>
        </Grid.Cell>
        
        <Grid.Cell size={4}>
          <S.BulletinCard>
            <header>Fim</header>
            <main>{getLastSundayFormatted()}</main>
          </S.BulletinCard>
        </Grid.Cell>
        
        <Grid.Cell
          size={12}
          style={{ border: '1px solid #d6d6d6', borderRadius: '.8rem', padding: '2.2rem' }}
          className="pagebreakAfter"
        >
          <S.BulletinChartHeader>
            <ClockArrowUpIcon />
            Deslocamentos e Permanência (Variação em relação a Primeira semana)
          </S.BulletinChartHeader>
          <div style={{ position: 'relative' }}>
            <DisplacementChart data={bulletinInfo.data} />

            {arrCurrentWeek.map((v, index) => (
              <S.ChartVariantInfo
                key={`${index}_${v}`}
                top={`${index * 59 + 15}px`}
                variant={
                  convertTimeStringToMinutes(v) < convertTimeStringToMinutes(arrFirstWeek[index])
                    ? 'positive'
                    : 'negative'
                }
              >
                {index > 1 ? (
                  <p>
                    <TriangleIcon />
                    {getCurrentHoursCategoryIndex(index, currentAreaHours)}
                  </p>
                ) : (
                  <></>
                )}
                <p>
                  {convertTimeStringToMinutes(v) < convertTimeStringToMinutes(arrFirstWeek[index]) ? (
                    <ArrowUpwardIcon height={14} width={14} />
                  ) : (
                    <ArrowUpwardIcon
                      height={14}
                      width={14}
                      style={{ transform: 'rotate(180deg)' }}
                      fill="#E74011"
                    />
                  )}
                  {getDifferenceInMinutes(v, arrFirstWeek[index])}
                </p>
              </S.ChartVariantInfo>
            ))}
          </div>
        </Grid.Cell>
        
        <Grid.Cell
          size={12}
          style={{ border: '1px solid #d6d6d6', borderRadius: '.8rem', padding: '2.2rem' }}
          className="firstElement pagebreakAfter"
        >
          <S.BulletinChartHeader>
            <ClockArrowUpIcon />
            Deslocamentos e Permanência (Variação em relação a Última semana)
          </S.BulletinChartHeader>
          <div style={{ position: 'relative' }}>
            <DisplacementByLastWeek data={bulletinInfo.data} />
            {arrCurrentWeek.map((v, index) => (
              <S.ChartVariantInfo
                key={`${index}_${v}`}
                top={`${index * 59 + 15}px`}
                variant={
                  convertTimeStringToMinutes(v) < convertTimeStringToMinutes(arrLastWeek[index])
                    ? 'positive'
                    : 'negative'
                }
              >
                <p>
                  {index > 1 && <TriangleIcon />}
                  {getCurrentHoursCategoryIndex(index, currentAreaHours)}
                </p>
                <p>
                  {convertTimeStringToMinutes(v) < convertTimeStringToMinutes(arrLastWeek[index]) ? (
                    <ArrowUpwardIcon height={14} width={14} />
                  ) : (
                    <ArrowUpwardIcon
                      height={14}
                      width={14}
                      style={{ transform: 'rotate(180deg)' }}
                      fill="#E74011"
                    />
                  )}

                  {getDifferenceInMinutes(v, arrLastWeek[index])}
                </p>
              </S.ChartVariantInfo>
            ))}
          </div>
        </Grid.Cell>
        
        <Grid.Cell size={12} className="firstElement pageBreak pagebreakAfter">
          <S.BulletinChartHeader>
            <UsersIconVuesax />
            Quantidade de Recursos no Período
            <AlertIconVuesax />
            <S.BulletinLegendContainer>
              <S.BulletinLegendItem>
                <S.BulletinLegendDot color="#2f4cdd" />
                <span>Recursos Disponíveis</span>
              </S.BulletinLegendItem>
              <S.BulletinLegendItem>
                <S.BulletinLegendDot color="#E74011" />
                <span>Recursos Registrados</span>
              </S.BulletinLegendItem>
            </S.BulletinLegendContainer>
          </S.BulletinChartHeader>
          <ResourcesAmountChart data={bulletinInfo.data.quantityofResources.series} />
        </Grid.Cell>
        
        <Grid.Cell
          size={12}
          className="firstElement"
          style={{
            border: '1px solid #d6d6d6',
            borderRadius: '.8rem',
            padding: '2.2rem',
            marginTop: '100px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '2.2rem',
          }}
        >
          <S.BulletinChartHeader>
            <IconClock />
            Registros diários de atraso em áreas produtivas (Menos de 90% do efetivo às 08:00)
          </S.BulletinChartHeader>
          <S.DelayCardsContainer>
            <S.DelayCard color="#0573FA">
              <span>
                <CalendarIconVuesax />
                <p>Semana atual</p>
              </span>
              <h1>{bulletinInfo.data.LateEmployessCurrentWeekPercentage}</h1>
            </S.DelayCard>
            <S.DelayCard color="#0573FA">
              <span>
                <CalendarRepeat />
                Semana anterior
              </span>
              <h1>{bulletinInfo.data.LateEmployessLastWeekPercentage}</h1>
            </S.DelayCard>
            <S.DelayCard color={calculateDelayVariation.includes('+') ? '#E74011' : '#00C48C'}>
              <span>
                <TriangleIcon width={24} height={24} />
                Variação
              </span>
              <h1>{calculateDelayVariation.replace('+', '').replace('-', '')}</h1>
            </S.DelayCard>
          </S.DelayCardsContainer>
          <DelayTable data={bulletinInfo.data.DelayedEmployees} />
        </Grid.Cell>
      </Grid.Display>
    </>
  );
}

  /*const pastWeekAreaHours = useMemo(() => {
    if (!bulletinInfo) return;

    return {
        portariaEntrada: convertTimeStringToMinutes(bulletinInfo.data.entranceMediaPortariaLastWeek),
        refeitorioEntrada: convertTimeStringToMinutes(bulletinInfo.data.entranceMediaRefeitoriosLastWeek),
        gvsEntrada: convertTimeStringToMinutes(bulletinInfo.data.entranceMediaGVsLastWeek),
        gvsSaida: convertTimeStringToMinutes(bulletinInfo.data.exitMediaGVsLastWeek),
        portariaSaida: convertTimeStringToMinutes(bulletinInfo.data.exitMediaPortariaLastWeek),
    }
  }, [bulletinInfo]);

  const firstWeekAreaHours = useMemo(() => {
    if (!bulletinInfo) return;

    return {
        portariaEntrada: convertTimeStringToMinutes(bulletinInfo.data.entranceMediaPortariaFirstWeek),
        refeitorioEntrada: convertTimeStringToMinutes(bulletinInfo.data.entranceMediaRefeitoriosFirstWeek),
        gvsEntrada: convertTimeStringToMinutes(bulletinInfo.data.entranceMediaGVsFirstWeek),
    };
  }, [bulletinInfo]);*/