import styled from 'styled-components';

export interface TitleContainerProps {
  flexDirection?: 'row' | 'column';
  hiddenOnPreview?: boolean;
  marginTop?: string;
  justifyContent?: string;
}

export interface TimelinePointProps {
  count: number;
}

export const PreviewContainer = styled.div`
  position: relative; 
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2.4rem;
  max-height: 75rem;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    pointer-events: none;

    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.7) 35%,
      rgba(255, 255, 255, 0.9) 65%,
      rgba(255, 255, 255, 1) 100%
    );
  }


  @media print {
    padding-top: 7rem;
    max-height: none;
    overflow: visible;

    &::after {
      display: none;
    }
  }
`;

export const PreviewHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: Work Sans;
  margin-bottom: 3.2rem;
  @media print {
    display: none;
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  color: #2f4cdd;
  margin: 0;
`;

export const HeaderDate = styled.span`
  text-align: center;
  line-height: 2.4rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: #808080;
`;

export const PreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Work Sans;
  width: 100%;
  gap: 3.2rem;
`;

export const TitleContainer = styled.div<TitleContainerProps>`
  display: ${({ hiddenOnPreview }) => (hiddenOnPreview ? 'none' : 'flex')};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  @media print {
    display: flex !important;
    margin-top: ${({ marginTop }) => marginTop || '10rem'};
  }
`;

export const ContentTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #808080;
`;
export const ContentSubtitle = styled.p`
  color: #808080;
  font-size: 1.2rem !important;
  font-weight: 400;
`;

export const TimelineContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  @media print {
    //margin-bottom: 10rem;
  }
`;

export const TimelineCount = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  color: #008ae5;
  & span {
    font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
    color: #008ae5;
  }
`;

export const TimelineLine = styled.span`
  background: #999999;
  width: 100%;
  height: 2px;
  display: flex;
  flex-grow: 1;
`;

export const TimelineData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
  & div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TimelinePoint = styled.span<TimelinePointProps>`
  display: block;
  width: 10px;
  height: 7px;
  border-radius: 100%;
  background: ${({ count }) => (count ? '#008ae5' : '#999999')};
  margin: 0 4px;
`;

export const TimelineHours = styled.span`
  margin-top: 5px;
  color: #999999;
  font: normal 500 0.8rem/1.5 Work Sans, sans-serif;
`;

export const PreviewInOutHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: Work Sans;
  & h1 {
    font-size: 1.6rem;
    font-weight: 700;
    color: #808080;
    & span {
      font-weight: 500;
      color: #2f4cdd;
    }
  }
`;

export const TitleRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const BulletinCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 0.8rem;
  font-family: Work Sans;
  border: 1px solid #d6d6d6;
  & > header {
    border-radius: 0.8rem 0.8rem 0 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2.2rem;
    color: #ffffff;
    background-color: #2f4cdd;
    font-weight: 700;
  }
  & > main {
    display: flex;
    padding: 2.2rem;
    font-weight: 700;
    color: #616161;
    font-size: 1.8rem;
  }
`;

export const BulletinChartHeader = styled.div`
  display: flex;
  margin-bottom: 1.6rem;
  width: 100%;
  align-items: center;
  gap: 0.8rem;
  color: #707070;
  font-family: Work Sans;
  font-weight: 700;
`;

interface ChartVariantInfoProps {
  top: string;
  variant: 'positive' | 'negative';
}

export const ChartVariantInfo = styled.span<ChartVariantInfoProps>`
  position: absolute;
  top: ${({ top }) => top};
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.8rem;
  border: 1px solid #d6d6d6;
  border-radius: 0.8rem;
  & p {
    margin: 0;
    display: flex;
    font-family: Work Sans;
    font-size: 1.2rem;
    font-weight: 700;
    color: #707070;
    gap: 0.8rem;
  }
  &:last-child {
    color: ${({ variant }) => (variant === 'positive' ? '#2B9E30' : '#EC6641')};
  }
`;

export const BulletinLegendContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 1.6rem;
  align-items: center;
`;

export const BulletinLegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const BulletinLegendDot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

interface DelayCardProps {
  color: string;
}

export const DelayCardsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2.4rem;
  justify-content: space-between;
  align-items: center;
`;

export const DelayCard = styled.div<DelayCardProps>`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 0.8rem;
  padding: 1.4rem;
  font-family: Work Sans;
  & span {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.2rem;
    color: #707070;
    gap: 0.8rem;
  }
  & h1 {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 0;
    color: ${({ color }) => color};
  }
`;