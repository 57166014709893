import React from 'react';

import { ActionColumn, TableRow } from './types';
import { TrashIcon } from '../../Icons/Trash-Vuesax';
import { EditIcon } from '../../Icons/Edit';
import { TableSelect } from '../TableSelect';

import * as S from './styles';

interface Props extends ActionColumn {
  row: TableRow<any>;
}

export const ActionColumnTable: React.FC<Props> = ({ delete: deleteAction, edit, select, row }) => {
  return (
    <td>
      <S.ActionColumnButtons
        fullHeight={select && !edit && !deleteAction}
        noMargin={select && !edit && !deleteAction}
      >
        {edit ? (
          <S.ActionColumnButton onClick={(event) => edit.onEvent(row, event)}>
            {edit.icon || <EditIcon width={16} height={16} fill="#2F4CDD" />}
          </S.ActionColumnButton>
        ) : (
          <></>
        )}
        {deleteAction ? (
          <S.ActionColumnButton onClick={(event) => deleteAction.onEvent(row, event)}>
            {deleteAction.icon || <TrashIcon />}
          </S.ActionColumnButton>
        ) : (
          <></>
        )}
        {select ? (
          <TableSelect
            onSelect={(event) => select.onEvent(row, event)}
            selected={typeof select.selected === 'boolean' ? select.selected : select.selected(row.id)}
          />
        ) : (
          <></>
        )}
      </S.ActionColumnButtons>
    </td>
  );
};