import React from 'react';
import { DefaultIconProps } from './types';

export const BoxesIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M16.5837 8.7474L3.41699 8.7474C2.16699 8.7474 1.66699 8.21406 1.66699 6.88906L1.66699 3.5224C1.66699 2.1974 2.16699 1.66406 3.41699 1.66406L16.5837 1.66406C17.8337 1.66406 18.3337 2.1974 18.3337 3.5224V6.88906C18.3337 8.21406 17.8337 8.7474 16.5837 8.7474Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.08366 18.3333H3.41699C2.16699 18.3333 1.66699 17.8 1.66699 16.475L1.66699 13.1083C1.66699 11.7833 2.16699 11.25 3.41699 11.25L9.08366 11.25C10.3337 11.25 10.8337 11.7833 10.8337 13.1083V16.475C10.8337 17.8 10.3337 18.3333 9.08366 18.3333Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.583 18.3333H15.083C13.833 18.3333 13.333 17.8 13.333 16.475L13.333 13.1083C13.333 11.7833 13.833 11.25 15.083 11.25L16.583 11.25C17.833 11.25 18.333 11.7833 18.333 13.1083V16.475C18.333 17.8 17.833 18.3333 16.583 18.3333Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
