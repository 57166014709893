import axios from 'axios';
import { useAuth } from '../../../hooks';
import { changePageAccess as change, changeComponents } from '../../../services/api/TrackfyService';

interface AdminCard {
  name: string;
  icon: string;
  selected?: boolean;
  disabled?: boolean;
  path?: string;
}

type PropertyKey = keyof AdminCard;

export const useAdmin = () => {
  const { getGroupId } = useAuth();
  const getRowProperty = <T extends PropertyKey>(cards: AdminCard[], property: T): Array<AdminCard[T]> => {
    return cards.filter((card) => card.selected).map((card) => card[property]);
  };
  const changePageAccess = async (pages: string[], icons: string[]) => {
    const source = axios.CancelToken.source();
    const result = await change(pages, icons, getGroupId(), source.token);
    return result;
  };

  const changeComponentAccess = async (components: string[], icons: string[]) => {
    const source = axios.CancelToken.source();
    const result = await changeComponents(components, icons, getGroupId(), source.token);
    return result.data;
  };

  return {
    changePageAccess,
    getRowProperty,
    changeComponentAccess,
  };
};
