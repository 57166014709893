import React from 'react';
import * as S from './styles';

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  selected: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const Switch: React.FC<Props> = ({ onClick, selected, style, disabled }) => {
  return (
    <S.Container
      disabled={disabled}
      style={style}
      selected={selected}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }}
    >
      <div></div>
    </S.Container>
  );
};
