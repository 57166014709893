import React from 'react';
import { DefaultIconProps } from './types';
export const TagRightIcon: React.FC<DefaultIconProps> = ({ fill, width, height, style, onClick }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M3.51661 2.58594H13.0499C13.6166 2.58594 14.325 2.9776 14.625 3.46094L18.1083 9.0276C18.4416 9.56927 18.4083 10.4193 18.025 10.9276L13.7083 16.6776C13.4 17.0859 12.7333 17.4193 12.225 17.4193H3.51661C2.05828 17.4193 1.17498 15.8193 1.94165 14.5776L4.24995 10.8859C4.55828 10.3943 4.55828 9.59427 4.24995 9.1026L1.94165 5.41094C1.17498 4.18594 2.06661 2.58594 3.51661 2.58594Z"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
