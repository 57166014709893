import styled from 'styled-components';

export const ModalContainer = styled.div`
  min-width: 80rem;
  padding: 2.4rem;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.6rem;
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: thin;
  gap: 4rem;
  & header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1.6rem;
    & p {
      margin: 0;
      font-family: Work Sans;
      color: #666666;
      font-weight: 500;
      & span {
        color: #0e1742;
      }
    }
  }
`;

export const CellTitle = styled.label`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #0e1742;
  font-family: Work Sans;
  font-weight: 400;
`;

export const DefaultInput = styled.input`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  padding: 1.6rem 2rem;
  border-radius: 0.8rem;
  color: #666666;
  font-family: Work Sans;
`;

export const DefaultTextarea = styled.textarea`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  padding: 1.6rem 2rem;
  border-radius: 0.8rem;
  color: #666666;
  min-height: 7rem;
  font-family: Work Sans;
  resize: vertical;
`;

export const PreviewBox = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 0.8rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 0.8rem;
  align-items: center;
  justify-content: flex-end;
`;

export type TagButtonProps = {
  selected: boolean;
};

export const TagButton = styled.button<TagButtonProps>`
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  font-family: Work Sans;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#2F4CDD' : '#ffffff')};
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#2F4CDD')};
  padding: 0.4rem 1rem;
  border-radius: 0.8rem;
  border: ${({ selected }) => (selected ? 'none' : '1px solid #e6e6e6')};
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 4rem;
  align-items: center;
  width: 100%;
`;
