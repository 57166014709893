import React, { Dispatch, SetStateAction } from 'react';

import { Modal } from '../../../../components/Modal';
import { EditIcon } from '../../../../Icons/Edit-Vuesax';
import { AlertIconVuesax } from '../../../../Icons/Alert-Vuesax';
import { Grid } from '../../../../components/Grid';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton';
import { AdminTag } from '../../types';
import { TagRightIcon } from '../../../../Icons/TagRight';
import { FlashIcon } from '../../../../Icons/Flash';

import * as S from './styles';

interface ModalForm {
  icon: string;
  info: string;
}

interface Props {
  onClickConfirm: () => void;
  modal: boolean;
  closeModal: () => void;
  modalTitle: string;
  modalForm: ModalForm;
  setModalForm: Dispatch<SetStateAction<ModalForm>>;
  onChangeTag?: (tag: AdminTag[]) => void;
  tags?: AdminTag[];
  hasTag?: boolean;
}

const CellStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.6rem',
};

export const AdminModalEdit: React.FC<Props> = ({
  onClickConfirm,
  closeModal,
  modal,
  modalForm,
  modalTitle,
  setModalForm,
  onChangeTag,
  tags,
  hasTag = true,
}) => {
  return (
    <Modal visible={modal}>
      <S.ModalContainer>
        <header>
          <EditIcon />
          <p>
            Editar {'>'} <span>{modalTitle}</span>
          </p>
          <AlertIconVuesax />
        </header>
        <Grid.Display style={{ width: '100%', columnGap: '8px', rowGap: '3.2rem' }}>
          <Grid.Cell size={12} style={CellStyles}>
            <S.CellTitle htmlFor="modal-info">
              <AlertIconVuesax />
              Info
            </S.CellTitle>
            <S.DefaultTextarea
              id="modal-info"
              onChange={(e) => setModalForm({ ...modalForm, info: e.target.value })}
              value={modalForm.info}
            />
          </Grid.Cell>
          {hasTag && (
            <Grid.Cell size={12} style={CellStyles}>
              <S.CellTitle htmlFor="modal-tag">
                <TagRightIcon />
                Adicionar Tag
              </S.CellTitle>
              <S.TagsContainer>
                <S.TagButton
                  selected={tags?.includes('pro') || false}
                  onClick={() => {
                    if (!tags) {
                      return;
                    }
                    if (tags.includes('pro')) {
                      onChangeTag?.(tags.filter((v) => v !== 'pro'));
                    } else {
                      onChangeTag?.([...tags, 'pro']);
                    }
                  }}
                >
                  <FlashIcon fill={tags?.includes('pro') ? '#FFFFFF' : '#2F4CDD'} />
                  Pro
                </S.TagButton>
                <S.TagButton
                  selected={tags?.includes('beta') || false}
                  onClick={() => {
                    if (!tags) {
                      return;
                    }
                    if (tags.includes('beta')) {
                      onChangeTag?.(tags.filter((v) => v !== 'beta'));
                    } else {
                      onChangeTag?.([...tags, 'beta']);
                    }
                  }}
                >
                  Beta
                </S.TagButton>
              </S.TagsContainer>
            </Grid.Cell>
          )}
        </Grid.Display>
        <S.ButtonsContainer>
          <SecondaryButton onClick={closeModal}>Cancelar</SecondaryButton>
          <PrimaryButton onClick={onClickConfirm}>Salvar</PrimaryButton>
        </S.ButtonsContainer>
      </S.ModalContainer>
    </Modal>
  );
};
