import styled from 'styled-components';

interface AdminCardRowProps {
  isActive: boolean;
  disabled: boolean;
  selected: boolean;
}

export const AdminCardRow = styled.div<AdminCardRowProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${({ isActive, selected }) => (!selected ? '#F2F2F2' : isActive ? '#DBE1FB' : '#EAEDFC')};
  transition: background-color 0.3s ease;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.9')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  padding: 1.6rem;
  border-radius: 8px;
  &:hover {
    opacity: 1;
  }
`;
export const RowTitle = styled.span`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 1.6rem;
  & p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    & span {
      color: #808080;
    }
  }
`;

export const RowActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;
