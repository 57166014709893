import React, { useEffect } from 'react';

import { useAuthContext } from '../../../stores/AuthContext';
import { useAuth } from '../../../hooks/useAuth';
import CompanySelect from '../../filters/CompanySelect';
import { mixpanelTrack } from '../../../services/SystemMonitor';

import * as S from '../../../styles/structure';
import ProfessionSelect from '../../filters/ProfessionSelect';
import { useLinkCollaboratorsContext } from '../context/LinkCollaboratorsContext';
import { FiltersContainer } from './CollaboratorsList/styles';
import { SearchInput } from '../../../components/SearchInput';

const LinkCollaboratorsOptions: React.FC = () => {
  const {
    companySelected,
    setCompanySelected,
    professionSelected,
    setProfessionSelected,
    searchTag,
    setSearchTag,
  } = useLinkCollaboratorsContext();
  const { authData, monitorAgent } = useAuthContext();
  const compIds: string = authData ? authData.grpCompanies : 't';
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => mixpanelTrack(monitorAgent, 'Vinculacao', userName, authData, 'paginaVinculação'), []);

  return (
    <FiltersContainer>
      <S.SimpleContainer>
        <SearchInput
          id="Search"
          placeholder="Pesquise por uma tag"
          value={searchTag}
          searchValue={searchTag}
          setSearchValue={setSearchTag}
          onChanging={(value) => setSearchTag(value)}
        />
      </S.SimpleContainer>
      {compIds != 'a' && !compIds.includes(',') ? (
        <></>
      ) : (
        <S.SimpleContainer>
          <CompanySelect
            label={undefined}
            companySelected={companySelected}
            setCompanySelected={setCompanySelected}
            demo={true}
            screen={'Vinculação'}
			eventName={'Vinculação'}
          />
        </S.SimpleContainer>
      )}
      <S.SimpleContainer>
        <ProfessionSelect
          label={undefined}
          professionSelected={professionSelected}
          setProfessionSelected={setProfessionSelected}
          demo={true}
          screen={'Vinculação'}
		  eventName={'Vinculação'}
        />
      </S.SimpleContainer>
    </FiltersContainer>
  );
};

export default LinkCollaboratorsOptions;
