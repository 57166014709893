import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  & h1 {
    margin: 0;
    border-bottom: 2px solid #00e0b4;
    margin-bottom: 0.8rem;
    font-family: Work Sans;
    font-weight: 700;
    color: #b4bde6;
    font-size: 2.8rem;
    & span {
      margin-left: 0.8rem;
      color: #f8f8f8;
    }
  }
`;

export const ReturnButton = styled.button`
  all: unset;
  padding: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 1.2rem;
  cursor: pointer;
`;
