import React from 'react';
import { DefaultIconProps } from './types';

const IconClock: React.FC<DefaultIconProps> = ({ style, height, width, stroke }) => (
  <svg
    fill="none"
    width={width || '20'}
    height={height || '20'}
    stroke={stroke || '#666666'}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    style={style || { marginRight: '2px', marginTop: '2px' }}
  >
    <circle cx="12" cy="12" r="10" />
    <polyline points="12 6 12 12 15 15" />
  </svg>
);

export default IconClock;
