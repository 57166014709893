import React from 'react';

import { CardHeader } from '../CardHeader';
import { UsersIconVuesax } from '../../../../Icons/Users-Vuesax';
import { TrendUpIcon } from '../../../../Icons/TrendUp';
import { TrendDownIcon } from '../../../../Icons/TrendDown';
import { useGetInOutValues } from './hooks/useGetInOutValues';
import { IconLoading } from '../../../../components/IconLoading';

import * as S from './styles';

interface Props {}

export const InOutCard: React.FC<Props> = () => {
  const { dashboardData } = useGetInOutValues();

  if(dashboardData.loading)
    return (<S.Container>
                <IconLoading />
            </S.Container>);

  return (
    <S.Container>
      <CardHeader title="Chegadas e saídas" icon={<UsersIconVuesax />} />
      <S.InOutDataSection>
        <div>
          <span>Média(Chegada/saída)</span>
          <h1>{dashboardData.data.arrivalAndDepartureAverage}</h1>
        </div>
      </S.InOutDataSection>
    </S.Container>
  );
};

