import styled from 'styled-components';

interface ContainerProps {
  show: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${({ show }) => (show ? '4rem' : '-4rem')};
  background-color: #fff;
  border-radius: 0.8rem;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  transition: bottom 1s ease-in-out, opacity 1s ease-in-out;
  opacity: ${({ show }) => (show ? 1 : 0)};
  min-width: 40rem;
  z-index: 999;
  & h1 {
    font-size: 1.6rem;
    font-family: Work Sans;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
`;
