import React from 'react';

import Incident from '../../Icons/Incident';

import * as S from './styles';

type Props = {
  emptyMessage?: string;
};

export const EmptyContainer: React.FC<Props> = ({ emptyMessage }) => {
  return (
    <S.Tbody>
      <tr>
        <td width="100%" height="100%" colSpan={100}>
          <S.EmptyContainer>
            <Incident />
            <span>{emptyMessage ?? 'Não há dados para o período selecinado'}</span>
          </S.EmptyContainer>
        </td>
      </tr>
    </S.Tbody>
  );
};