import React, { createContext, ReactNode, useState, useContext, Dispatch, SetStateAction } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';

import { Area, Collaborator } from '../../../types/Filters';
import { parseToDateFormatted } from '../../../utils/DateTime';

type Props = {
  children: ReactNode;
};

interface MonitoredHH {
  hhArea: any[];
  hhColab: any[];
  hhFunction: any[];
  hhHistory: any[];
  hhMedia: any[];
  hhPago: number;
  hhTotal: number;
}

type ReportsContextProps = {
  collaborators: Collaborator[];
  collaboratorsState: string;
  initialDate: any;
  finalDate: any;

  loadingAccessControl: boolean;
  loadingAreaMonitoringHistory: boolean;
  loadingDailyReport: boolean;
  setCollaborators: (collaborators: Collaborator[]) => void;
  setCollaboratorsState: (value: string) => void;

  companySelected: number;
  professionSelected: number;
  collaboratorSelected: number;
  areaSelected: number;
  date: any;
  
  setCompanySelected: (companie: number) => void;
  setProfessionSelected: (profession: number) => void;
  setAreaSelected: Dispatch<SetStateAction<number>>;
  setCollaboratorSelected: (collaborator: number) => void;
  setDate: (value: any) => void;

  setLoadingAccessControl: (value: boolean) => void;
  setLoadingAreaMonitoringHistory: (value: boolean) => void;
  setLoadingDailyReport: (value: boolean) => void;
  updatePeriod: (initial: any, final: any) => void;

  allAreas: Area[];
  setAllAreas: Dispatch<SetStateAction<Area[]>>;
  monitoredHH: MonitoredHH;
  setMonitoredHH: (value: MonitoredHH) => void;
};

const ReportsContext = createContext<ReportsContextProps>({} as ReportsContextProps);

export function ReportsContextProvider(props: Props) {
  const { children } = props;

  const { authData } = useAuthContext();
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [collaboratorsState, setCollaboratorsState] = useState<string>('');
  const [initialDate, setInitialDate] = useState<any>();
  const [finalDate, setFinalDate] = useState<any>();
  const [professionSelected, setProfessionSelected] = useState<number>(0);
  const [collaboratorSelected, setCollaboratorSelected] = useState<number>(0);
  const [areaSelected, setAreaSelected] = useState(0);
  const [date, setDate] = useState(parseToDateFormatted(new Date(), new Date()));
  
  const [loadingAccessControl, setLoadingAccessControl] = useState<boolean>(false);
  const [loadingAreaMonitoringHistory, setLoadingAreaMonitoringHistory] = useState<boolean>(false);
  const [loadingDailyReport, setLoadingDailyReport] = useState<boolean>(false);

  const [allAreas, setAllAreas] = useState<Area[]>([]);
  const [monitoredHH, setMonitoredHH] = useState<MonitoredHH>({
    hhHistory: [],
    hhArea: [],
    hhColab: [],
    hhFunction: [],
    hhMedia: [],
    hhTotal: 0,
    hhPago: 0,
  });

  const companyId =
    authData && authData.grpCompanies != 'a' && !authData.grpCompanies.includes(',') ? authData.companyId : 0;

  const [companySelected, setCompanySelected] = useState<number>(companyId);

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    collaborators,
    setCollaborators,
    collaboratorsState,
    setCollaboratorsState,
    initialDate,
    finalDate,
    companySelected,
    setCompanySelected,
    professionSelected,
    setProfessionSelected,
    collaboratorSelected,
    setCollaboratorSelected,
    loadingAccessControl,
    setLoadingAccessControl,
    loadingAreaMonitoringHistory,
    setLoadingAreaMonitoringHistory,
    loadingDailyReport,
    setLoadingDailyReport,
    updatePeriod,
    areaSelected,
    setAreaSelected,
    allAreas,
    setAllAreas,
    monitoredHH,
    setMonitoredHH,
    date,
    setDate
  };

  return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>;
}

export function useReportsContext() {
  const context = useContext(ReportsContext);

  if (typeof context === 'undefined') {
    throw new Error('ReportsContext must be used within an useReportsContext');
  }

  return context;
}
