import styled from 'styled-components';

interface Props {
  selected: boolean;
  header?: boolean;
}

const getSelectBackgroundNotInHeader = (selected: boolean) => {
  if(selected)
    return '#2F4CDD';

  return 'transparent';
}

export const SelectContainer = styled.div<Props>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  background-color: ${({ selected, header }) => (header ? '#ffffff' : getSelectBackgroundNotInHeader(selected))};
  position: relative;
`;

export const LineSelectedHeader = styled.div`
  width: 1.4rem;
  height: 0.2rem;
  background-color: #2f4cdd;
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;