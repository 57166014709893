import React from 'react';
import Switch from 'antd/lib/switch';

import { useAreasContext } from '../context/AreasContext';

import * as S from '../styles';

interface Props {
    style?: React.CSSProperties;
}

export const CollaboratorsVehiclesSwitch: React.FC<Props> = ({style}) => {
    const { displayMode, toggleDisplayMode } = useAreasContext();
    
    return (
        <S.SwitchContainer left={'unset'} right={'80px'} style={style}>
            <S.SwitchLabel checked={displayMode === 'collaborators'}>Colaboradores</S.SwitchLabel>
            <Switch style={{ marginTop: '3px' }} onChange={toggleDisplayMode} />
            <S.SwitchLabel checked={displayMode === 'vehicles'}>Veículos</S.SwitchLabel>
        </S.SwitchContainer>
    );
}
