import React from 'react';

import { CompanyBar, ProfessionBar } from './ResourcesTimeBars';
import { TimeDivisionData } from '../../types';

import * as S from '../../styles';
import '../../stylesheet.css';

type ResourcesTimeCompanySectionProps = {
    index: number;
    item:  TimeDivisionData;
    titles: string[];
    cores: {
        produtivas: string;  
        pt: string;
        complementares: string;
        deslocamentos: string;
        improdutivas: string;
    }
}

export const ResourcesTimeCompanySection: React.FC<ResourcesTimeCompanySectionProps> = ({index, item, titles, cores}) => {
    const getCurrentValue = (value?: number) => value ? parseInt(value.toString()) : 0;
    let totalProdFormatted = 0;
    let totalPtFormatted = 0;
    let totalComplFormatted = 0;
    let totalDeslocFormatted = 0;
    let dailyTotal = 0;
    
    const companyBars =  Object.values(item.empresas).map((metrics, index) => {
        const companyTitle = titles[index];
        const bars: JSX.Element[] = [];                                        
        const section = (index > 0) ? <S.CompanyGapDiv/> : <></>;
        
        bars.push(<S.CompanyTitle>{companyTitle}</S.CompanyTitle>);

        let companyProdFormatted = 0;
        let companyPtFormatted = 0;
        let companyComplFormatted = 0;
        let companyDeslocFormatted = 0;
        let companyTotal = 0;

        metrics.forEach(empresa => {
            const prodFormatted = getCurrentValue(empresa.produtivas);
            const ptFormatted = getCurrentValue(empresa.pt);
            const complFormatted = getCurrentValue(empresa.complementares);
            const deslocFormatted = getCurrentValue(empresa.deslocamentos);
            //const improdFormatted = getCurrentValue(empresa.improdutivas);

            companyProdFormatted = companyProdFormatted + prodFormatted;
            companyPtFormatted = companyPtFormatted + ptFormatted;
            companyComplFormatted = companyComplFormatted + complFormatted;
            companyDeslocFormatted = companyDeslocFormatted + deslocFormatted;

            const total = prodFormatted + ptFormatted + complFormatted + deslocFormatted;
            companyTotal = companyTotal + total; // + improdFormatted;

            if(total > 0)
                bars.push(
                    <ProfessionBar index={index}
                                   prodFormatted={prodFormatted}
                                   ptFormatted={ptFormatted}
                                   complFormatted={complFormatted}
                                   deslocFormatted={deslocFormatted}
                                   improdFormatted={0}
                                   total={total}
                                   title={empresa.funcao}
                                   cores={cores}
                                   dailyTotal={false} />
                )
        });
        
        totalProdFormatted = totalProdFormatted + companyProdFormatted;
        totalPtFormatted = totalPtFormatted + companyPtFormatted;
        totalComplFormatted = totalComplFormatted + companyComplFormatted;
        totalDeslocFormatted = totalDeslocFormatted + companyDeslocFormatted;
        dailyTotal = totalProdFormatted + totalPtFormatted + totalComplFormatted + totalDeslocFormatted;

        const footerBar = (companyTotal <= 0) ? <></> :
            <CompanyBar index={0}
                    prodFormatted={companyProdFormatted}
                    ptFormatted={companyPtFormatted}
                    complFormatted={companyComplFormatted}
                    deslocFormatted={companyDeslocFormatted}
                    improdFormatted={0}
                    total={companyTotal}
                    title={companyTitle}
                    cores={cores} 
                    dailyTotal={false} />
                                    
        return [section, bars, footerBar];
    });
    
    return <S.ReportDataContiner key={index} style={{ marginBottom: '20px' }}>
            <S.DataHeader>{item.data}</S.DataHeader>

            <CompanyBar index={-1}
                        prodFormatted={totalProdFormatted}
                        ptFormatted={totalPtFormatted}
                        complFormatted={totalComplFormatted}
                        deslocFormatted={totalDeslocFormatted}
                        improdFormatted={0}
                        total={dailyTotal}
                        title={item.data}
                        cores={cores} 
                        dailyTotal={true} />
            {companyBars}
    </S.ReportDataContiner>
}