import React from 'react';
import { DefaultIconProps } from './types';

export const PageOpenIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M8.75033 16.6654H13.9442C14.4939 16.6654 14.9687 16.6495 15.4018 16.5941C17.709 16.3486 18.3337 15.3194 18.3337 12.5007V7.49672C18.3337 4.67804 17.709 3.64876 15.4018 3.40331C14.9687 3.34789 14.4856 3.33203 13.9442 3.33203H6.05646C5.51506 3.33203 5.03197 3.34789 4.59885 3.40331C2.29167 3.64876 1.66699 4.67804 1.66699 7.49672L1.66699 11.6654"
        stroke={fill || '#B3B3B3'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83301 6.93359H8.89967"
        stroke={fill || '#B3B3B3'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.61621 10H5.63287H14.3912"
        stroke={fill || '#B3B3B3'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7451 6.91667H13.7526"
        stroke={fill || '#B3B3B3'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2451 6.91667H11.2526"
        stroke={fill || '#B3B3B3'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66699 17.2209L6.66699 13.332M6.66699 13.332L2.7781 13.332M6.66699 13.332L1.66699 18.332"
        stroke={fill || '#B3B3B3'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
