import axios from 'axios';

import { BulletinInfos } from '../types';
import { getBulletinReportInfos } from '../../../services/api/TrackfyService';

export const useBulletin = () => {
    const getBulletinInfos = async (): Promise<BulletinInfos> => {
        const source = axios.CancelToken.source();
        const response = await getBulletinReportInfos(source.token);

        return {
        loading: false,
        data: response.data,
        }
    }

    const compareBulletinHours = (current: string, compare: string) => {
        const currentHours = current.split(':').map(Number);
        const compareHours = compare.split(':').map(Number);

        const currentTotal = currentHours[0] * 3600 + currentHours[1] * 60 + currentHours[2];
        const compareTotal = compareHours[0] * 3600 + compareHours[1] * 60 + compareHours[2];

        return (currentTotal - compareTotal) / 60;
    }

    return {
        getBulletinInfos,
        compareBulletinHours,
    }
}