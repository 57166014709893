import React from 'react';

import { ArrowLeftVuesax } from '../../../../Icons/ArrowLeft-Vuesax';
import { useAdminPagesContext } from '../../context/AdminPagesContext';

import * as S from './styles';

interface Props {
  title: string;
}

export const AdminCustomTitle: React.FC<Props> = ({ title }) => {
  const { setPage } = useAdminPagesContext();
  return (
    <S.Container>
      <S.ReturnButton onClick={() => setPage('')}>
        <ArrowLeftVuesax width={20} height={20} />
      </S.ReturnButton>
      <h1>
        Admin {'>'}
        <span>{title}</span>
      </h1>
    </S.Container>
  );
};
