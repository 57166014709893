import React from 'react';

import { MenuIcon } from '../enum';
import IconPdf from '../Icons/IconPdf';
import IconChartBars from '../Icons/ChartBars';
import IconDashboard from '../Icons/Dashboard';
import IconCalendar from '../Icons/Calendar';
import IconForkLift from '../Icons/ForkLift';
import IconAlert from '../Icons/Alert';
import IconInsights from '../Icons/Insights';
import IconCurve from '../Icons/Curve';
import IconLinkCollaborator from '../Icons/Link';
import { IconDisplacement } from '../Icons/Displacement';
import IconClock from '../Icons/Clock';
import IconLocationSearch from '../Icons/LocationSearch';
import { IconFinance } from '../Icons/Finance';
import { IconSupport } from '../Icons/Support';
import IconAlertReports from '../Icons/AlertReports';
import { IconStatus } from '../Icons/Status';
import { UsersIconVuesax } from '../Icons/Users-Vuesax';
import IconUsers  from '../Icons/Users'
import { IconClientCompanyRegister } from '../Icons/ClientCompanyRegister';
import IconHome from '../Icons/Home';
import { TruckIcon } from '../Icons/Trucks';
import IconInfo from '../Icons/Info';
import { ReportsMenuIcon } from '../Icons/Menu/Reports';
import { MonitoringMenuIcon } from '../Icons/Menu/Monitoring';
import { ConfigIcon } from '../Icons/Config';

export const iconConfig = {
  [MenuIcon.dashboard]: <IconDashboard />,
  [MenuIcon.indicators]: <IconChartBars />,
  [MenuIcon.insights]: <IconInsights />,
  [MenuIcon.reports]: <IconPdf />,
  [MenuIcon.summary]: <IconCalendar />,
  [MenuIcon.machinesSummary]: <IconForkLift />,
  [MenuIcon.emergency]: <IconAlert />,
  [MenuIcon.agilean]:  <IconCalendar />,
  [MenuIcon.curves]: <IconCurve />,
  [MenuIcon.textInsights]: <IconInsights />,
  [MenuIcon.collaboratorsLink]: <IconLinkCollaborator />,
  [MenuIcon.displacement]: <IconDisplacement />,
  [MenuIcon.realtime]: <IconClock />,
  [MenuIcon.calibration]: <IconLocationSearch />,
  [MenuIcon.onePage]: <IconFinance/>,
  [MenuIcon.support]: <IconSupport />,
  [MenuIcon.incidents]: <IconAlertReports/>,
  [MenuIcon.scannerstatus]: <IconStatus/>,
  [MenuIcon.users]: <UsersIconVuesax />,
  [MenuIcon.newpassword]: <IconUsers/>,
  [MenuIcon.clientregistration]: <IconClientCompanyRegister/>,
  [MenuIcon.homepage]: <IconHome />,
  [MenuIcon.infleet]: <TruckIcon />,
  [MenuIcon.logs]: <IconInfo fill="#fff" />,
  [MenuIcon.resourcessummary]: <ReportsMenuIcon />,
  [MenuIcon.gps]: <MonitoringMenuIcon />,
  [MenuIcon.gpsroute]: <MonitoringMenuIcon />,
  [MenuIcon.admin]: <ConfigIcon />,
}