import React, { ReactNode, useState } from 'react';
import Switch from 'antd/lib/switch';

import { MonitoringComponentList } from '../types';
import { CollaboratorsVehiclesSwitch } from '../../../../realTime/components/CollaboratorsVehiclesSwitch';
import { NotificationBoard } from '../../../../emergency/components/NotificationBoard';
import { ArrowDownIconVuesax } from '../../../../../Icons/ArrowDown-Vuesax';
import { useMonitoringAdminContext } from '../context/MonitoringAdminContext';
import { Search } from '../../../../../components';
import IconFilter from '../../../../../Icons/Filter';
import { ResultListItem } from '../../../../../components/Search/components';
import { IndicatorsCard } from '../../../../realTime/components/summary';
import { useSearch } from '../../../../monitoring/components/MonitoringIndicators/detective/components/Search/hooks/useSearch';
import { CheckGuardIcon } from '../../../../../Icons/CheckGuard';
import { EvacuationSnapshot } from '../../../../emergency/types/Emergency';
import { EvacuationContextProvider } from '../../../../emergency/context/EvacuationContext';
import { SearchContent,
         SearchInput } from '../../../../monitoring/components/MonitoringIndicators/detective/components/Search/components';

import * as S from './styles';

interface Props {
  component: MonitoringComponentList;
  icon: string;
  info?: string;
  title?: string;
  subtitle?: string;
}

const InfleetSwitch: React.FC = () => {
  return (
    <CollaboratorsVehiclesSwitch
      style={{
        position: 'relative',
        bottom: 'inherit',
        right: 'inherit',
        left: 'inherit',
        top: 'inherit',
        maxWidth: '30rem',
      }}
    />
  );
};

const Alerts: React.FC = () => {
  return (
    <EvacuationContextProvider>
      <NotificationBoard
        style={{
          position: 'relative',
          bottom: 'inherit',
          right: 'inherit',
          left: 'inherit',
          top: 'inherit',
          maxWidth: '60rem',
        }}
      />
    </EvacuationContextProvider>
  );
};

export const MonitoresAreas: React.FC = () => {
  const list = [
    {
      name: 'Almoxarifado',
      count: 2,
      collaborators: ['Funcionário 1', 'Funcionário 2'],
      color: '#00CAA2',
    },
    {
      name: 'Oficina',
      count: 4,
      collaborators: ['Funcionário 1', 'Funcionário 2', 'Funcionário 3', 'Funcionário 4'],
      color: '#00CAA2',
    },
    {
      name: 'Cantina',
      count: 2,
      collaborators: ['Funcionário 1', 'Funcionário 2'],
      color: '#2F4CDD',
    },
    {
      name: 'Laboratório',
      count: 4,
      collaborators: ['Funcionário 1', 'Funcionário 2', 'Funcionário 3', 'Funcionário 4'],
      color: '#2F4CDD',
    },
  ];
  const { activeRow } = useMonitoringAdminContext();
  const [collapsed, setCollapsed] = useState(false);
  const [areaCollapsed, setAreaCollapsed] = useState('');
  return (
    <S.MonitoredAreasCard>
      <S.MonitoredAreasCardHeader>
        <span>Áreas monitoradas</span>
        <div
          style={{ cursor: 'pointer', height: '100%', display: 'flex', alignItems: 'center' }}
          onClick={() => setCollapsed((prev) => !prev)}
          dangerouslySetInnerHTML={{ __html: activeRow?.icon || '' }}
        ></div>
      </S.MonitoredAreasCardHeader>
      <S.MonitoredAreasCardContent collapsed={collapsed}>
        {list.map((item, index) => {
          const isFirstItem = index === 0;
          const isLastItem = index === list.length - 1;
          return (
            <S.MonitoredAreasCardRow
              color={item.color}
              isFirstItem={isFirstItem}
              isLastItem={isLastItem}
              collapsed={item.name === areaCollapsed}
            >
              <div>
                <div>{item.count}</div>
                <span>{item.name}</span>
                <ArrowDownIconVuesax
                  style={{
                    transform: areaCollapsed === item.name ? 'rotateZ(-180deg)' : 'none',
                    transition: 'transform 0.2s ease',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setAreaCollapsed((prev) => (prev === item.name ? '' : item.name));
                  }}
                />
              </div>
              <S.AreaCollapsedCollaborators collapsed={item.name === areaCollapsed}>
                {item.collaborators.map((collab) => (
                  <span>{collab}</span>
                ))}
              </S.AreaCollapsedCollaborators>
            </S.MonitoredAreasCardRow>
          );
        })}
      </S.MonitoredAreasCardContent>
    </S.MonitoredAreasCard>
  );
};

const CompanyFilter: React.FC = () => {
  const [value, setValue] = useState('');
  const [selectedItem, setSelectedItem] = useState<ResultListItem | null>(null);
  const [isActive, setIsActive] = useState(false);
  return (
    <S.FilterContainer>
      <Search
        value={value}
        placeholder="Pesquisar empresa"
        data={[
          {
            label: 'Empresa 1',
            id: '1',
          },
          {
            label: 'Empresa 2',
            id: '2',
          },
        ]}
        selectedItems={selectedItem}
        isActive={isActive}
        onChangeValue={(value: string) => setValue(value)}
        onToggle={() => setIsActive(!isActive)}
        onSelectValue={(item: ResultListItem | null) => setSelectedItem(item)}
        customIcon={<IconFilter />}
      />
    </S.FilterContainer>
  );
};

const Summary: React.FC = () => {
  return <IndicatorsCard titulo="Resumo" civil={false} style={{ position: 'static', width: '400px' }} />;
};

const MapSwitch: React.FC = () => {
  const [checked, setChecked] = useState('street');
  const changeMapTerrain = (text: string) => {
    setChecked(text);
  };
  return (
    <S.SwitchContainer>
      <S.SwitchLabel checked={checked === 'street'}>Street</S.SwitchLabel>
      <Switch
        style={{ marginTop: '3px' }}
        onChange={() => changeMapTerrain(checked === 'street' ? 'satelite' : 'street')}
      />
      <S.SwitchLabel checked={checked === 'satelite'}>Satélite</S.SwitchLabel>
    </S.SwitchContainer>
  );
};

const SearchCollaborators: React.FC = () => {
  const { searchListActive, handleClickButtonSeacrh, getListMonitoredCollaborators } = useSearch();

  return (
    <>
      <div className={/* active */ searchListActive ? 'search-container active' : 'search-container'}>
        <SearchInput
          active={/* active */ searchListActive}
          onClick={handleClickButtonSeacrh}
          onSearch={getListMonitoredCollaborators}
        />
        <SearchContent active={searchListActive} onSearch={getListMonitoredCollaborators} />
      </div>
    </>
  );
};

const Evacuation: React.FC = () => {
  const { activeRow } = useMonitoringAdminContext();
  const evacuationButtonTexts = {
    standby: 'Iniciar modo de evacuação',
    evacuating: 'Finalizar modo de evacuação',
    notevacuating: 'Modo de evacuação',
  };
  const [data, setData] = useState<EvacuationSnapshot>({
    collaboratorsInDangerAreas: [],
    collaboratorsInDangerAtStart: [],
    collaboratorsSeenInSafeAreas: [],
    currentCollaboratorsInArea: [],
    currentSnapshot: [],
    dangerAreas: [],
    elapsedTime: 0,
    initialTimestamp: 0,
    loadingData: false,
    mode: 'notevacuating',
    safeArea: '',
  });

  const handleEmergencyButton = () => {
    setData((prev) => ({
      ...prev,
      mode:
        data.mode === 'notevacuating' ? 'standby' : data.mode === 'evacuating' ? 'notevacuating' : 'evacuating',
    }));
    if (data.mode === 'notevacuating') {
    }
  };

  return (
    <S.EvacuationButton
      mode={data.mode}
      isMobile={false}
      active={!Boolean(data.mode)}
      onClick={handleEmergencyButton}
    >
      {data.mode === 'evacuating' ? (
        <CheckGuardIcon />
      ) : (
        <div
          style={{ display: 'flex', height: '100%', alignItems: 'center' }}
          dangerouslySetInnerHTML={{ __html: activeRow?.icon || '' }}
        />
      )}
      {data.mode !== 'notevacuating' ? evacuationButtonTexts[data.mode] : 'Modo de evacuação'}
    </S.EvacuationButton>
  );
};

export const PreviewCardMonitoring: React.FC<Props> = ({ component }) => {
  const components: ReactNode[] = [];
  switch (component) {
    case 'Áreas monitoradas':
      components.push(<MonitoresAreas />);
      break;
    case 'Filtrar empresa':
      components.push(<CompanyFilter />);
      break;
    case 'Resumo':
      components.push(<Summary />);
      break;
    case 'Infleet':
      components.push(<InfleetSwitch />);
      break;
    case 'Switch de mapa':
      components.push(<MapSwitch />);
      break;
    case 'Alertas':
      components.push(<Alerts />);
      break;
    case 'Pesquisar recurso':
      components.push(<SearchCollaborators />);
      break;
    case 'Modo de evacuação':
      components.push(<Evacuation />);
      break;
  }
  return <>{components}</>;
};
