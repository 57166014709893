import React from 'react';
import { DefaultIconProps } from './types';

export const MonitorIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M8.58699 2.66797H23.4003C28.147 2.66797 29.3337 3.85464 29.3337 8.58797V17.028C29.3337 21.7746 28.147 22.948 23.4137 22.948H8.58699C3.85366 22.9613 2.66699 21.7746 2.66699 17.0413V8.58797C2.66699 3.85464 3.85366 2.66797 8.58699 2.66797Z"
        stroke={fill || 'white'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 22.9609V29.3343"
        stroke={fill || 'white'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66699 17.332H29.3337"
        stroke={fill || 'white'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 29.332H22"
        stroke={fill || 'white'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
