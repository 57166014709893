import styled from 'styled-components';

interface ContainerProps {
  selected: boolean;
  disabled?: boolean;
}

export const Container = styled.button<ContainerProps>`
  all: unset;
  position: relative;
  width: 3.125rem;
  height: 1.625rem;
  background-color: ${({ selected }) => (selected ? '#2F4CDD' : '#D7D7D7')};
  border-radius: 50rem;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  & div {
    position: absolute;
    right: ${({ selected }) => (selected ? '0.1375rem' : 'calc(100% - 0.1375rem - 1.375rem)')};
    transition: right 0.2s ease;
    width: 1.375rem;
    height: 1.375rem;
    background-color: #ffffff;
    border-radius: 5rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;
